import React from 'react'

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Banner(props) {
  const { t } = useTranslation();
  const category = props.category;
  const slider = props.slider;
  return (
    <React.Fragment>
      <div className="banner banner-1 bg-image">
        <div className="container">
          <div className="banner-inner">
            <div className="row">
              <div className="col-xl-2 col-lg-4 col-12 gutter-1">
                <div className="catagory-sidebar">
                  <div className="widget-bg">
                    <h2 className="widget-title">
                      {t('homepage.banner.all_categories')} <i className="rt-angle-down" />
                    </h2>
                    <nav>
                      <ul>
                        {category.map(({ category_name, category_slug, category_uid }, index) => {
                          return (<li key={index}>
                            <Link to={`categories/${category_slug}`}>
                              {category_name} <i className="rt rt-arrow-right-long" />
                            </Link>
                          </li>)
                        })}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-xl-10 col-lg-8 col-12 gutter-2">
                <div className="swiper bannerSlide2">
                  <div className="swiper-wrapper">
                    {slider.map(({ title, image, description }, index) => {
                      return (
                        <div className="swiper-slide" key={index}>
                          <div className="banner-single bg-image bg-image-3-1 lazyload" data-bg={image} >
                            <div className="container">
                              <div className="single-inner">
                                <div className="content-box">
                                  <p className="slider-subtitle">
                                    <img
                                      className="lazyload"
                                      data-src={process.env.REACT_APP_ASSETS_URL + "/assets/images/banner/wvbo-icon.png"}
                                      alt={title}
                                      width={'22px'}
                                      height={'6px'}
                                    />
                                    {t('homepage.banner.small_content')}
                                  </p>
                                  <h2 className="slider-title">
                                    {title}
                                  </h2>
                                  <div className="slider-description">
                                    <p>
                                      {description}
                                    </p>
                                  </div>
                                  <Link to={`products`} className="slider-btn2">
                                    {t('homepage.banner.view_collections')}
                                  </Link>
                                </div>
                                <Link to={`products`} className="slider-btn2">
                                  {t('homepage.banner.view_collections')}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="slider-navigation">
                    <div className="swiper-button-prev slider-btn prev" title={'Prev'}>
                      <i className="rt rt-arrow-left-long" />
                    </div>
                    <div className="swiper-button-next slider-btn next"  title={'Next'}>
                      <i className="rt rt-arrow-right-long" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
