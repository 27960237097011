import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SocialList from "../General/SocialList";
import { Link } from "react-router-dom";
import { GetPrice } from "../Helpers";
import { useTranslation } from 'react-i18next';
import { Product } from "../services";
export default function ProductData(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    product_images,
    product_image,
    product_name,
    product_price,
    product_ref_price,
    Brand,
    Category,
    product_sku,
    slug,
    product_description,
    product_uid,
    product_quantity,
    product_stock_out,
    variate_1_name,
    variate_2_name,
    variation_option1_name,
    variation_option2_name,
    variation_1,
    variation_2,
    variation_details
  } = props.data;
  const [quantity, setQuantity] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [variationDetails, setVariationDetails] = useState({});
  const [selectVariation, setselectVariation] = useState({});
  const [productPrice, setProductPrice] = useState(0);
  const [product_price_main, setproduct_price_main] = useState(0);
  const [variation, setVariation] = useState({
    variation_1: {
    },
    variation_2: {
    }
  });
  useEffect(() => {
    setVariationDetails(variation_details);
    setProductPrice(product_price);
    setproduct_price_main(product_price);
  }, [props]);
  useEffect(() => {
    setDisabled(false);
    setselectVariation({});
    let variation_uid_check = '';
    if (variation.variation_1.option_uid) {
      variation_uid_check += variation.variation_1.option_uid;
    }
    if (variation.variation_2.option_uid) {
      variation_uid_check += ',' + variation.variation_2.option_uid;
    }
    for (let i = 0; i < Object.keys(variationDetails).length; i++) {
      if (Object.keys(variationDetails)[i] == variation_uid_check) {
        setDisabled(true);
        setselectVariation(Object.values(variationDetails)[i]);
      }
    }
    if (variation_uid_check == '') {
      setDisabled(true);
    }
  }, [variation]);

  useEffect(() => {
    if (Object.keys(selectVariation).length > 0) {
      var varition_price = product_price_main * selectVariation.variation_sku_code.slice(-1);
      setProductPrice(varition_price);
    }
  }, [selectVariation]);


  const cartHandler = async (item = {}, type = "") => {
    let ProductStock = await Product.getProductStockCheck(item.product_uid, item.quantity);
    if (type === "quantity") {
      return;
    }
    if (ProductStock) {
      dispatch({
        type: "ADD",
        payload: item,
      })
    }
  }

  var fno = "";
  var sno = "";
  var extraClass = "";

  return (
    <React.Fragment>
      <div className="details-product-area mb--70">
        <div className="product-thumb-area">
          <div className="cursor">
            {(Object.keys(selectVariation).length > 0) ?
              <div className={`thumb-wrapper variation filterd-items figure`} >
                <div
                  className="product-thumb zoom lazyload"
                  onmousemove="zoom(event)"
                  data-bg={selectVariation.product_image}
                >
                  <img className="lazyload" data-src={selectVariation.product_image} alt="product-thumb" />
                </div>
              </div>
              :
              <>
              </>
            }
            {Object.values(Object.assign({}, product_images)).map(
              (img, index) => {
                if (index == 0) {
                  fno = "one";
                  extraClass = (Object.keys(selectVariation).length > 0) ? "hide" : "figure";
                } else if (index == 1) {
                  fno = "two";
                  extraClass = "hide";
                } else if (index == 2) {
                  fno = "three";
                  extraClass = "hide";
                }
                return (
                  <div
                    className={`thumb-wrapper ${fno} filterd-items ${extraClass}`}
                  >
                    <div
                      className="product-thumb zoom lazyload"
                      onmousemove="zoom(event)"
                      data-bg={img}
                    >
                      <img className="lazyload" data-src={img} alt="product-thumb" />
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="product-thumb-filter-group">
            {(Object.keys(selectVariation).length > 0) ?
              <div className="thumb-filter filter-btn active" data-show={`.variation`} >
                <img className="lazyload" data-src={selectVariation.product_image} alt="product-thumb-filter" />
              </div>
              :
              <>
              </>
            }
            {Object.values(Object.assign({}, product_images)).map(
              (img, index) => {
                if (index == 0) {
                  sno = "one";
                } else if (index == 1) {
                  sno = "two";
                } else if (index == 2) {
                  sno = "three";
                }
                return (
                  <div
                    className="thumb-filter filter-btn active"
                    data-show={`.${sno}`}
                    key={index}
                  >
                    <img className="lazyload" data-src={img} alt="product-thumb-filter" />
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="contents">
          <div className="product-status">
            <span className="product-catagory">{Category}</span>
            <div className="rating-stars-group">
              <div className="rating-star">
                <i className="fas fa-star" />
              </div>
              <div className="rating-star">
                <i className="fas fa-star" />
              </div>
              <div className="rating-star">
                <i className="fas fa-star-half-alt" />
              </div>
              <span>{t('product_details.reviews')}</span>
            </div>
          </div>
          <h2 className="product-title">
            {(Object.keys(selectVariation).length > 0) ? selectVariation.product_name : product_name}
            {(parseInt(product_quantity) == parseInt(product_stock_out)) ?
              <span className="stock">{t('product_details.out_of_stock')}</span>
              : <span className="stock">{t('product_details.in_stock')}</span>}
          </h2>
          <span className="product-price">
            <span className="old-price">{GetPrice(product_ref_price)}</span>  {(Object.keys(selectVariation).length > 0) ? GetPrice(product_price * selectVariation.variation_sku_code.slice(-1)) : GetPrice(product_price)}
          </span>
          {/* <p>{product_description}</p> */}
          <p dangerouslySetInnerHTML={{ __html: (Object.keys(selectVariation).length > 0) ? selectVariation.product_description : product_description }}
          />
          {(variate_1_name) ?
            <div className="action-item3">
              <div className="action-top">
                <span className="action-title">{variate_1_name} : <strong> {variation.variation_1.name}</strong></span>
              </div>
              {variation_option1_name.map((option, index) => {
                return (
                  <div className="color-item2" onClick={() => setVariation({ ...variation, variation_1: { uid: variation_1, option_uid: option.uid, name: option.name } })}>
                    <div className={`size ${variation.variation_1.option_uid === option.uid &&
                      "active"
                      }`} data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title={option.name}>{option.name}</div>
                  </div>
                );
              }
              )}
            </div>
            :
            <></>
          }

          {(variate_2_name) ?
            <div className="action-item3">
              <div className="action-top">
                <span className="action-title">{variate_2_name} : <strong> {variation.variation_2.name}</strong></span>
              </div>
              {variation_option2_name.map((option, index) => {
                return (
                  <div className="color-item2" onClick={() => setVariation({ ...variation, variation_2: { uid: variation_2, option_uid: option.uid, name: option.name } })}>
                    <div className={`size ${variation.variation_2.option_uid === option.uid &&
                      "active"
                      }`} data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title={option.name} aria-label={option.name}>{option.name}</div>
                  </div>
                );
              }
              )}
            </div>
            :
            <></>
          }

          <div className="product-bottom-action">
            <div className="cart-edit">
              <div className="quantity-edit action-item">
                <button
                  className="button"
                  onClick={() => { cartHandler({}, "quantity"); quantity > 1 ? setQuantity(quantity - 1) : setQuantity(1) }}
                >
                  <i className="fal fa-minus minus" />
                </button>
                <input type="text" className="input" value={quantity} />
                <button
                  className="button plus"
                  onClick={() => { cartHandler({}, "quantity"); setQuantity(quantity + 1) }}
                >
                  +<i className="fal fa-plus plus" />
                </button>
              </div>
            </div>
            <Link
              className="addto-cart-btn action-item"
              disabled={(parseInt(product_quantity) === parseInt(product_stock_out) || !disabled) ? "disabled" : ""}
              onClick={() => cartHandler(
                {
                  id: product_uid,
                  slug: slug,
                  title: product_name,
                  image: product_image,
                  quantity: (Object.keys(selectVariation).length > 0) ? quantity * selectVariation.variation_sku_code.slice(-1) : quantity,
                  price: (Object.keys(selectVariation).length > 0) ? product_price * selectVariation.variation_sku_code.slice(-1) : product_price,
                  brand: Brand,
                  category: Category,
                  variation: variation
                }
              )}
            >
              <i className="rt-basket-shopping" /> {t('product_details.add_to_cart')}
            </Link>
            {
              (parseInt(product_quantity) !== parseInt(product_stock_out))
                ?
                <Link
                  className="wishlist-btn action-item"
                  onClick={() =>
                    dispatch({
                      type: "WISHLIST_ADD",
                      payload: {
                        id: product_uid,
                        slug: slug,
                        title: product_name,
                        image: product_image,
                        quantity: (Object.keys(selectVariation).length > 0) ? quantity * selectVariation.variation_sku_code.slice(-1) : quantity,
                        price: (Object.keys(selectVariation).length > 0) ? product_price * selectVariation.variation_sku_code.slice(-1) : product_price,
                        brand: Brand,
                        category: Category,
                        quantity: product_quantity,
                        stock_out: product_stock_out,
                        variation: variation
                      },
                    })
                  }
                >
                  <i className="rt-heart" />
                </Link>
                : ""
            }

          </div>
          <div className="product-uniques">
            <span className="sku product-unipue">
              <span>{t('product_details.sku')} </span> {(Object.keys(selectVariation).length > 0) ? selectVariation.product_sku : product_sku}
            </span>
            <span className="catagorys product-unipue">
              <span>{t('product_details.categories')} </span> {Category}
            </span>
            <span className="tags product-unipue">
              <span>{t('product_details.brand')} </span> {Brand}
            </span>
          </div>
          <div className="d-flex align-items-center share-social">
            <span>{t('product_details.share')}</span>
            <SocialList />
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
