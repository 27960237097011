import React, { useEffect, useState } from 'react'
import { BreadCrumb, Comment, Rating, Search, PopularBlogs, Categories, Tags, Preload,Meta } from '../../components';
import { Link, useParams } from "react-router-dom";
import { Blog } from '../services';
import { CommentForm } from '../Forms';
import SocialList from '../General/SocialList';
import moment from "moment";
import { useTranslation } from 'react-i18next';

function BlogDetails({ title }) {
  const { t } = useTranslation();
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    // title
    document.title = title;

    let isCancelled = false;
    const fetchBlog = async () => {
      setLoading(true);
      let parameter = {
        "slug": slug
      }
      await Blog.getBlog(parameter).then((res) => {
        if (res) {
          setBlog(res.arrData);
          setLoading(false);
        }
      })
    }
    if (!isCancelled) {
      fetchBlog();
    }
    return () => {
      isCancelled = true;
    }
  }, [slug])

  return (
    <React.Fragment>
      <Meta
        title={title + blog.meta_title}
        keyword={title + blog.meta_keyword}
        description={title + blog.meta_description}
      />
      <BreadCrumb title={t('blog_details.title')} />
      <section className="news-feed-area pt-120 pb-75 pt-md-60 pb-md-15 pt-xs-50 pb-xs-10">
        <div className="container">
          {!loading
            ? <div className="row mb-15">
              <div className="col-lg-8 pe-xl-0">
                <div className="news-left2">
                  <div className="news-top">
                    <h1>{blog.blog_name}</h1>
                    <div className="icon-text">
                      {/* <span className="viewers fs-10">
                    <Link to="#">
                      <i className="fal fa-eye" /> 100 Views
                    </Link>
                  </span>
                  <span className="comment fs-10">
                    <Link to="#">
                      <i className="fal fa-comments" /> 30 Comments
                    </Link>
                  </span> */}
                      <span className="date fs-10">
                        <Link to="#" className='ps-0'>
                          <i className="fal fa-calendar-alt" />
                          {moment(blog.blog_created_date).format("DD MMM YY")}
                        </Link>
                      </span>
                    </div>
                    <div className="image-section">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="image-1 c-blog-detail-main-image">
                            <img className="lazyload" data-src={blog.blog_image} alt="img" />
                          </div>
                        </div>
                      </div>
                    </div>

                    { Object.values(Object.assign({}, blog.blog_content)).map((item,index) => {
                      if(item.content_type == 'content'){
                        return(
                          <>
                          <p className="description">
                          <div
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            />
                          </p>
                          </>
                        );
                      }else if(item.content_type == 'image_video'){
                        return(
                          <div className="image-section">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="image-1 c-blog-detail-main-image">
                                  <img className="lazyload" data-src={item.image_video} alt="img" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }else if(item.content_type == 'content_image_video'){
                        return(
                          <div className="image-section">
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="description">
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                  />
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <div className="image-2">
                                  <img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/blog-details/img-2.jpg"} alt="img" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }else if(item.content_type == 'image_video_content'){
                        return(
                          <div className="image-section">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="image-1">
                                  <img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/blog-details/img-1.jpg"} alt="img" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <p className="description">
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      }

                    })
                    }

                  </div>
                  <div className="button-area">
                    <div className="row justify-content-between">
                      <div className="col-lg-6 col-sm-6 col-12">
                        <div className="tag-area">
                          <h3>{t('blog_details.related_tags')}</h3>
                          <Tags blog_tags={blog.blog_tag} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12 text-sm-end text-start">
                        <div className="social-area">
                          <div className="social-title">
                            <h3>{t('blog_details.social_share')}</h3>
                          </div>
                          <div className="c-blog-page-social-icone">
                            <SocialList />
                          </div>
                        </div>
                      </div>
                    </div>
                    {(blog.prev_blog && blog.next_blog) ?
                    <div className="post-area">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-lg-4 col-sm-4 col-12 text-start">
                          <div className="previous-post">
                            <div className="post-img">
                              <Link to={'/blogs/'+blog.prev_blog.blog_slug}>
                                <img
                                  className="lazyload"
                                  data-src={blog.prev_blog.blog_image}
                                  alt="prev-post"
                                />
                              </Link>
                            </div>
                            <div className="post-text">
                              <h3 className="sub-title">{t('blog_details.prev_post')}</h3>
                              <h2 className="sect-title">
                                <Link to={'/blogs/'+blog.prev_blog.blog_slug}>{blog.prev_blog.blog_name}</Link>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-12 text-sm-center text-start">
                          <div className="icon-area">
                            <Link to="/blogs">
                              <img
                                className="lazyload"
                                data-src={process.env.REACT_APP_URL + "/assets/images/blog-details/shape.png"}
                                alt="img"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-12 text-sm-end text-start justify-content-sm-end justify-content-start">
                          <div className="next-post">
                            <div className="post-text">
                              <h3 className="sub-title">{t('blog_details.next_post')}</h3>
                              <h2 className="sect-title">
                                <Link to={'/blogs/'+blog.next_blog.blog_slug}>{blog.next_blog.blog_name}</Link>
                              </h2>
                            </div>
                            <div className="post-img">
                              <Link to={'/blogs/'+blog.next_blog.blog_slug}>
                                <img
                                  className="lazyload"
                                  data-src={blog.next_blog.blog_image}
                                  alt="next-post"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <></>
                    }
                    {/* <Comment />
                    <Rating />
                    <CommentForm /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pl-30 pl-lg-15 pl-md-15 pl-xs-15">
                <div className="news-right-widget">
                  <Search />
                  <PopularBlogs />
                  <Categories />
                  <div className="widget widget-categories-tag mb-40">
                    <div className="widget-title-box pb-25 mb-25">
                      <h4 className="widget-sub-title2 fs-20">{t('blog_details.tags')}</h4>
                    </div>
                    <Tags />
                  </div>
                </div>
              </div>
            </div>
            : <Preload />
          }
        </div>
      </section>

    </React.Fragment >
  )
}

export default BlogDetails