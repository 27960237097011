import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useSelector } from "react-redux";
import { Auth, Product, Address } from '../services';
import { Customer } from '../CustomerConfig';
import moment from "moment";
import { Preload } from '../../components';
import { useTranslation } from 'react-i18next';

export default function UserOverview({ logout, tabChange }) {
  const dummyProfileImage = `${process.env.REACT_APP_URL}/assets/images/placeholder/placeholder-user.png`;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { CustomerData } = Customer();
  const { wishlist } = useSelector((state) => state);
  const [user, setUser] = useState();
  const [orderListCount, setOrderListCount] = useState();
  const [address, setAddress] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const fetchUser = () => {
      setLoading(true);
      let parameter = {
        "user_uid": CustomerData.user_uid,
        "access_token": CustomerData.access_token
      }
      Auth.getUser(parameter).then((res) => {
        if (res.success === 1) {
          setUser(res.arrData)
          setLoading(false);
        }
      })
    }
    const fetchProductTotal = () => {
      setLoading(true);
      let parameter2 = {
        "user_uid": CustomerData.user_uid,
        "access_token": CustomerData.access_token,
        "keyword": "",
        "price": {
          "min": 0,
          "max": 0
        },
        "pagination": {
          "per_page": 0,
          "page": 0
        }
      }
      Product.getOrderList(parameter2).then((res) => {
        if (res.success === 1) {
          setOrderListCount(res.count)
          setLoading(false);
        }
      })
    }
    const fetchAddress = () => {
      setLoading(true);

      Address.getCustomerAddress().then((res) => {
        if (res.success === 1) {
          setAddress(res.arrData)
          setLoading(false);
        }
      })
    }

    if (!isCancelled) {
      fetchUser();
      fetchProductTotal();
      fetchAddress();
    }

    return () => {
      isCancelled = true;
    };
  }, [tabChange])


  return (
    <React.Fragment>
      <div className="account-main dashboard filterd-items">
        {
          loading ?
            <Preload type='userProfile' />
            : <React.Fragment>
              <div className="account-profile-area">
                <div className="profile-dp">
                  <img src={user && user.image ? user.image : dummyProfileImage} alt="profile" />
                </div>
                <div className="d-block">
                  <span className="profile-name d-block">
                    <span>{t('account.tabs.1.hi')}</span> {user ? user.first_name + " " + user.last_name : ""}
                  </span>
                  <span className="profile-name"><span>{user ? user.email : ""}</span></span>
                  <span className="profile-date d-block">{user ? moment(user.birth_date).format("DD MMM YYYY") : ""}</span>
                </div>
              </div>
              <p>{t('account.tabs.1.content')}</p>
            </React.Fragment>
        }
        <div className="activity-box">
          {loading ? <Preload type='dashboardIcon' /> :
            <div className="activity-item">
              <div className="icon"><i className="fas fa-box-check" /></div>
              <span className="title">{t('account.tabs.1.my_orders')}</span>
              <span className="value">{orderListCount}</span>
            </div>
          }
          {loading ? <Preload type='dashboardIcon' /> :
            <div className="activity-item">
              <div className="icon"><i className="fas fa-map-marker-alt" /></div>
              <span className="title">{t('account.tabs.1.address')}</span>
              {
                Object.values(address).length
                  ?
                  <div>
                    {/* <span className="value">
                      {address.is_default_billing.address1},
                      {address.is_default_billing.state},
                      {address.is_default_billing.country},
                      {address.is_default_billing.zipcode}.
                    </span> */}
                    <span className="value">
                      {address.is_default_shipping.address1},
                      {address.is_default_shipping.state},
                      {address.is_default_shipping.country},
                      {address.is_default_shipping.zipcode}.
                    </span>
                  </div>
                  : ""
              }
            </div>
          }
          {loading ? <Preload type='dashboardIcon' /> :
            <div className="activity-item">
              <div className="icon"><i className="fas fa-heart" /></div>
              <span className="title">{t('account.tabs.1.wishlist')}</span>
              <span className="value">{wishlist.length}</span>
            </div>
          }
          {loading ? <Preload type='dashboardIcon' /> :
            <button onClick={logout} className="activity-item">
              <div className="icon"><i className="fas fa-sign-out-alt" /></div>
              <span className="title">{t('account.tabs.1.logout')}</span>
            </button>
          }
        </div>
      </div>
    </React.Fragment>
  )
}
