import React, { useEffect, useState } from 'react'
import { Address } from '../services';
import { useTranslation } from 'react-i18next';

export default function NewAddress({ checkout, getShippingCharge }) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      fetchCountry();
    }
    return () => {
      isCancelled = true;
    };
  }, [])

  const fetchCountry = async () => {
    await Address.getCountryList().then((res) => {
      setCountry(res.arrData);
    });
  };

  const fetchState = async (countryId) => {
    await Address.getStateList(countryId).then((res) => {
      setState(res.arrData);
    });
  };

  const changeHandler = (e) => {
    if (e.target.name === "country") {
      fetchState(e.target.value)
    }
  }


  return (
    <div className='ps-md-3 ps-0'>
      <button className='toggle_btn ps-md-3 ps-0 mb-2' onClick={() => { setToggle(!toggle); checkout.resetForm(); getShippingCharge(0) }}>
        <i className="fa fa-plus me-1" aria-hidden="true"></i>
        {t('checkout.new_address.add_new_address')}
      </button>
      {
        toggle
          ?
          <div className={`change-address-wrapper px-3 ${toggle ? "d-block" : "d-none"}`}>
            <form className="checkout-form">
              <h5 className='mb-3'>{t('checkout.new_address.add_new_shipping_address')}</h5>
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  <div className="input-div">
                    <input
                      type="text"
                      placeholder={t('checkout.form.address1.placeholder')}
                      name="address1"
                      onChange={checkout.handleChange}
                      onBlur={checkout.handleBlur}
                      value={checkout.values.address1}
                    />
                    {checkout.touched.address1 && checkout.errors.address1 ? (
                      <div className='text-danger'>{checkout.errors.address1}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="input-div">
                    <input
                      type="text"
                      placeholder={t('checkout.form.address2.placeholder')}
                      name="address2"
                      onChange={checkout.handleChange}
                      value={checkout.values.address2}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-md-6 mb-4">
                  <select
                    name='country'
                    onChange={(e) => { checkout.handleChange(e); changeHandler(e) }}
                    value={checkout.values.country}>
                    <option value="">{t('checkout.form.country.placeholder')}</option>
                    {
                      country.length > 0
                        ? country.map((country, index) => (
                          <option key={index} value={country.uid}>{country.name}</option>
                        ))
                        : ""
                    }
                  </select>
                  {checkout.touched.country && checkout.errors.country ? (
                    <div className='text-danger'>{checkout.errors.country}</div>
                  ) : null}

                </div>
                <div className="col-xl-6 col-md-6 mb-4">
                  <select
                    name='state'
                    onChange={(e) => { checkout.handleChange(e); getShippingCharge(e) }}
                    value={checkout.values.state}>
                    <option value="">{t('checkout.form.state.placeholder')}</option>
                    {
                      state.length > 0
                        ? state.map((state, index) => (
                          <option key={index} value={state.uid}>{state.name}</option>
                        ))
                        : ""
                    }
                  </select>
                  {checkout.touched.state && checkout.errors.state ? (
                    <div className='text-danger'>{checkout.errors.state}</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  <div className="input-div">
                    <input
                      type="text"
                      placeholder={t('checkout.form.city.placeholder')}
                      name="city"
                      onChange={checkout.handleChange}
                      onBlur={checkout.handleBlur}
                      value={checkout.values.city}
                    />
                    {checkout.touched.city && checkout.errors.city ? (
                      <div className='text-danger'>{checkout.errors.city}</div>
                    ) : null}
                  </div>

                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="input-div">
                    <input
                      type="text"
                      placeholder={t('checkout.form.zipcode.placeholder')}
                      name="zipcode"
                      onChange={checkout.handleChange}
                      onBlur={checkout.handleBlur}
                      value={checkout.values.zipcode}
                    />
                    {checkout.touched.zipcode && checkout.errors.zipcode ? (
                      <div className='text-danger'>{checkout.errors.zipcode}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
          : ""
      }
      <div className='change-address-wrapper ps-md-3 ps-0'>
        <form className="checkout-form">
          <h5 className='mb-3'>{t('checkout.new_address.additional_information')}</h5>
          <textarea
            id="orderNotes"
            cols="80"
            rows="4"
            name='note'
            placeholder={t('checkout.form.note.placeholder')}
            onChange={checkout.handleChange}
            onBlur={checkout.handleBlur}
            value={checkout.values.note}>
          </textarea>
          {checkout.touched.note && checkout.errors.note ? (
            <div className='text-danger'>{checkout.errors.note}</div>
          ) : null}
        </form>
      </div>
    </div>
  )
}
