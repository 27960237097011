import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { BreadCrumb, Meta } from '../components';
import { useTranslation } from 'react-i18next';
const About = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Meta
                type="about"
            />
            <BreadCrumb title={t('about.title')} />
            <section className="about-us-area pt-120 pb-75 pt-md-60 pb-md-15 pt-xs-50 pb-xs-10">
                <div className="container">
                    <div className="image-section">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="image-1">
                                    <img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/img-1.jpg"} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-2">
                                    <img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/img-2.jpg"} alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="section-title">
                            <div className="title-inner">
                                <div className="wrapper">
                                    <div className="sub-content">
                                        <img className="line-1 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                                        <span className="sub-text">{t('about.since_from_2000')}</span>
                                        <img className="line-2 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                                    </div>
                                    <h2 className="title">{t('about.about_our_story')}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-text">
                        <div className="row">
                            <div className="col-lg-4">
                                <p className="description">{t('about.description_1')}</p>
                            </div>
                            <div className="col-lg-4">
                                <p className="description">{t('about.description_2')}</p>
                            </div>
                            <div className="col-lg-4">
                                <div className="service-list">
                                    <ul>
                                        <li><Link to={"#"}>{t('about.list_items.1')}</Link></li>
                                        <li><Link to={"#"}>{t('about.list_items.2')}</Link></li>
                                        <li><Link to={"#"}>{t('about.list_items.3')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="team-area">
                <div className="container">
                    <div className="sub-content">
                        <img className="line-1 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                        <span className="sub-text">{t('about.meet_with_team.small_title')}</span>
                        <img className="line-2 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                    </div>
                    <h2 className="title">{t('about.meet_with_team.title')}</h2>
                    <div className="slider-div">
                        <div className="swiper rts-cmmnSlider-over2" data-swiper="pagination">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="team-wraper">
                                        <div className="team-thumb">
                                            <Link to={"#"}><img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/1.jpg"} alt="collection-image" /></Link>
                                        </div>
                                        <div className="team-content">
                                            <h3>
                                                <Link to={"#"} className="item-catagory-box">{t('about.meet_with_team.team.1.name')}</Link>
                                            </h3>
                                            <h6>{t('about.meet_with_team.team.1.position')}</h6>
                                            <div className="footer__social">
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-facebook-f"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-twitter"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-linkedin-in"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="team-wraper">
                                        <div className="team-thumb">
                                            <Link to={"#"}><img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/2.jpg"} alt="collection-image" /></Link>
                                        </div>
                                        <div className="team-content">
                                            <h3>
                                                <Link to={"#"} className="item-catagory-box">{t('about.meet_with_team.team.2.name')}</Link>
                                            </h3>
                                            <h6>{t('about.meet_with_team.team.2.position')}</h6>
                                            <div className="footer__social">
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-facebook-f"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-twitter"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-linkedin-in"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="team-wraper">
                                        <div className="team-thumb">
                                            <Link to={"#"}><img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/3.jpg"}
                                                alt="collection-image" /></Link>
                                        </div>
                                        <div className="team-content">
                                            <h3>
                                                <Link to={"#"} className="item-catagory-box">{t('about.meet_with_team.team.3.name')}</Link>
                                            </h3>
                                            <h6>{t('about.meet_with_team.team.3.position')}</h6>
                                            <div className="footer__social">
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-facebook-f"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-twitter"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-linkedin-in"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="team-wraper">
                                        <div className="team-thumb">
                                            <Link to={"#"}><img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/4.jpg"} alt="collection-image" /></Link>
                                        </div>
                                        <div className="team-content">
                                            <h3>
                                                <Link to={"#"} className="item-catagory-box">{t('about.meet_with_team.team.4.name')}</Link>
                                            </h3>
                                            <h6>{t('about.meet_with_team.team.4.position')}</h6>
                                            <div className="footer__social">
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-facebook-f"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-twitter"></i></Link>
                                                <Link className="footer-icon" to={"#"}><i aria-hidden="true"
                                                    className="fab fa-linkedin-in"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-area">
                <div className="container">
                    <div className="features-1">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-section">
                                    <Link to={"#"}><img className="lazyload"  data-src={process.env.REACT_APP_URL + "/assets/images/about/features-1.jpg"} alt="features-1" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-content">
                                    <div className="sub-content">
                                        <img className="line-1 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                                        <span className="sub-text">{t('about.Features.1.sub_text')}</span>
                                        <img className="line-2 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                                    </div>
                                    <h2 className="title">{t('about.Features.1.title')}</h2>
                                    <p className="description">{t('about.Features.1.description')}</p>
                                    <div className="section-button">
                                        <Link to={"#"}>{t('about.Features.1.btn_text')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="features-2">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="section-content">
                                    <div className="sub-content">
                                        <img className="line-1 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                                        <span className="sub-text">{t('about.Features.2.sub_text')}</span>
                                        <img className="line-2 lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"} alt="icon" />
                                    </div>
                                    <h2 className="title">{t('about.Features.2.title')}</h2>
                                    <p className="description">{t('about.Features.2.description')}</p>
                                    <div className="section-button">
                                        <Link to={"#"}>{t('about.Features.2.btn_text')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-section">
                                    <Link to={"#"}><img className="lazyload" data-src={process.env.REACT_APP_URL + "/assets/images/about/features-2.jpg"} alt="image" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default About