import React, { useEffect, useState } from 'react';
import { GetPrice } from '../Helpers';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Product } from '../services';

const ComboProducts = ({ singleProduct }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(singleProduct);
    const dispatch = useDispatch();
    let total_price = singleProduct.product_price;

    const cartHandler = async () => {
        let ProductStock = await Product.getProductStockCheck(data.product_uid, 1);
        if (ProductStock) {
            AddAllToCart();
        }
    }

    const AddAllToCart = () => {
        dispatch({ type: "ADD", payload: { id: data.product_uid, slug: data.slug, title: data.product_name, image: data.product_image, quantity: 1, price: data.product_price, category: data.Category, brand: data.Brand } });
        data.combo_product.map((item, index) => {
            dispatch({ type: "ADD", payload: { id: item.product_uid, slug: item.slug, title: item.product_name, image: item.product_image, quantity: 1, price: item.product_price, category: item.Category, brand: item.Brand } });
        })
    }
    return (
        <div className="rts-top-listed-items c-combo-products mb--70">
            <div className="container">
                <div className="section-inner">
                    <div className="row">
                        <div className="col-xl-12 col-lg-8 col-md-6 col-sm-12 pr">
                            <div className="content-topbar">
                                <h3 className="heading">{t('product_details.combo_product.title')}</h3>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-6 p-0">
                                    <div className="wraper">
                                        <div className="image-section">
                                            <a href="product-details.html"><img src={singleProduct.product_image} alt="product image" /></a>
                                        </div>
                                        <div className="content">
                                            <a href="product-details.html" className="product-title">{singleProduct.product_name}</a>
                                            <p className="product-price">{GetPrice(singleProduct.product_price)}</p>
                                        </div>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </div>
                                </div>
                                {singleProduct.combo_product.map((item, index) => {
                                    total_price = (parseInt(total_price) + parseInt(item.product_price));
                                    return (
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-6 p-0" key={index}>
                                            <div className="wraper">
                                                <div className="image-section">
                                                    <a href="product-details.html"><img src={item.product_image} alt="product image" /></a>
                                                </div>
                                                <div className="content">
                                                    <a href="product-details.html" className="product-title">{item.product_name}</a>
                                                    <p className="product-price">{GetPrice(item.product_price)}</p>
                                                </div>
                                                {(singleProduct.combo_product.length == index + 1) ? <></> : <i className="fa fa-plus" aria-hidden="true"></i>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <p className="c-total-price">{t('product_details.combo_product.total_price')} {GetPrice(total_price)}</p>
                        <a className="addto-cart-btn action-item" onClick={() => { cartHandler() }}><i className="rt-basket-shopping"></i>{t('product_details.combo_product.add_all_to_cart')}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComboProducts