import React from 'react'
import { useSelector } from "react-redux";

export default function CartIcon() {
  const { cart } = useSelector((state) => state);
  return (
    <div className="cart action-item theme-custom-link">
      <div className="cart-nav">
        <div className="cart-icon icon">
          <i className="rt-cart" />
          <span className="wishlist-dot icon-dot">{cart.length}</span>
        </div>
      </div>
    </div>
  )
}
