//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";
import CompanyConfig from "../../CompanyConfig";
import { Customer } from "../../CustomerConfig";
import { Toast } from "../../../components";
import axios from "axios";

// const getCheckout = async (data) => {
//   try {
//     let result = await api
//       .post(Apis.Checkout, data)
//     if (result.data.error == 1) {
//       for (const key in result.data.message) {
//         Toast(result.data.message[key], "error");
//       }
//       return result.data;
//     }
//     return result.data;
//   } catch (error) {
//     console.error(error.message);
//   }
// }

const getProduct = async (slug) => {
  let data = {
    slug: slug,
  };
  try {
    let result = await api
      .post(Apis.GetProduct, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCheckout = async (data) => {
  try {
    let result = await api
      .post(Apis.Checkout, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getOrderList = async (data) => {
  try {
    let result = await api
      .post(Apis.GetOrderList, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getOrder = async (data) => {
  try {
    let result = await api
      .post(Apis.GetOrder, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getReorderItems = async (data) => {
  try {
    let result = await api
      .post(Apis.GetReorderItems, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCancelOrder = async (data) => {
  try {
    let result = await api
      .post(Apis.CancelOrder, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCancelOrders = async (data) => {
  try {
    let result = await api
      .post(Apis.GetCancelOrders, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}


const getReturnOrder = async (data) => {
  try {
    let result = await api
      .post(Apis.ReturnOrder, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getOrderForReturn = async (data) => {
  try {
    let result = await api
      .post(Apis.GetOrderForReturn, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getOrderReturnStatus = async (ItemId) => {
  const { CustomerData } = Customer();

  const parameter = {
    "user_uid": CustomerData.user_uid,
    "access_token": CustomerData.access_token,
    "order_detail_uid": ItemId
  }
  try {
    let result = await api
      .post(Apis.GetOrderForReturn, parameter)
    if (result.data.error == 1) {
      return result.data;
    }
    if (result.data.arrData.is_return == 1) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error.message);
  }
}

const getCancelReason = async () => {
  try {
    let result = await api
      .post(Apis.GetCancelReason, {})
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCoupon = async (data) => {
  try {
    let result = await api
      .post(Apis.GetCoupon, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getFreeGift = async (data) => {
  try {
    let result = await api
      .post(Apis.GetFreeGift, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getProductReview = async (data) => {
  try {
    let result = await api
      .post(Apis.ProductReview, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getProductStockCheck = async (productId = 0, quantity = 0) => {
  const { company_token } = CompanyConfig();
  const data = {
    company_token: company_token,
    product_uid: productId,
    quantity: quantity
  }

  try {
    return await api
      .post(Apis.ProductStockCheck, data)
      .then(res => {
        if (res.data.error == 1) {
          Toast(res.data.message[productId], "error");
          return false;
        }
        if (res.data.success == 1) {
          return true;
        }
        return false;
      }).catch((error) => {
        console.error(error.message);
      })
  } catch (error) {
    console.error(error.message);
  }
}

export default {
  getCheckout,
  getOrderList,
  getOrder,
  getReorderItems,
  getCancelOrder,
  getCancelOrders,
  getReturnOrder,
  getOrderForReturn,
  getOrderReturnStatus,
  getCancelReason,
  getCoupon,
  getProduct,
  getFreeGift,
  getProductStockCheck,
  getProductReview
};


