import React, { useEffect, useState } from 'react'
import { Blog } from '../services';
import Preload from '../General/Preload';

export default function AuthorDetails({ blog_slug }) {
  const [loading, setLoading] = useState(false);
  const [blogAuthor, setBlogAuthor] = useState({});

  useEffect(() => {
    let isCancelled = false;
    const fetchAuthor = async () => {
      setLoading(true);
      let parameter = {
        "slug": blog_slug
      }
      await Blog.getBlog(parameter).then((res) => {
        if (res.success === 1) {
          setBlogAuthor(res.arrData.blog_author[0])
          setLoading(false);
        } else {
          setBlogAuthor({})
          setLoading(false);
        }
      })
    }
    if (!isCancelled) {
      fetchAuthor();
    }
    return () => {
      isCancelled = true;
    }
  }, [blog_slug])


  return (
    loading
      ? <Preload type='blogProfile' />
      : <div className="author-info d-flex align-items-center">
        <div className="avatar">
          <img
            src={Object.values(blogAuthor).length ? blogAuthor.image : process.env.REACT_APP_URL + "/assets/images/featured/author.png"}
            alt="Author Image"
          />
        </div>
        <div className="info">
          <p className="author-name">{Object.values(blogAuthor).length ? blogAuthor.full_name : ""}</p>
          <p className="author-dsg">{Object.values(blogAuthor).length ? blogAuthor.description : ""}</p>
        </div>
      </div>
  )
}
