import { useSelector } from "react-redux";

const GetPrice = (price = 0.00) => {
  const { setting } = useSelector((state) => state);
  if (setting != '') {
    if (setting.currency_setting) {
      var currency = setting.currency_setting.symbol;
      var display = setting.currency_setting.display;
      if (display == 'before') {
        return currency + ' ' + price;
      } else {
        return price + ' ' + currency;
      }
    }
  }
  return;
}

const GetCurrency = () => {
  const { setting } = useSelector((state) => state);
  if (setting.currency_setting) {
    return setting.currency_setting.symbol;
  }
}

const GetLogo = () => {
  const { ecommerce_setting = null } = useSelector((state) => state.setting);
  let logo = `${ecommerce_setting != null ? ecommerce_setting.logo : ""}`;
  return logo;
}

const GetStripePublicKey = () => {
  const { payment_gateway = null } = useSelector((state) => state.setting);
  let key = `${payment_gateway != null && payment_gateway.stripe_mode == 'sandbox' ? payment_gateway.stripe_sandbox_public_key : payment_gateway.stripe_live_public_key}`;
  return key;
}


export {
  GetPrice,
  GetCurrency,
  GetLogo,
  GetStripePublicKey,
};


