import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Toast } from "../../components";
import { Auth } from '../services';
import { Customer } from '../CustomerConfig';

import moment from "moment";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
export default function UpdateProfile() {
  const { t } = useTranslation();
  const { CustomerData } = Customer();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [count, setCount] = useState(0)
  const [image, setImage] = useState(`${process.env.REACT_APP_URL}/assets/images/placeholder/placeholder-user.png`);

  useEffect(() => {
    let isCancelled = false;
    const fetchUser = () => {
      let parameter = {
        "user_uid": CustomerData.user_uid,
        "access_token": CustomerData.access_token
      }
      Auth.getUser(parameter).then((res) => {
        if (res) {
          setUser(res.arrData)
          res.arrData.image &&
            setImage(res.arrData.image)
        }
      })
    }
    if (!isCancelled) {
      fetchUser();
    }
    return () => {
      isCancelled = true;
    };
  }, [count])

  const handleChange = (e) => {
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
  }

  const updateProfile = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user && user.first_name,
      last_name: user && user.last_name,
      email: user && user.email,
      mobile: user && user.mobile,
      dob: user && moment(user.birth_date).format("YYYY-MM-DD"),
      gender: user && user.gender,
      profile_image: null,
    },
    validationSchema: Yup.object({
      first_name:
        Yup.string()
          .max(15, t('account.tabs.2.form.first_name.validation.max'))
          .matches(/^[aA-zZ\s]+$/, t('account.tabs.2.form.first_name.validation.matches'))
          .required(t('account.tabs.2.form.first_name.validation.required')),
      last_name:
        Yup.string()
          .max(20, t('account.tabs.2.form.last_name.validation.max'))
          .matches(/^[aA-zZ\s]+$/, t('account.tabs.2.form.last_name.validation.matches'))
          .required(t('account.tabs.2.form.last_name.validation.required')),
      mobile:
        Yup.number()
          .typeError(t('account.tabs.2.form.mobile.validation.typeError'))
          .positive(t('account.tabs.2.form.mobile.validation.positive'))
          .integer(t('account.tabs.2.form.mobile.validation.integer'))
          .min(10)
          .required(t('account.tabs.2.form.mobile.validation.required')),
      email:
        Yup.string()
          .email(t('account.tabs.2.form.email.validation.email'))
          .required(t('account.tabs.2.form.email.validation.required')),
      gender:
        Yup.string()
          .oneOf(
            ['M', 'F'],
            t('account.tabs.2.form.gender.validation.oneOf')
          )
          .required(t('account.tabs.2.form.gender.validation.required')),
      /* profile_image: Yup.mixed()
        .required(t('account.tabs.2.form.image.validation.required')) */
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.user_uid = CustomerData.user_uid;
      values.access_token = CustomerData.access_token;
      Auth.getUpdateProfile(values).then((res) => {
        if (res) {
          resetForm();
          Toast(res.message, "success");
          navigate("/account");
          setCount(prev => prev + 1)
        }
        setLoading(false);
      })
    },
  });

  return (
    <React.Fragment>
      <div className="account-main updateprofile filterd-items hide">
        <div className="login-form">
          <div className="section-title">
            <h2>{t('account.tabs.2.title')}</h2>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={updateProfile.handleSubmit}>
                <div className="form">
                  <div className="profile-dp-area">
                    <div className="profile-dp">
                      {image && <img src={image} alt="profile" />}
                    </div>
                    <label for="profile_image" className='profile_image_label'>Update Profile</label>
                  </div>
                  <input className="form-control" type="file"
                    onChange={(e) => { updateProfile.handleChange(e); updateProfile.setFieldValue('profile_image', e.currentTarget.files[0]); handleChange(e); }}
                    hidden={true} id="profile_image" />
                  {updateProfile.touched.profile_image && updateProfile.errors.profile_image ? (
                    <div className='text-danger'>{updateProfile.errors.profile_image}</div>
                  ) : null}
                </div>
                <div className="form">
                  <input type="text"
                    className="form-control" placeholder={t('account.tabs.2.form.first_name.placeholder')} name="first_name" onChange={updateProfile.handleChange}
                    onBlur={updateProfile.handleBlur}
                    value={updateProfile.values.first_name} />
                  {updateProfile.touched.first_name && updateProfile.errors.first_name ? (
                    <div className='text-danger'>{updateProfile.errors.first_name}</div>
                  ) : null}
                </div>
                <div className="form">
                  <input type="text"
                    className="form-control" placeholder={t('account.tabs.2.form.last_name.placeholder')} name="last_name" onChange={updateProfile.handleChange}
                    onBlur={updateProfile.handleBlur}
                    value={updateProfile.values.last_name} />
                  {updateProfile.touched.last_name && updateProfile.errors.last_name ? (
                    <div className='text-danger'>{updateProfile.errors.last_name}</div>
                  ) : null}
                </div>
                <div className="form">
                  <input type="email" className="form-control" placeholder={t('account.tabs.2.form.email.placeholder')} name="email"
                    onChange={updateProfile.handleChange}
                    onBlur={updateProfile.handleBlur}
                    value={updateProfile.values.email} />
                  {updateProfile.touched.email && updateProfile.errors.email ? (
                    <div className='text-danger'>{updateProfile.errors.email}</div>
                  ) : null}
                </div>
                <div className="form">
                  <input type="text" className="form-control" placeholder={t('account.tabs.2.form.mobile.placeholder')} name="mobile"
                    onChange={updateProfile.handleChange}
                    onBlur={updateProfile.handleBlur}
                    value={updateProfile.values.mobile} />
                  {updateProfile.touched.mobile && updateProfile.errors.mobile ? (
                    <div className='text-danger'>{updateProfile.errors.mobile}</div>
                  ) : null}
                </div>
                <div className="form">
                  <input type="date" className="form-control" name="dob"
                    onChange={updateProfile.handleChange}
                    value={updateProfile.values.dob} />
                </div>
                <div className="form">
                  <select name="gender" className="form-control"
                    onChange={updateProfile.handleChange}
                    onBlur={updateProfile.handleBlur}
                    value={updateProfile.values.gender}
                  >
                    <option value="">{t('account.tabs.2.form.gender.select.1')}</option>
                    <option value="M">{t('account.tabs.2.form.gender.select.2')}</option>
                    <option value="F">{t('account.tabs.2.form.gender.select.3')}</option>
                  </select>
                  {updateProfile.touched.gender && updateProfile.errors.gender ? (
                    <div className='text-danger'>{updateProfile.errors.gender}</div>
                  ) : null}
                </div>
                <div className="form">
                  <button type="submit" className="btn" disabled={loading ? true : false} >{loading ? t('account.tabs.2.form.button_loading') : t('account.tabs.2.form.button_update_profile')}
                    <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
