import React, { useEffect } from 'react'
import { BreadCrumb, Toast } from '../components';
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
const PaymentCancel = ({ title }) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  useEffect(() => {
    document.title = title;

    if (state !== null) {
      Toast(state.cancelStatusMsg, "error");
    }
  }, [])

  return (
    <React.Fragment>
      <BreadCrumb title={t('stripe.payment_cancel.title')} />
      <div className="thanks-area">
        <div className="container">
          <div className="section-inner">
            <div className="section-icon">
              <i className="fal fa-xmark" />
            </div>
            <div className="section-title">
              <h2 className="sub-title">{t('stripe.payment_cancel.main_title')}</h2>
              <h3 className="sect-title">
              {t('stripe.payment_cancel.content')}
              </h3>
            </div>
            <div className="section-button">
              {/* <Link className="btn-1" to="/">
                <i className="fal fa-long-arrow-left" /> Go To Homepage
              </Link> */}
              <Link className="btn-1" to="/pay">
              {t('stripe.payment_cancel.re_payment')}  &nbsp;&nbsp;<i className="fal fa-long-arrow-right" aria-hidden="true" />
              </Link>
              <h3>
              {t('stripe.payment_cancel.let_s_track_your_order_or')}
                <Link className="btn-2" href="/contact">
                  {" "}
                  &nbsp;&nbsp;{t('stripe.payment_cancel.contact_us')}
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaymentCancel;