import React from 'react'
import { Link } from 'react-router-dom'
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';

export default function TopHeaderMenu() {
  const { t } = useTranslation();
  return (
    <div className="header-topbar-menu-area">
      <div className="container">
        <div className="menu-area1">
          <div className="menu-item">
            <nav className="nav navbar">
              <div className="navbar-menu">
                <ul>
                  <li>
                    <Link to="/about">{t('header.abount_us')}</Link>
                  </li>
                  <li>
                    <Link to="/account">{t('header.my_account')}</Link>
                  </li>
                  <li>
                    <Link to="/wishlist">{t('header.wishlist')}</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="menu-area-right-side">
            <div className="contact">
              <i aria-hidden="true" className="fas fa-phone"  title={t('header.call_us')}/>
              {t('header.need_help')}?{" "}
              <strong>
              {t('header.call_us')}:
                <a href="tel:+0020500">+ 0020 500</a>
              </strong>
            </div>
            <SelectLanguage />
          </div>
        </div>
      </div>
    </div>
  )
}
