import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Menu(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <li>
        <Link className="menu-item active1" to={`/`}>
          {t('header.menu.home')}
        </Link>
      </li>
      <li className="has-dropdown">
        <Link className="menu-item" to="#">
          {t('header.menu.categories')} <i className="rt-plus" />
        </Link>
        <ul className="dropdown-ul">
          {
            Object.values(Object.assign({}, props.categories[0])).map((item, index) => {
              return (
                <li className="dropdown-li" key={index}>
                  <Link className="dropdown-link" to={`/categories/${item.category_slug}`}>
                    {item.category_name}
                  </Link>
                  {(Object.values(Object.assign({}, props.categories[item.uid])).length > 0) ?
                    <ul className="c-dropdown-ul c-dropdown-second">
                      {Object.values(Object.assign({}, props.categories[item.uid])).map((sitem, sindex) => {
                        return (
                          <li className="dropdown-li" key={sindex}>
                            <Link className="dropdown-link" to={`/categories/${sitem.category_slug}`}>
                              {sitem.category_name}
                            </Link>
                            {(Object.values(Object.assign({}, props.categories[sitem.uid])).length > 0) ?
                              <ul className="c-dropdown-ul c-dropdown-third">
                                {Object.values(Object.assign({}, props.categories[sitem.uid])).map((titem, sindex) => {
                                  return (
                                    <li className="dropdown-li" key={sindex}>
                                      <Link className="dropdown-link" to={`/categories/${titem.category_slug}`}>
                                        {titem.category_name}
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                              :
                              <>
                              </>
                            }
                          </li>
                        )
                      })}
                    </ul>
                    :
                    <>
                    </>
                  }
                </li>
              )
            })
          }
        </ul>
      </li>
      <li className="has-dropdown">
        <Link className="menu-item" to="#">
          {t('header.menu.brands')} <i className="rt-plus" />
        </Link>
        <ul className="dropdown-ul">
          <li className="dropdown-li">
            <div className="input-box sub-input mb-30 c-search_brand">
              <input type="text" name="search_brand" placeholder="Search Brand..." onChange={props.onChange} />
            </div>
          </li>
          {
            props.brand.map(({ brand_name, brand_slug }, index) => {
              return (
                <li className="dropdown-li" key={index}>
                  <Link className="dropdown-link" to={`/brands/${brand_slug}`}>
                    {brand_name}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </li>
      <li>
        <Link className="menu-item" to={`/products`}>
          {t('header.menu.products')}
        </Link>
      </li>
      <li>
        <Link className="menu-item" to={`/blogs`}>
          {t('header.menu.blog')}
        </Link>
      </li>
      <li>
        <Link className="menu-item" to={`/contact`}>
          {t('header.menu.contact')}
        </Link>
      </li>
    </React.Fragment>
  )
}
