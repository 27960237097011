import React, { useEffect, useState } from "react";
import { Product } from "../services";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

export default function OrderItems({ orderId, item, cancelOrders, Currency, order_status_uid }) {
  const { t } = useTranslation();
  const [status, setOrderStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false;
    setLoading(true);
    const orderStatus = () => {
      Product.getOrderReturnStatus(item.order_detail_uid).then((res) => {
        setOrderStatus(res);
        setLoading(false);
      });
    }

    if (!isCancelled) {
      orderStatus();
    }

    return () => {
      isCancelled = true
    }
  }, []);


  const CreateRating = (orderId, slug) => {
    localStorage.setItem("rating_order_id", orderId);
    navigate(`/product/${slug}?rating=true`, { state: { product_uid: item.product_uid } });
  }
  return (
    <React.Fragment>
      <tr>
        <td className="align_left">
          <b>{item.product_name}</b>
        </td>
        <td>{item.oder_quantity}</td>
        <td>{Currency + ' ' + item.oder_price}</td>
        <td>{Currency + ' ' + parseInt(item.oder_price) * parseInt(item.oder_quantity)}</td>
        <td>{!loading ? status ? t('account.tabs.4.order_view.yes') : t('account.tabs.4.order_view.no') : <Skeleton height={35} width={35} />}</td>
        <td>
          {
            !loading
              ? cancelOrders == 0 && !status ? (
                <Link
                  to={`/account/orders/item/return/${item.order_detail_uid}`}
                  className="btn btn-dark"
                >
                  <i className="fa fa-reply" />
                </Link>
              ) : (
                ""
              )
              : <Skeleton height={35} width={35} />
          }
        </td>
        {(order_status_uid == 6) ?
          <td>
            {
              !loading
                ? order_status_uid == 6 ? (
                  <a onClick={() => CreateRating(item.order_detail_uid, item.slug)}
                    className="btn btn-dark"
                  >
                    <i className="fal fa-star" />
                  </a>
                ) : (
                  ""
                )
                : <Skeleton height={35} width={35} />
            }
          </td>
          : <></>}
      </tr>
    </React.Fragment>
  );
}
