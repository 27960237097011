import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    BreadCrumb,
    BackToPage,
} from "../components";

import { AddressBookForm } from "../components/Forms";
import { Address } from "../components/services";
import { Customer } from "../components/CustomerConfig";
import { useTranslation } from 'react-i18next';

export default function AddAddress() {
    const { t } = useTranslation();
    const { CustomerData } = Customer();
    const { addressId } = useParams();
    const [addressData, setAddressData] = useState(null);

    useEffect(() => {
        let isCancelled = false;
        let parameter = {
            access_token: CustomerData.access_token,
            address_uid: addressId
        }
        const fetchAddress = async () => {
            await Address.getAddress(parameter).then((res) => {
                if (res.success == 1) {
                    setAddressData(res.arrData);
                }
            });
        };
        if (!isCancelled) {
            if (addressId) {
                fetchAddress();
            }
        }
        return () => {
            isCancelled = true;
        };
    }, [addressId])


    return (
        <React.Fragment>
            <BreadCrumb title={addressData === null ? t('account.tabs.6.add.title') : t('account.tabs.6.edit.title')} />
            <div className="rts-shop-section order_list">
                <div className="container w-1220">
                    <div className="row gutter-lg mb-4">
                        <div className="col-lg-12 col-md-12 c_desktop">
                            <AddressBookForm addressData={addressData} />
                        </div>
                        <div className="col-lg-12 col-md-12 c_mobile"></div>
                    </div>
                    <BackToPage title={t('account.tabs.6.go_to_address_list')} />
                </div>
            </div>
        </React.Fragment >
    );
}