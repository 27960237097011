import React from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function BackToPage({ navigateTo, title }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="section-button-back section-button">
        <button className="btn-1" onClick={() => navigate(navigateTo ? navigateTo : -1)}>
          <i className="fal fa-long-arrow-left"></i>
          {title ? title : t('back')}
        </button>
      </div>
    </React.Fragment>
  )
}
