import React, { useState, useRef, useEffect } from 'react'
import { Product, Auth } from "../../components/services"
import CompanyConfig from "../../components/CompanyConfig";
import { Customer } from "../CustomerConfig";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../components";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function Coupon({ cart }) {
  const { t } = useTranslation();
  const inputElement = useRef();
  const dispatch = useDispatch();
  const { company_token } = CompanyConfig();
  const [loading, setLoading] = useState(false);
  const { CustomerData } = Customer();
  const { total } = useSelector((state) => state.product[0]);

  const coupon = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code:
        Yup.string()
          .required(t('coupon.form.validation.required')),
    }),

    onSubmit: (values, { resetForm }) => {
      values.company_token = company_token;
      values.access_token = CustomerData.access_token;
      values.amount = total;
      setLoading(true);
      setTimeout(() => {
        Product.getCoupon(values).then((res) => {
          dispatch({ type: "DISCOUNT", payload: res.arrData })
          resetForm();
        })
        setLoading(false);
      }, 1000);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Auth.isAuthenticate()) {
      coupon.handleSubmit(e);
    } else {
      Toast(t('error_msg.auth_error'), "error");
    }
  }

  useEffect(() => {
    inputElement.current.focus();
  }, [])

  return (
    <div className="coupon-apply">
      <span className="coupon-text">{t('coupon.coupon_code')}</span>
      <form onSubmit={handleSubmit}>
        <div className="apply-input">
          <input type="text" ref={inputElement} placeholder={t('coupon.form.placeholder')} name='code'
            onChange={coupon.handleChange}
            onBlur={coupon.handleBlur}
            value={coupon.values.code}
          />
          <button type="submit" className="apply-btn" disabled={(loading || !cart.length) ? true : false}>{!loading ? t('coupon.form.btn_apply') : ""}
            <i className={`fal ${loading ? "fa-spinner fa-spin" : ""}`} /></button>
        </div>
        {coupon.touched.code && coupon.errors.code ? (
          <div className='text-danger ms-md-3 mt-md-0 mt-3'>{coupon.errors.code}</div>
        ) : null}
      </form>
    </div>
  )
}
