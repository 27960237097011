import React, { useEffect, useState } from "react";
import { Toast } from "../components";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Link } from "react-router-dom";
import { Customer } from "../components/CustomerConfig";
import { Address } from "../components/services";

import {
    BreadCrumb,
    BackToPage,
    Preload,
    NotFound,
} from "../components";
import { useTranslation } from 'react-i18next';

export default function ListAddress() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [reload, setreload] = useState(false);
    const { CustomerData } = Customer();
    const [addressList, setAddressList] = useState([]);

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            fetchAddressList();
        }
        return () => {
            isCancelled = true;
        }
    }, [reload]);

    const fetchAddressList = () => {
        setLoading(true);
        let parameter = {
            access_token: CustomerData.access_token,
        };
        Address.getAddressList(parameter).then((res) => {
            if (res) {
                setAddressList(res.arrData);
                setLoading(false);
            }
        });
    };

    const customerAddressDelete = (uid) => {
        setLoading(true);
        let parameter = {
            access_token: CustomerData.access_token,
            address_uid: uid
        };
        Address.deleteAddress(parameter).then((res) => {
            if (res) {
                setLoading(false);
                setreload(true);
                Toast(res.message, "success");
            }
        });
    }

    return (
        <React.Fragment>
            <BreadCrumb title={t('account.tabs.6.list.title')} />
            <div className="rts-shop-section order_list">
                <div className="container w-1220">
                    <div className="row gutter-lg mb-4">
                        <div className="col-lg-12 col-md-12 c_desktop">
                            <h2 className="mb-3 c-title-flex"><span>{t('account.tabs.6.list.my_address')}</span> <Link to={'/account/address/create'} className="btn c-custom-address-btn" > {t('account.tabs.6.list.add_address')}</Link></h2>
                            <table className="shop-table cart-table mt-2 table-bordered">
                                <thead>
                                    <tr>
                                        <th width="15%" className="align_left">
                                        {t('account.tabs.6.list.#')}
                                        </th>
                                        <th width="70%">{t('account.tabs.6.list.address')}</th>
                                        <th width="15%">{t('account.tabs.6.list.action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading ?
                                            addressList.length > 0
                                                ? addressList.map(
                                                    (
                                                        {
                                                            uid,
                                                            address1,
                                                            address2,
                                                            country,
                                                            state,
                                                            city,
                                                            zipcode
                                                        },
                                                        index
                                                    ) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{loading ? <Skeleton height={15} width={100} /> : ++index}</td>
                                                                <td>
                                                                    {loading ? <Skeleton height={15} width={100} /> : address1}
                                                                    ,
                                                                    {loading ? <Skeleton height={15} width={100} /> : address2}
                                                                    ,<br />
                                                                    {loading ? <Skeleton height={15} width={100} /> : country}
                                                                    ,
                                                                    {loading ? <Skeleton height={15} width={100} /> : state}
                                                                    ,<br />
                                                                    {loading ? <Skeleton height={15} width={100} /> : city}
                                                                    ,
                                                                    {loading ? <Skeleton height={15} width={100} /> : zipcode}
                                                                    .
                                                                </td>
                                                                <td>
                                                                    {
                                                                        loading
                                                                            ? <Skeleton height={15} width={100} />
                                                                            : <div className="btn-icon-block">
                                                                                <Link
                                                                                    to={`/account/address/edit/${uid}`}
                                                                                    title={t('account.tabs.6.list.edit_address')}
                                                                                    className="btn btn-icon"
                                                                                >
                                                                                    <i className="fa fa-edit"></i>
                                                                                </Link>
                                                                                <button
                                                                                    title={t('account.tabs.6.list.delete_address')}
                                                                                    className="btn btn-icon"
                                                                                    onClick={() => { if (window.confirm(t('account.tabs.6.list.detete_this_address'))) { customerAddressDelete(uid) }; }}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                                : <tr>
                                                    <td colSpan={7} style={{ border: 0 }}>
                                                        <NotFound />
                                                    </td>
                                                </tr>
                                            : <Preload type="feed" />
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-12 col-md-12 c_mobile"></div>
                    </div>
                    <BackToPage navigateTo={"/account"} />
                </div>
            </div>
        </React.Fragment >
    );
}