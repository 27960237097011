import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function MobileMenu(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <li className="firstlvl">
        <Link className="mm-link theme-custom-link" to="/" aria-expanded="false">
        {t('header.menu.home')}
        </Link>
      </li>
      <li className="has-dropdown firstlvl">
        <Link className="mm-link" to="#" aria-expanded="false">
        {t('header.menu.categories')} <i className="rt-angle-down" />
        </Link>
        <ul
          className="sub-menu mega-dropdown-mobile mm-collapse"
          style={{ height: 14 }}
        >
          <li className="mega-dropdown-li">
            <ul className="mega-dropdown-ul mm-show mm-collapse">
              {
                Object.values(Object.assign({}, props.categories[0])).map((item, index) => {
                  return (
                    <li className="mega-dropdown-li" key={index}>
                      <Link className="dropdown-link2 theme-custom-link d-inline-block"
                        to={`categories/${item.category_slug}`}>
                        {item.category_name}
                      </Link>

                      {
                        Object.values(Object.assign({}, props.categories[item.uid])).length > 0 ?
                          <Link to="#" className='pl-3 d-inline-block' aria-expanded="false">
                            <i className="rt-angle-down" />
                          </Link>
                          : ""
                      }

                      {(Object.values(Object.assign({}, props.categories[item.uid])).length > 0)
                        ?
                        <ul
                          className="sub-menu mega-dropdown-mobile mm-collapse"
                          style={{ height: 14 }}
                        >
                          {Object.values(Object.assign({}, props.categories[item.uid])).map((sitem, sindex) => {
                            return (
                              <li className="mega-dropdown-li" key={sindex}>
                                <Link className="dropdown-link2 theme-custom-link d-inline-block"
                                  to={`categories/${sitem.category_slug}`}>
                                  {sitem.category_name}
                                </Link>

                                {
                                  Object.values(Object.assign({}, props.categories[sitem.uid])).length > 0 ?
                                    <Link to="#" className='pl-3 d-inline-block' aria-expanded="true">
                                      <i className="rt-angle-down" />
                                    </Link>
                                    : ""
                                }

                                {(Object.values(Object.assign({}, props.categories[sitem.uid])).length > 0) ?
                                  <ul
                                    className="sub-menu mega-dropdown-mobile mm-collapse"
                                    style={{ height: 14 }}
                                  >
                                    {Object.values(Object.assign({}, props.categories[sitem.uid])).map((titem, sindex) => {
                                      return (
                                        <li className="mega-dropdown-li" key={sindex}>
                                          <Link className="dropdown-link2 theme-custom-link d-inline-block"
                                            to={`categories/${titem.category_slug}`}>
                                            {titem.category_name}
                                          </Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                  :
                                  <>
                                  </>
                                }
                              </li>

                            )
                          })}
                        </ul>
                        :
                        <>
                        </>
                      }

                    </li>
                  )
                })
              }
            </ul>
          </li>
        </ul>
      </li>

      <li className="has-dropdown firstlvl">
        <Link className="mm-link" to="#" aria-expanded="false">
        {t('header.menu.brands')} <i className="rt-angle-down" />
        </Link>
        <ul
          className="sub-menu mega-dropdown-mobile mm-collapse"
          style={{ height: 14 }}
        >
          <li className="mega-dropdown-li">
            <ul className="mega-dropdown-ul mm-show mm-collapse">
              <li className="dropdown-li">
                <div className="input-box sub-input mb-30 c-search_brand">
                  <input type="text" name="search_brand" placeholder="Search Brand..." onChange={props.onChange} />
                </div>
              </li>
              {
                props.brand.map(({ brand_name, brand_slug }, index) => {
                  return (
                    <li className="dropdown-li" key={index}>
                      <Link className="dropdown-link2 theme-custom-link"
                        to={`brands/${brand_slug}`}>{brand_name}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <Link className="mm-link theme-custom-link" to="/products" aria-expanded="false">
        {t('header.menu.products')}
        </Link>
      </li>
      <li>
        <Link className="mm-link theme-custom-link" to="/blogs" aria-expanded="false">
        {t('header.menu.blog')}
        </Link>
      </li>
      <li>
        <Link className="mm-link theme-custom-link" to="/contact" aria-expanded="false">
        {t('header.menu.contact')}
        </Link>
      </li>
    </React.Fragment>
  )
}
