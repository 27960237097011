import React, { useEffect } from 'react'

import { BreadCrumb } from '../components';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function PaymentSuccess({ title }) {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = title;
  }, [])

  return (
    <React.Fragment>
      <BreadCrumb title={t('stripe.Payment_success.title')} />
      <div className="thanks-area">
        <div className="container">
          <div className="section-inner">
            <div className="section-icon">
              <i className="fal fa-check" />
            </div>
            <div className="section-title">
              <h2 className="sub-title">{t('stripe.Payment_success.main_title')}</h2>
              <h3 className="sect-title">
                {t('stripe.Payment_success.content')}
              </h3>
            </div>
            <div className="section-button">
              <Link className="btn-1" to="/">
                <i className="fal fa-long-arrow-left" /> {t('stripe.Payment_success.go_to_homepage')}
              </Link>
              <Link className="btn-1" to="/account/orders">
                <i className="fa fa-user-circle" aria-hidden="true" /> {t('stripe.Payment_success.my_account')}
              </Link>
              <h3>
              {t('stripe.Payment_success.let_s_track_your_order_or')}
                <Link className="btn-2" href="/contact">
                  {" "}
                  &nbsp;&nbsp;{t('stripe.Payment_success.contact_us')}
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
