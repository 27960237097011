import Accordion from 'react-bootstrap/Accordion';

function FaqCard({ title, content, eventKey, activeKey = 0 }) {
  return (
    <Accordion defaultActiveKey={[`${activeKey}`]}>
      <Accordion.Item eventKey={`${eventKey}`}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          {content}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion >
  );
}

export default FaqCard;