import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

export default function WishlistIcon() {
  const { wishlist } = useSelector((state) => state);

  return (
    <div className="cart action-item theme-custom-link">
      <div className="cart-nav">
        <div className="wishlist-icon icon">
          <Link to="/wishlist">
            <i className="rt-heart" />
            <span className="wishlist-dot icon-dot">
              {wishlist.length}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}
