import React from 'react'
import { Link } from 'react-router-dom'
import moment from "moment";
import AuthorDetails from './AuthorDetails';

function BlogCard({
  blog_title,
  blog_slug,
  blog_image,
  blog_content,
  blog_created_date,
  blog_category,
  blog_tag
}) {
  return (
    <div className="col-md-6 col-sm-12">
      <div className="full-wrapper wrapper-1 wrapper-4">
        <div className="image-part">
          <Link to={`/blogs/${blog_slug}`} className="image">
            <img
              className="lazyload"
              data-src={blog_image}
              alt="product name"
              srcset={`${blog_image} 1120w,${blog_image} 720w,${blog_image} 400w`}
              sizes="(min-width: 40em) calc(66.6vw - 4em),100vw" />
          </Link>
        </div>
        <div className="blog-content">
          <span className="date-full">
            <span className="day">{moment(blog_created_date).format("DD")}</span>
            <br />
            <span className="month">{moment(blog_created_date).format("MMM")}</span>
          </span>
          <ul className="blog-meta">
            <li>
              <Link to={`/blogs/${blog_slug}`}>{blog_title}</Link>
            </li>
          </ul>
          <div className="title">
            <Link to={`/blogs/${blog_slug}`}>
              <div
                dangerouslySetInnerHTML={{ __html: blog_content }}
              />
            </Link>
          </div>
          <AuthorDetails blog_slug={blog_slug} />
        </div>
      </div>
    </div>
  )
}

export default BlogCard