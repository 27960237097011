//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { Toast } from "../..";
import CompanyConfig from "../../CompanyConfig";
const getPrivacyPolicy = async () => {
  const { company_token } = CompanyConfig();
  try {
    let data = {
      company_token: company_token
    }
    let result = await api
      .post(Apis.PrivacyAndTermsOfUse, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data.arrData.privacy_policy;
  } catch (error) {
    console.error(error.message);
  }
}
const getTermsOfUse = async () => {
  const { company_token } = CompanyConfig();
  let data = {
    company_token: company_token
  }
  try {
    let result = await api
      .post(Apis.PrivacyAndTermsOfUse, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data.arrData.terms_of_use;
  } catch (error) {
    console.error(error.message);
  }
}

const CreateContactUs = async (data) => {
  try {
    let result = await api
      .post(Apis.CreateContactUs, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

export default {
  getPrivacyPolicy,
  getTermsOfUse,
  CreateContactUs,
};
