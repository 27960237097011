import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { common } from '@material-ui/core/colors';
import swal from 'sweetalert';

import { Product } from '../../services';

function HeaderCartItem(props) {
    const { t } = useTranslation();

    const { setting } = useSelector((state) => state);
    const dispatch = useDispatch();

    const GetPrice = (price = 0.00) => {
        if (setting != '') {
            if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
                var currency = setting.currency_setting.symbol;
                var display = setting.currency_setting.display;
                if (display == 'before') {
                    return currency + ' ' + price;
                } else {
                    return price + ' ' + currency;
                }
            }
        }
    }

    const removeProductCart = (data) => {
        swal({
            title: t('swal.cart_remove.title'),
            text: t('swal.cart_remove.text'),
            icon: "warning",
            buttons: [t('swal.cart_remove.cancelButtonText'), t('swal.cart_remove.confirmButtonText')],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch({ type: "REMOVE", payload: data });
                }

            });
    }


    const cartHandler = async (props) => {
        let ProductStock = await Product.getProductStockCheck(props.id, props.quantity);
        if (ProductStock) {
            return;
        }
    }

    return (
        <>
            <div className="product-item">
                <div className="product-detail">
                    <div className="product-thumb">
                        <img src={props.image} alt="product-thumb" />
                    </div>
                    <div className="item-wrapper">
                        <span className="product-name">{props.title}</span>
                        <div className="item-wrapper">
                            <span className="product-variation">
                                <span className="color">{props.category} / </span>
                                <span className="size">{props.brand}</span>
                            </span>
                        </div>
                        {(props.variation && props.variation != "") ?
                            <div className="item-wrapper">
                                <span className="product-variation">
                                    <span className="color">{props.variation && props.variation.variation_1.name} / </span>
                                    <span className="size">{props.variation && props.variation.variation_2.name}</span>
                                </span>
                            </div>
                            :
                            <>
                            </>
                        }
                        <div className="item-wrapper">
                            <span className="product-qnty">{props.quantity} ×</span>
                            <span className="product-price">{GetPrice(props.price)}</span>
                        </div>
                    </div>
                </div>
                <div className="cart-edit">
                    <div className="quantity-edit">
                        <button className="button" onClick={() => {
                            {
                                cartHandler(props.items);
                                if (props.quantity > 1) {
                                    dispatch({ type: "DECREASE", payload: props.items });
                                } else {
                                    dispatch({ type: "REMOVE", payload: props.items });
                                }
                            }
                        }}>
                            <i className="fal fa-minus minus" />
                        </button>
                        <input type="text" className="input" value={props.quantity} />
                        <button className="button plus" onClick={() => { cartHandler(props.items); dispatch({ type: "INCREASE", payload: props.items }) }}>
                            +<i className="fal fa-plus plus" />
                        </button>
                    </div>
                    <div className="item-wrapper d-flex mr--5 align-items-center">
                        {/* <Link to="/" className="product-edit">
                      <i className="fal fa-edit" />
                    </Link> */}
                        <Link className="delete-cart" onClick={() => { removeProductCart(props.items) }}>
                            <i className="fal fa-times" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderCartItem;