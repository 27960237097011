import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function SearchBox() {
  const [searchText, setSearchText] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchHandler = () => {
    if (searchText.length > 2 || searchText.length < 1) {
      navigate({
        pathname: '/products',
        search: `?search=${searchText}`,
      });
    }
  }

  return (
    <div className="search-part">
      <div className="search-input-area">
        <div className="container">
          <div className="search-input-inner">
            <div className="input-div d-flex align-items-center">
              <input
                className="search-input"
                type="text"
                placeholder={t('header.top_search')}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className='search-custom-btn' onClick={searchHandler}>
                <i className="fa fa-search" aria-hidden="true"></i>
              </div>
            </div>
            <div className="search-close-icon">
              <i className="rt-xmark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
