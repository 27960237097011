import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function NotFound({ title }) {
  const { t } = useTranslation();
  useEffect(() => {
    // title
    document.title = title;
  }, [])

  return (
    <React.Fragment>
      <div className="error-area">
        <div className="content-area">
          <div className="container">
            <div className="error-404">
              <div className="section-inner">
                <div className="error-img">
                  <img src={`${process.env.REACT_APP_URL}/assets/images/error/404-1.png`} alt="error-img" />
                </div>
                <div className="title">
                  <h2 className="sub-title">{t('404.title')}</h2>
                  <h3 className="sect-title">{t('404.sub_title')}</h3>
                </div>
                <div className="section-button">
                  <Link to={`/`}><i className="fal fa-long-arrow-left"></i> {t('404.go_to_homepage')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
