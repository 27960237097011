import React, { useEffect, useState } from "react";

import { BreadCrumb, OrderItems, BackToPage, Preload } from "../components";
import { useParams } from "react-router-dom";
import { Product } from "../components/services";
import { Customer } from "../components/CustomerConfig";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import { GetCurrency } from "../components/Helpers";
import { useTranslation } from 'react-i18next';
export default function OrderView() {
  const { t } = useTranslation();
  const Currency = GetCurrency();
  const [loading, setLoading] = useState(false);
  const { CustomerData } = Customer();
  const { orderId } = useParams();
  const [order, setOrder] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false;

    const fetchOrder = async () => {
      setLoading(true);
      let parameter = {
        user_uid: CustomerData.user_uid,
        access_token: CustomerData.access_token,
        order_uid: orderId,
      };
      Product.getOrder(parameter).then((res) => {
        if (res) {
          setOrder(res.arrData);
          setLoading(false);
        }
      });
    };
    if (!isCancelled) {
      fetchOrder();
    }
    return () => {
      isCancelled = true;
    }
  }, []);

  const Repayment = (orderId) => {
    localStorage.setItem("order_id", orderId);
    navigate("/pay");
  }

  return (
    <React.Fragment>
      <BreadCrumb title={t('account.tabs.4.order_view.title')} />
      <div className="rts-shop-section order_list">
        <div className="container w-1220">
          {
            loading ?
              <Preload type="feed" />
              : <div className="row gutter-lg mb-4">
                <div className="col-lg-12 col-md-12 c_desktop">
                  <h2>{t('account.tabs.4.order_view.main_title')}</h2>
                  <div>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <td colSpan={2} className="align_left">
                          {t('account.tabs.4.order_view.order_details')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align_left">
                            <b>{t('account.tabs.4.order_view.order_id')}</b>{t('account.tabs.4.order_view.pos_001')}{order.unique_order_number}
                            <br />
                            <b>{t('account.tabs.4.order_view.date')}</b>{" "}
                            {moment(order.order_date).format("DD MMM YYYY")}
                          </td>
                          <td className="align_left">
                            <b>{t('account.tabs.4.order_view.payment_method')}{order.payment_method} </b>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <th className="align_left">{t('account.tabs.4.order_view.payment_address')}</th>
                          <th className="align_left">{t('account.tabs.4.order_view.shipping_address')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align_left">
                            <div
                              dangerouslySetInnerHTML={{ __html: order.billing_address }}
                            />
                          </td>
                          <td className="align_left">
                            <div
                              dangerouslySetInnerHTML={{ __html: order.shipping_address }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <th width="25%" className="align_left">
                          {t('account.tabs.4.order_view.name')}
                          </th>
                          <th width="10%">{t('account.tabs.4.order_view.qty')}</th>
                          <th width="20%">{t('account.tabs.4.order_view.price')}</th>
                          <th width="20%">{t('account.tabs.4.order_view.total')}</th>
                          <th width="15%">{t('account.tabs.4.order_view.is_return')}</th>
                          <th width="5%">{t('account.tabs.4.order_view.action')}</th>
                          {(order.order_status_uid == 6) ?
                          <th width="5%">{t('account.tabs.4.order_view.rating')}</th>
                          :<></>}
                        </tr>
                      </thead>
                      <tbody>
                        {order.order_items
                          ? order.order_items.map((item, index) => {
                            return (
                              <OrderItems
                                orderId={orderId}
                                item={item}
                                cancelOrders={order.dispute}
                                key={index}
                                Currency={Currency}
                                order_status_uid={order.order_status_uid}
                              />
                            );
                          })
                          : ""}
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.order_view.coupon_amount')}</td>
                          <td className="align_center textbold">
                            - {Currency + ' ' + order.voucher_amount}
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.order_view.shipping_amount')}</td>
                          <td className="align_center textbold">
                            + {Currency + ' ' + order.shipping_amount}
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.order_view.sub_total')}</td>
                          <td className="align_center textbold">
                            {Currency + ' ' + order.total_amount}
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.order_view.main_total')}</td>
                          <td className="align_center textbold">
                            {Currency + ' ' + order.total_amount}
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                    {(order.payment_status == "pending") ?
                      <button className="c-repayment-btn" onClick={() => Repayment(order.uid)}>{t('account.tabs.4.order_view.repayment')} <i className="fal fa-long-arrow-right"></i></button>
                      : <></>}
                    <h2>{t('account.tabs.4.order_view.order_status_history.title')}</h2>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <th width="25%" className="align_left">
                          {t('account.tabs.4.order_view.order_status_history.date')}
                          </th>
                          <th width="25%">{t('account.tabs.4.order_view.order_status_history.status')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.status_history ? (
                          order.status_history.map(
                            ({ created_date, status }, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {moment(created_date).format("DD MMM YYYY")}
                                  </td>
                                  <td>{status}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td>{t('account.tabs.4.order_view.order_status_history.no_status_history')}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 c_mobile"></div>
              </div>
          }

          <BackToPage title={t('account.tabs.4.order_view.go_to_order_list')} />
        </div>
      </div>
    </React.Fragment>
  );
}
