import React from 'react'
import { useSelector } from "react-redux";

export default function SocialList({ type = "default" }) {
  const { general_setting = null } = useSelector((state) => state.setting);
  return (
    <React.Fragment>
      <ul className={`ms-1 ${type === "rounded" ? "social-links-footer2" : type === "square" ? "social-icon" : "d-flex align-items-center"}`}>
        <li>
          <a
            className={`${type === "rounded" ? "platform fb" : type === "square" ? "" : "platform"}`}
            target="_blank"
            href={general_setting != null ? `${general_setting.facebook_link}` : ""}
            title={'Facebook'}
          >
            <i className={`fab fa-facebook-f`} />
          </a>
        </li>
        <li>
          <a
            className={`${type === "rounded" ? "platform ttr" : type === "square" ? "" : "platform"}`}
            target="_blank"
            href={general_setting != null ? general_setting.twitter_link : ""}
            title={'Twitter'}
          >
            <i className={`fab fa-twitter`} />
          </a>
        </li>
        <li>
          <a
            className={`${type === "rounded" ? "platform inst" : type === "square" ? "" : "platform"}`}
            target="_blank"
            href={general_setting != null ? general_setting.instagram_link : ""}
            title={'Instagram'}
          >
            <i className={`fab fa-instagram`} />
          </a>
        </li>
        <li>
          <a
            className={`${type === "rounded" ? "platform lkd" : type === "square" ? "" : "platform"}`}
            target="_blank"
            href={general_setting != null ? general_setting.linkedin_link : ""}
            title={'Linkedin'}
          >
            <i className={`fab fa-linkedin`} />
          </a>
        </li>
      </ul>
    </React.Fragment>
  )
}

