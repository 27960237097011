import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { useDispatch } from "react-redux";
import { Toast } from '../components';
import { useTranslation } from 'react-i18next';
const StripeResponse = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    var urlParams;
    (window.onpopstate = function () {
        var match,
            pl = /\+/g,  // Regex for replacing addition symbol with a space
            search = /([^&=]+)=?([^&]*)/g,
            decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
            query = window.location.search.substring(1);

        urlParams = {};
        while (match = search.exec(query))
            urlParams[decode(match[1])] = decode(match[2]);
    })();
    let isCancelled = false;
    useEffect(() => {
        const fetchData = async () => {
            try {
                await api
                    .post(Apis.StripePaymentResponse, urlParams)
                    .then((res) => {
                        if (res.data.success == 1) {
                            if (res.data.payment_status == 'paid') {
                                localStorage.removeItem('order_id');
                                localStorage.removeItem('cart');
                                Navigate('/thank-you');
                            } else if (res.data.payment_status == 'failed') {
                                Toast(t('stripe.response_fail_msg'), "error");
                                Navigate('/order-cancel');
                            } else {
                                Navigate('/pay');
                            }
                        }
                    })
            } catch (error) {
                console.error(error.message);
            }
        };
        if (!isCancelled) {
            fetchData();
        }
        return () => {
            isCancelled = true;
        }
    }, []);
    return (
        <>

        </>
    )
}

export default StripeResponse;