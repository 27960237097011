import React from 'react'

export default function SearchBoxIcon() {
  return (
    <div className="search-part theme-custom-link">
      <div className="search-icon action-item icon">
        <i className="rt-search" />
      </div>
    </div>
  )
}
