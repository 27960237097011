import React, { useEffect } from 'react';
import { CartProduct, Coupon, CouponSuccess } from '../components';
import { useSelector, useDispatch } from "react-redux";
import { BreadCrumb } from '../components';
import { Link } from 'react-router-dom';
import { GetPrice } from '../components/Helpers';
import { useTranslation } from 'react-i18next';

const Cart = ({ title }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    const { total, sub_total } = useSelector((state) => state.product[0]);

    useEffect(() => {
        // title
        document.title = title;
    }, [])

    return (
        <React.Fragment >
            <BreadCrumb title={t('cart.title')} />
            <div className="rts-cart-section" >
                <div className="container">
                    <h4 className="section-title">{t('cart.product_list')}</h4>
                    <div className="row justify-content-between">
                        <div className="col-xl-7">
                            <div className="cart-table-area">
                                <table className="table table-bordered table-hover">
                                    <thead className="thead-dark">
                                    </thead>
                                    <tbody>
                                        {cart.length ? cart.map((item, index) => {
                                            return (
                                                <CartProduct
                                                    image={item.image}
                                                    category={item.category}
                                                    brand={item.brand}
                                                    title={item.title}
                                                    price={item.price}
                                                    quantity={item.quantity}
                                                    id={item.id}
                                                    variation={item.variation}
                                                    dispatch={dispatch}
                                                    key={index}
                                                />
                                            );
                                        })
                                            :
                                            <div className="error-img w-50 h-50 mx-auto">
                                                <img className="lazyload" data-src={`${process.env.REACT_APP_URL}/assets/images/error/empty_cart.png`} alt="error-img" />
                                            </div>
                                        }
                                    </tbody>
                                </table>
                                <Coupon cart={cart} />
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="checkout-box">
                                <div className="checkout-box-inner">
                                    <div className="subtotal-area">
                                        <span className="title">{t('cart.subtotal')}</span>
                                        <span className="subtotal-price">{GetPrice(sub_total)}</span>
                                    </div>
                                    <div className="shipping-check">
                                        <span className="title">{t('cart.shipping')}</span>
                                        <div className="check-options">
                                            <form>
                                                {/* <div className="form-group">
                                                    <input type="checkbox" id="fltrt" />
                                                    <label className="check-title" htmlFor="fltrt">Flat rate</label>
                                                </div> */}
                                                <div className="form-group">
                                                    <input type="checkbox" checked id="frsh" />
                                                    <label className="check-title" htmlFor="frsh">{t('cart.free_shipping')}</label>
                                                </div>
                                                <CouponSuccess />
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="shipping-location">
                                        <span className="shipping-to">Shipping to <span>NY.</span></span>
                                        <span className="change-address"><i className="fal fa-map-marker-alt mr--5"></i>Change address</span>
                                    </div> */}
                                    <div className="total-area">
                                        <span className="title">{t('cart.total')}</span>
                                        <span className="total-price">{GetPrice(total)}</span>
                                    </div>
                                </div>
                                <Link to="/checkout" className="procced-btn" disabled={(!cart.length) ? true : false} >{t('cart.procced_to_checkout')}</Link>
                                <Link to="/products" className="continue-shopping"><i className="fal fa-long-arrow-left"></i> {t('cart.continue_shopping')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Cart;