import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Auth } from '../components/services';
import { BreadCrumb, Meta } from '../components'
import { LoginForm, SignupForm } from '../components/Forms';
import { useTranslation } from 'react-i18next';
export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (Auth.isAuthenticate()) {
      navigate("/account")
    }
  }, [])

  return (
    <React.Fragment>
      <Meta
        type="login"
      />
      <BreadCrumb title={t('login.title')} />
      <div className="login-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 mr-md-10 mr-0">
              <LoginForm />
            </div>
            <div className="col-lg-6 col-md-12 ml-md-10 ml-0">
              <SignupForm />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
