//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { Toast } from "../..";
import CompanyConfig from "../../CompanyConfig";

const getBlogs = async (data) => {
  try {
    let result = await api
      .post(Apis.Blogs, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getBlog = async (data) => {
  try {
    let result = await api
      .post(Apis.GetBlog, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getTags = async () => {
  const { company_token } = CompanyConfig();
  let data = {
    company_token: company_token
  }
  try {
    let result = await api
      .post(Apis.BlogTags, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCategory = async () => {
  const { company_token } = CompanyConfig();
  let data = {
    company_token: company_token
  }
  try {
    let result = await api
      .post(Apis.BlogCategory, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getBlogAuthor = async (blogSlug) => {
  let parameter = {
    "slug": blogSlug
  }
  try {
    let result = await api
      .post(Apis.GetBlog, parameter)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data.arrData.blog_author;
  } catch (error) {
    console.error(error.message);
  }
}

const getPopularBlog = async () => {
  const { company_token } = CompanyConfig();
  let data = {
    company_token: company_token
  }
  try {
    let result = await api
      .post(Apis.PopularBlog, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

export default {
  getBlogs,
  getBlog,
  getTags,
  getCategory,
  getBlogAuthor,
  getPopularBlog,
};


