import React, { useEffect } from 'react'
import Slider from '@material-ui/core/Slider';

import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';


export default function PriceSlider({ handleClick }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState([0, 0]);
  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (searchParams.get("price")) {
      let str = searchParams.get("price").replace(/-/g, ",");
      let arr = str.split(",");
      value[0] = parseInt(arr[0]);
      value[1] = parseInt(arr[1]);
    }
  }, [searchParams])

  const GetPrice = (price = 0.00) => {
    let { setting } = useSelector((state) => state);

    if (setting != '') {
      if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
        var currency = setting.currency_setting.symbol;
        var display = setting.currency_setting.display;
        if (display == 'before') {
          return currency + ' ' + price;
        } else {
          return price + ' ' + currency;
        }
      }
    }
  }

  return (
    <div style={{
      margin: 'auto',
      display: 'block',
      width: '100%'
    }}>
      <span> {GetPrice(value[0])} - {GetPrice(value[1])}</span>
      <Slider
        name="price_slider"
        value={value}
        defaultValue={0}
        onChange={rangeSelector}
        valueLabelDisplay="auto"
        min={0}
        max={2000}
        step={4}
      />
      <button className='btn btn-danger' onClick={() => handleClick({ type: "price_slider", pricemin: value[0], pricemax: value[1] })}>{t('filter_count.filter')}</button>
    </div>
  )
}
