import React, { useEffect, useState } from 'react'
import { BreadCrumb, BackToPage, NotFound, Meta } from '../components'
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import SocialList from '../components/General/SocialList';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { Product } from '../components/services';
export default function Wishlist() {
  const { t } = useTranslation();
  const { setting } = useSelector((state) => state);
  const cart = useSelector((state) => state);
  const dispatch = useDispatch();

  const GetPrice = (price = 0.00) => {
    if (setting != '') {
      if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
        var currency = setting.currency_setting.symbol;
        var display = setting.currency_setting.display;
        if (display == 'before') {
          return currency + ' ' + price;
        } else {
          return price + ' ' + currency;
        }
      }
    }
  }

  const removeProductWishlist = (data) => {
    swal({
      title: t('swal.cart_remove.title'),
      text: t('swal.cart_remove.text'),
      icon: "warning",
      buttons: [t('swal.cart_remove.cancelButtonText'), t('swal.cart_remove.confirmButtonText')],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch({ type: "WISHLIST_REMOVE", payload: data })
        }

      });
  }

  const cartHandler = async (item) => {
    let ProductStock =
      await Product.getProductStockCheck(item.product_uid, item.quantity);
    if (ProductStock) {
      dispatch({
        type: "ADD",
        payload: item,
      })
    }
  }

  return (
    <React.Fragment>
      <Meta
        type="wishlist"
      />
      <BreadCrumb title={t('wishlist.title')} />
      <div className="rts-wishlist-section section-gap">
        <div className="container w-1220">
          <table className="table table-bordered table-hover">
            <tbody>
              <tr className="heading">
                <th />
                <th>{t('wishlist.product_name')}</th>
                <th>{t('wishlist.variation')}</th>
                <th>{t('wishlist.unit_price')}</th>
                <th>{t('wishlist.stock_status')}</th>
                <th />
              </tr>
            </tbody>
            <tbody>
              {(cart.wishlist.length > 0) ?
                cart.wishlist.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="first-td" key={index}>
                          <button className="remove-btn" onClick={() => { removeProductWishlist(item) }}>
                            <i className="fal fa-times" />
                          </button>
                        </td>
                        <td className="first-child">
                          <Link to="product-details">
                            <img className="lazyload" data-src={item.image} alt="product image" style={{ width: "100px" }} />
                          </Link>
                          <Link to="product-details" className="pretitle">
                            {item.title}
                          </Link>
                        </td>
                        <td>
                          {(item.variation.variation_1 != "") ?
                            <>
                              <span className="product-price">{item.variation && item.variation.variation_1.name} / {item.variation && item.variation.variation_2.name}</span>
                            </>
                            :
                            <> - - - </>
                          }
                        </td>
                        <td>
                          <span className="product-price">{GetPrice(item.price)}</span>
                        </td>
                        {(item.quantity == item.stock_out) ?
                          <td class="stock1">{t('wishlist.out_of_stock')}</td>
                          : <td className="stock">{t('wishlist.in_stock')}</td>}
                        <td className="last-td">
                          {(item.quantity == item.stock_out) ?
                            <button class="cart-btn1"><i class="rt-basket-shopping"></i> {t('wishlist.add_to_cart')}</button>
                            :
                            <button className="cart-btn" disabled={(parseInt(item.quantity) == parseInt(item.stock_out)) ? "disabled" : ""} onClick={() => cartHandler({ id: item.id, slug: item.slug, title: item.title, image: item.image, quantity: 1, price: item.price, category: item.category, brand: item.brand, variation: item.variation })}>
                              <i className="rt-basket-shopping" /> {t('wishlist.add_to_cart')}
                            </button>
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
                :
                <>
                  <tr>
                    <td className="first-td" colSpan="5">
                      <NotFound />
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
          <BackToPage navigateTo={"/account"} />
          <div className="wishlist-social">
            <div className="text">
              <Link to="/">{t('wishlist.share_now')}</Link>
            </div>
            <div className="icon">
              <SocialList />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
