import React from "react";
import { ItemCard, Preload, NotFound } from "../../components";
import { useTranslation } from 'react-i18next';
export default function ItemContent({ loading, product, setModel, Width }) {
  const { t } = useTranslation();
  return (
    <div className="products-area products-area3">
      {loading ? (
        <div className="d-flex mr-4">
          <Preload type="item" count={1} />
        </div>
      ) : (
        <div className="row justify-content-start">
          {Object.values(product).length > 0 ? (
            Object.values(product).map((item, index) => {
              return (
                <div className={`col-xl-${!Width ? "4" : "3"} col-md-4 col-sm-6`} key={index}>
                  <ItemCard item={item} setModel={setModel} />
                </div>
              );
            })
          ) : <NotFound />}
        </div>
      )}
    </div>
  );
}
