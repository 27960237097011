import React, { useState } from 'react';
import { PriceSlider } from "../../components";
import { Link, useLocation, } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
function FilterCount(props) {
    const { setting } = useSelector((state) => state);
    const { t } = useTranslation();
    let location = useLocation();

    const [toggle, setToggle] = useState(false);

    const GetPrice = (price = 0.00) => {
        if (setting != '') {
          if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
            var currency = setting.currency_setting.symbol;
            var display = setting.currency_setting.display;
            if (display == 'before') {
              return currency + ' ' + price;
            } else {
              return price + ' ' + currency;
            }
          }
        }
      }
    return (
        <React.Fragment>
            <div className="shop-product-topbar">
                <span className="items-onlist">{t('filter_count.showing')} {props.total} {t('filter_count.results')}</span>
                {
                    props.price_filter_show ?
                        <div className="c-price-filter-area" onClick={() => setToggle(!toggle)} style={{ cursor: "pointer" }}>
                            <i className="fa fa-filter me-1"></i>
                            {t('filter_count.filters')}
                        </div>
                        : ""
                }
                <div className="filter-area">
                    <p className="select-area">
                        <select className="select" name="order_by" defaultValue={"default"} onChange={(e) => { props.onChange(e) }} >
                            <option value="default">{t('filter_count.filter_select.default')}</option>
                            <option value="low_to_high" defaultValue={props.selected === "low_to_high" ? true : false}>{t('filter_count.filter_select.low_to_high')}</option>
                            <option value="high_to_low" defaultValue={props.selected === "high_to_low" ? true : false}>{t('filter_count.filter_select.high_to_low')}</option>
                            <option value="a-z" defaultValue={props.selected === "a-z" ? true : false}>{t('filter_count.filter_select.a_z')}</option>
                            <option value="z-a" defaultValue={props.selected === "z-a" ? true : false}>{t('filter_count.filter_select.z_a')}</option>
                        </select>
                    </p>
                </div>
            </div>

            {toggle
                ?
                <div className="shop-side-action">
                    <div className="row">
                        <div className="col-xl-3">
                            <div className="action-item">
                                <div className="action-top">
                                    <span className="action-title">{t('filter_count.price')}</span>
                                </div>
                                <div className="category-item" >
                                    <div className="category-item-inner">
                                        <div className="category-title-area">
                                            <Link to={`${location.pathname}?price=0-25`}>
                                                <span className="category-title">{GetPrice(0.00)+' - '+GetPrice(25.00)}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-item" >
                                    <div className="category-item-inner">
                                        <div className="category-title-area">
                                            <Link to={`${location.pathname}?price=25-50`}>
                                                <span className="category-title">{GetPrice(25.00)+' - '+GetPrice(50.00)}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-item" >
                                    <div className="category-item-inner">
                                        <div className="category-title-area">
                                            <Link to={`${location.pathname}?price=50-100`}>
                                                <span className="category-title">{GetPrice(50.00)+' - '+GetPrice(100.00)}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-item" >
                                    <div className="category-item-inner">
                                        <div className="category-title-area">
                                            <Link to={`${location.pathname}?price=100-150`}>
                                                <span className="category-title">{GetPrice(100.00)+' - '+GetPrice(150.00)}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-item" >
                                    <div className="category-item-inner">
                                        <div className="category-title-area">
                                            <Link to={`${location.pathname}?price=over-150`}>
                                                <span className="category-title">{GetPrice(150.00)+' + '}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="action-item">
                                <div className="action-top">
                                    <span className="action-title">{t('filter_count.price_range')}</span>
                                </div>
                                <PriceSlider handleClick={props.handleClick} />
                            </div>
                        </div>
                    </div>
                </div>
                : ""
            }
        </React.Fragment >
    )
}

export default FilterCount;