import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import styles from './style.module.css';

export default function App({ children }) {
  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        slidesPerGroup={1}
        speed={1500}
        loopFillGroupWithBlank={true}
        centeredSlides={false}
        breakpoints={{
          1600: {
            slidesPerView: 5,
          },
          1300: {
            slidesPerView: 5.6,
          },
          1220: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 4.5,
          },
          900: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          633: {
            slidesPerView: 3,
          },
          500: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          el: ".swiper-pag",
          clickable: true,
        }}
        className={styles.item_slider}
      >
        {
          children.map((childern, index) => {
            return (
              <SwiperSlide key={index}>{childern}</SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  );
}
