import React, { useState, useEffect } from "react";
import { useSearchParams, Link, useParams } from "react-router-dom";
import { BreadCrumb } from "../components";
import { ReviewForm } from "../components/Forms";

import {
  ProductData,
  FeaturedItemsList,
  ProductDetailsPopup,
  Meta,
} from "../components";

//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { useTranslation } from 'react-i18next';
import CompanyConfig from "../components/CompanyConfig";
import ComboProducts from "../components/Products/ComboProducts"
export default function ProductDetails({ title }) {
  const { t } = useTranslation();
  const { slug } = useParams();
  const { company_token } = CompanyConfig();
  const [model, setModel] = useState({});
  const [products, setProducts] = useState([]);
  const [singleProduct, setSingleProduct] = useState([]);
  const [searchParams] = useSearchParams();
  const rating = searchParams.get("rating") ? searchParams.get("rating") : false;

  if (rating) {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  }


  let Parameter1 = {
    company_token: company_token,
    keyword: "",
    categories: [],
    brands: [],
    price: {
      min: 0,
      max: 0,
    },
    sorting: {
      by: "price",
      order: "desc",
    },
    pagination: {
      per_page: 10,
      page: 1,
    },
  };

  let Parameter2 = {
    slug: slug,
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        await api.post(Apis.ProductList, Parameter1).then((res) => {
          if (res.data.success == 1) {
            setProducts(res.data.arrData);
          }
        });
        await api.post(Apis.GetProduct, Parameter2).then((res) => {
          if (res.data.success == 1) {
            setSingleProduct(res.data.arrData);
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [slug]);
  return (
    <React.Fragment>
      <Meta
        title={title + singleProduct.meta_title}
        keyword={title + singleProduct.meta_keyword}
        description={title + singleProduct.meta_description}
      />
      <BreadCrumb title={t('product_details.title')} />
      <div className="rts-product-details-section section-gap">
        <div className="container">
          <ProductData data={singleProduct} />
          {(singleProduct.combo_product !== undefined && singleProduct.combo_product.length > 0) ?
            <ComboProducts singleProduct={singleProduct} />
            :
            <></>
          }
          <div className="product-full-details-area">
            <div className="details-filter-bar2">
              <button
                className={`details-filter filter-btn ${(rating) ? '' : 'active'}`}
                data-show=".dls-one"
              >
                {t('product_details.tabs.1.title')}
              </button>
              {(rating) ?
                <button className={`details-filter filter-btn ${(rating) ? 'active' : ''}`}
                  data-show=".dls-three"
                >
                  {t('product_details.tabs.3.title')}
                </button>
                : <></>}
              {/* <button
                className="details-filter filter-btn"
                data-show=".dls-two"
              >
                {t('product_details.tabs.2.title')}
              </button>
              <button
                className="details-filter filter-btn"
                data-show=".dls-three"
              >
                {t('product_details.tabs.3.title')}
              </button> */}
            </div>
            <div className={`full-details dls-one filterd-items ${(rating) ? 'hide' : ''}`}>
              <div className="full-details-inner mb--30" >
                <p dangerouslySetInnerHTML={{ __html: singleProduct.product_full_description }} />
              </div>
            </div>
            {/* <div className="full-details dls-two filterd-items hide">
              <div className="full-details-inner">
                <p className="mb--30">
                  {t('product_details.tabs.2.content_1')}
                </p>
                <p>
                  {t('product_details.tabs.2.content_2')}
                </p>
              </div>
            </div> */}
            {(rating) ?
              <ReviewForm rating={rating} />
              : <></>}
          </div>
        </div>
      </div>
      <FeaturedItemsList
        icon={"wvbo-icon.png"}
        title={t('product_details.featured_product.title')}
        sub_title={t('product_details.featured_product.sub_title')}
        Objectdata={products}
        setModel={setModel}
      />
      <ProductDetailsPopup data={model} />
    </React.Fragment>
  );
}
