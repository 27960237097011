import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Setting } from '../services';

const Meta = (props) => {
  const pageType = props.type ?? "";
  const [pageData, setPageData] = useState({})

  useEffect(() => {
    Setting.getSeoSetting(pageType).then((res) => {
      res.success === 1 &&
        setPageData(res.arrRecord);
    })
  }, [pageType])

  return (
    pageType
      ?
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={Object.keys(pageData).length && pageData.seo_link} />
        {/* <!-- Primary Meta Tags --> */}
        <title>{Object.keys(pageData).length && pageData.seo_title}</title>
        <meta name="title" content={Object.keys(pageData).length && pageData.seo_title} />
        <meta name="description" content={Object.keys(pageData).length && pageData.seo_description} />
        <meta name="keyword" content={props.keyword ?? ""} />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={Object.keys(pageData).length && pageData.seo_link} />
        <meta property="og:title" content={Object.keys(pageData).length && pageData.seo_title} />
        <meta property="og:description" content={Object.keys(pageData).length && pageData.seo_description} />
        <meta property="og:image" content={Object.keys(pageData).length && pageData.seo_image} />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={Object.keys(pageData).length && pageData.seo_link} />
        <meta property="twitter:title" content={Object.keys(pageData).length && pageData.seo_title} />
        <meta property="twitter:description" content={Object.keys(pageData).length && pageData.seo_description} />
        <meta property="twitter:image" content={Object.keys(pageData).length && pageData.seo_image} />
      </Helmet>
      :
      <Helmet>
        <title>{props.title ?? ""}</title>
        <meta name="title" content={props.title ?? ""} />
        <meta name="description" content={props.description ?? ""} />
        <meta name="keyword" content={props.keyword ?? ""} />
      </Helmet>
  )
}
export default Meta;





