import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { BreadCrumb, Toast } from '../components';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CompanyConfig from '../components/CompanyConfig';

import { Auth } from '../components/services';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword({ title }) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { company_uid } = CompanyConfig();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // title
    document.title = title;

    if (state === null) {
      navigate("/forgot-password");
      Toast(t('change_password.form.unable_to_access'), "error");
    }
  }, [])

  const forgotPasswordVerify = useFormik({
    initialValues: {
      verify_code: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      verify_code:
        Yup.number()
          .typeError(t('change_password.form.verify_code.validation.typeError'))
          .positive(t('change_password.form.verify_code.validation.positive'))
          .integer(t('change_password.form.verify_code.validation.integer'))
          .required(t('change_password.form.verify_code.validation.required')),
      password:
        Yup.string()
          .required(t('change_password.form.password.validation.required')),
      confirm_password:
        Yup.string()
          .required(t('change_password.form.confirm_password.validation.required'))
          .oneOf([Yup.ref('password'), null], t('change_password.form.confirm_password.validation.oneOf')),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      Auth.getVerifyForgotPassword(values).then((res) => {
        if (res) {
          resetForm();
          Auth.logout()
          Toast(res.message, "success");
          navigate("/login");
        }
        setLoading(false);
      })
    },
  });

  return (
    <React.Fragment>
      <BreadCrumb title={t('change_password.title')} />
      <div className="contact-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form className="contact-form mb-10" onSubmit={forgotPasswordVerify.handleSubmit}>
                <div className="section-header section-header5 text-center align-items-center">
                  <div className="wrapper text-center">
                    <h2 className="title">{t('change_password.change_password')}</h2>
                    <div className="sub-content">
                      <img
                        className="line-1"
                        src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"}
                        alt="icon"
                      />
                      <span className="sub-text">{t('change_password.required_fields_are_marked')}</span>
                    </div>
                  </div>
                </div>
                <div className="info-form">
                  <div className="row justify-content-center w-50 m-auto">
                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                      <div className="input-box mail-input mb-3">
                        <input
                          type="text"
                          placeholder={t('change_password.form.verify_code.placeholder')}
                          name="verify_code"
                          onChange={forgotPasswordVerify.handleChange}
                          onBlur={forgotPasswordVerify.handleBlur}
                          value={forgotPasswordVerify.values.verify_code}
                        />
                        {forgotPasswordVerify.touched.verify_code && forgotPasswordVerify.errors.verify_code ? (
                          <div className='text-danger'>{forgotPasswordVerify.errors.verify_code}</div>
                        ) : null}
                      </div>
                      <div className="input-box mail-input mb-3">
                        <input
                          type="password"
                          placeholder={t('change_password.form.password.placeholder')}
                          name="password"
                          onChange={forgotPasswordVerify.handleChange}
                          onBlur={forgotPasswordVerify.handleBlur}
                          value={forgotPasswordVerify.values.password}
                        />
                        {forgotPasswordVerify.touched.password && forgotPasswordVerify.errors.password ? (
                          <div className='text-danger'>{forgotPasswordVerify.errors.password}</div>
                        ) : null}
                      </div>
                      <div className="input-box mail-input mb-3">
                        <input
                          type="password"
                          placeholder={t('change_password.form.confirm_password.placeholder')}
                          name="confirm_password"
                          onChange={forgotPasswordVerify.handleChange}
                          onBlur={forgotPasswordVerify.handleBlur}
                          value={forgotPasswordVerify.values.confirm_password}
                        />
                        {forgotPasswordVerify.touched.confirm_password && forgotPasswordVerify.errors.confirm_password ? (
                          <div className='text-danger'>{forgotPasswordVerify.errors.confirm_password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                      <button type="submit" className="form-btn form-btn4 w-100 text-center" disabled={loading ? true : false} >
                        {
                          loading ?
                            <i className={`fal ${loading ? "fa-spinner fa-spin" : ""}`} />
                            : t('change_password.button_submit')
                        }
                      </button>
                    </div>
                    <div className='d-flex justify-content-center mb-5 mt-3'>
                      <span className='text-muted me-2'>{t('change_password.already_have_account')} </span>
                      <Link to="/login" className='text-decoration-underline'>
                        {t('change_password.login')}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
