import React, { useEffect, useState } from 'react'

import { BreadCrumb, BackToPage, Preload, Toast } from '../components';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Product } from "../components/services"

import { Customer } from '../components/CustomerConfig';


import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function OrderView() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { CustomerData } = Customer();
  const navigate = useNavigate();
  const [returnOrder, setReturnOrder] = useState({});
  const [reasonList, setReasonLisrt] = useState({});
  const [userData, setUserData] = useState({});
  const { orderId } = useParams();
  const { setting } = useSelector((state) => state);

  const GetPrice = (price = 0.00) => {
    if (setting != '') {
      if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
        var currency = setting.currency_setting.symbol;
        var display = setting.currency_setting.display;
        if (display == 'before') {
          return currency + ' ' + price;
        } else {
          return price + ' ' + currency;
        }
      }
    }
  }

  useEffect(() => {
    let isCancelled = false;

    const fetchItem = async () => {
      setLoading(true);
      let parameter = {
        "user_uid": CustomerData.user_uid,
        "access_token": CustomerData.access_token,
        "order_detail_uid": orderId
      }
      await Product.getOrderForReturn(parameter).then((res) => {
        if (res) {
          setUserData(res.arrData.userdata);
          setReturnOrder(res.arrData);
          setLoading(false);
        }
      })
      await Product.getCancelReason().then((res) => {
        if (res) {
          setReasonLisrt(res.arrData);
        }
      })
    }
    if (!isCancelled) {
      fetchItem();
    }
    return () => {
      isCancelled = true;
    }
  }, [])


  const order_return = useFormik({
    initialValues: {
      reason: '',
      return_reason: '',
    },
    validationSchema: Yup.object({
      reason:
        Yup
          .string()
          .required(t('account.tabs.4.return_order_item.form.reason.validation.required')),
      return_reason:
        Yup
          .string()
          .required(t('account.tabs.4.return_order_item.form.return_reason.validation.required')),
    }),

    onSubmit: (values) => {
      setLoading(true);
      values.order_detail_uid = orderId;
      values.user_uid = CustomerData.user_uid;
      values.access_token = CustomerData.access_token;
      setTimeout(() => {
        Product.getReturnOrder(values).then((res) => {
          Toast(t('account.tabs.4.return_order_item.order') + res.message, "success");
          navigate(-1);
        })
        setLoading(false);
      }, 2000);
    },
  });

  return (
    <React.Fragment>
      <BreadCrumb title={t('account.tabs.4.return_order_item.title')} />
      <div className="rts-shop-section order_list">
        <div className="container w-1220">
          {
            loading ?
              <Preload type='feed' />
              : <div>
                <div className="row gutter-lg mb-4">
                  <div className="col-lg-12 col-md-12 c_desktop">
                    <h2>{t('account.tabs.4.return_order_item.main_title')}</h2>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <td colSpan={2} className="align_left">
                          {t('account.tabs.4.return_order_item.order_information')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.order_number')}
                          </td>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.pos_001')}{userData.unique_order_number}
                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.first_name')}
                          </td>
                          <td className="align_left">
                            {userData.first_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.last_name')}
                          </td>
                          <td className="align_left">
                            {userData.last_name}

                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.email')}
                          </td>
                          <td className="align_left">
                            {userData.email}

                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.mobile')}
                          </td>
                          <td className="align_left">
                            {userData.mobile}
                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.order_date')}
                          </td>
                          <td className="align_left">
                            {moment(userData.order_date).format('DD MMM YYYY')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <td colSpan={2} className="align_left">
                          {t('account.tabs.4.return_order_item.product_information.title')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.product_information.product_information')}
                          </td>
                          <td className="align_left">
                          {returnOrder.product_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.product_information.price')}
                          </td>
                          <td className="align_left">
                          {GetPrice(returnOrder.price)}
                          </td>
                        </tr>
                        <tr>
                          <td className="align_left">
                          {t('account.tabs.4.return_order_item.product_information.quantity')}
                          </td>
                          <td className="align_left">
                            {returnOrder.quantity}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-md-12 c_mobile"></div>
                </div>
                <div className="card p-5 mb-5 shadow-sm">
                  <h2 className='mb-3'>{t('account.tabs.4.return_order_item.return_order_item')}</h2>
                  <form onSubmit={order_return.handleSubmit}>
                    <label htmlFor="Reason For Return" className='my-2'>{t('account.tabs.4.return_order_item.form.return_reason.label')}</label>
                    <div className="form mb-3">
                      <select
                        name="return_reason"
                        className="form-control"
                        onChange={order_return.handleChange}
                        onBlur={order_return.handleBlur}
                        value={order_return.values.return_reason}
                      >
                        {
                          reasonList.length > 0
                            ?
                            reasonList.map(({ uid, title }, index) => {
                              return (
                                <option value={uid} key={index}>{title}</option>
                              )
                            })
                            : ""
                        }
                      </select>
                      {order_return.touched.return_reason && order_return.errors.return_reason ? (
                        <div className="text-danger">
                          {order_return.errors.return_reason}
                        </div>
                      ) : null}
                    </div>
                    <label htmlFor="return_order" className='my-2'>{t('account.tabs.4.return_order_item.form.reason.label')}</label>
                    <div className="form-group mb-3">
                      <textarea className="form-control" id="return_order" cols="80" rows="4" name="reason" placeholder={t('account.tabs.4.return_order_item.form.reason.placeholder')} onChange={order_return.handleChange}
                        onBlur={order_return.handleBlur}
                        value={order_return.values.reason}></textarea>
                      {order_return.touched.reason && order_return.errors.reason ? (
                        <div className='text-danger'>{order_return.errors.reason}</div>
                      ) : null}
                    </div>
                    <button type="submit" className="btn btn-2" disabled={(loading) ? true : false} >{!loading ? t('account.tabs.4.return_order_item.form.button_submit') : ""}
                      <i className={`fal ${loading ? "fa-spinner fa-spin" : ""}`} /></button>
                  </form>
                </div>
              </div>
          }
          <BackToPage />
        </div>
      </div>
    </React.Fragment>
  )
}
