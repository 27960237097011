import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Auth } from '../services';
import { Customer } from '../CustomerConfig';

import { Toast } from "../../components";
import { useTranslation } from 'react-i18next';
export default function PasswordChange() {
  const { t } = useTranslation();
  const { CustomerData } = Customer();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPasswordIcon1, setShowPasswordIcon1] = useState(false)
  const [showPasswordIcon2, setShowPasswordIcon2] = useState(false)
  const [showPasswordIcon3, setShowPasswordIcon3] = useState(false)

  const showPassword1 = () => {
    setShowPasswordIcon1(!showPasswordIcon1)
  }
  const showPassword2 = () => {
    setShowPasswordIcon2(!showPasswordIcon2)
  }
  const showPassword3 = () => {
    setShowPasswordIcon3(!showPasswordIcon3)
  }

  const ChangePassword = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(t('account.tabs.3.form.old_password.validation.required')),
      new_password: Yup.string().required(t('account.tabs.3.form.new_password.validation.required')),
      confirm_password: Yup.string().required(t('account.tabs.3.form.confirm_password.validation.required'))
        .oneOf([Yup.ref('new_password'), null], t('account.tabs.3.form.confirm_password.validation.oneOf')),
    }),
    onSubmit: (values, { resetForm }) => {
      values.user_uid = CustomerData.user_uid;
      values.access_token = CustomerData.access_token;
      setLoading(true);
      Auth.changePassword(values).then((res) => {
        if (res) {
          resetForm();
          Toast(res.message, "success");
          navigate("/account");
        }
        setLoading(false);
      })
    },
  });

  return (
    <React.Fragment>
      <div className="account-main accountdtls filterd-items hide">
        <div className="login-form">
          <div className="section-title">
            <h2>{t('account.tabs.3.change_password')}</h2>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={ChangePassword.handleSubmit}>
                <div className="form">
                  <div className="password-input">
                    <input type={`${showPasswordIcon1 ? "text" : "password"}`} className="form-control" name="old_password" placeholder={t('account.tabs.3.form.old_password.placeholder')}
                      onChange={ChangePassword.handleChange}
                      onBlur={ChangePassword.handleBlur}
                      value={ChangePassword.values.old_password}
                    />
                    {ChangePassword.touched.old_password && ChangePassword.errors.old_password ? (
                      <div className='text-danger'>{ChangePassword.errors.old_password}</div>
                    ) : null}
                    <span className={`${showPasswordIcon1 ? "hide" : "show"}-password-input`} onClick={showPassword1} />
                  </div>
                </div>
                <div className="form">
                  <div className="password-input">
                    <input type={`${showPasswordIcon2 ? "text" : "password"}`} className="form-control" name="new_password" placeholder={t('account.tabs.3.form.new_password.placeholder')}
                      onChange={ChangePassword.handleChange}
                      onBlur={ChangePassword.handleBlur}
                      value={ChangePassword.values.new_password}
                    />
                    {ChangePassword.touched.new_password && ChangePassword.errors.new_password ? (
                      <div className='text-danger'>{ChangePassword.errors.new_password}</div>
                    ) : null}
                    <span className={`${showPasswordIcon2 ? "hide" : "show"}-password-input`} onClick={showPassword2} />
                  </div>
                </div>
                <div className="form">
                  <div className="password-input">
                    <input type={`${showPasswordIcon3 ? "text" : "password"}`} className="form-control" name="confirm_password" placeholder={t('account.tabs.3.form.confirm_password.placeholder')}
                      onChange={ChangePassword.handleChange}
                      onBlur={ChangePassword.handleBlur}
                      value={ChangePassword.values.confirm_password}
                    />
                    {ChangePassword.touched.confirm_password && ChangePassword.errors.confirm_password ? (
                      <div className='text-danger'>{ChangePassword.errors.confirm_password}</div>
                    ) : null}
                    <span className={`${showPasswordIcon3 ? "hide" : "show"}-password-input`} onClick={showPassword3} />
                  </div>
                </div>
                <div className="form">
                  <button type="submit" className="btn" disabled={loading ? true : false} >{loading ? t('account.tabs.3.form.button_loading') : t('account.tabs.3.form.button_change_password')}
                    <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
                </div>
                <Link className="forgot-password" to="/forgot-password">{t('account.tabs.3.form.lost_your_password')}</Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
