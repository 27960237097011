//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { Toast } from "../..";
import CompanyConfig from "../../CompanyConfig";

const getSetting = async () => {
  const { company_token } = CompanyConfig();

  let data = {
    "company_token": company_token
  }

  try {
    let result = await api
      .post(Apis.GetSetting, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }

}

const getSeoSetting = async (page) => {
  const { company_token } = CompanyConfig();

  let data = {
    "company_token": company_token,
    "seo_page": page
  }

  try {
    let result = await api
      .post(Apis.GetSeoSetting, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }

}

export default {
  getSetting,
  getSeoSetting
};


