
import React, { useState, useEffect } from 'react';
import { BreadCrumb, FaqCard, Meta } from '../components';
import CompanyConfig from '../components/CompanyConfig';
//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { useTranslation } from 'react-i18next';
export default function Faq() {
  const { t } = useTranslation();
  const { company_token } = CompanyConfig();
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page = 1) => {
    setLoading(true);

    let Parameter = {
      "company_token": company_token
    }

    try {
      await api
        .post(Apis.GetFaq, Parameter)
        .then((res) => {
          if (res.data.success == 1) {
            setFaq(res.data.arrData);
            setLoading(false);
          }
        })
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <React.Fragment>
      <Meta
        type="faqs"
      />
      <BreadCrumb title={t('faq.title')} />
      <section className="faq-area pt-120 pb-90 pt-md-55 pb-md-30 pt-xs-55 pb-xs-30">
        <div className="container">
          <div className="row align-items-center">
            {faq.length > 0 ? (
              faq.map((item, index) => {
                return (
                  <div className="col-lg-6 col-md-6">
                    <div className="faq-que-list mb-30">
                      <div className="accordion">
                        <FaqCard
                          eventKey={index}
                          activeKey={0}
                          title={item.faq_question}
                          content={item.faq_answer}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>{t('records_not_found')}</>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
