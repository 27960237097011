import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from '../../components/services';
import { Toast } from '../../components'
import CompanyConfig from '../../components/CompanyConfig';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function SignupForm({ redirect = true, path = "" }) {
  const { t } = useTranslation();
  let location = useLocation();

  const { cart } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { company_token } = CompanyConfig();

  const [showPasswordIcon2, setShowPasswordIcon2] = useState(false);
  const [showPasswordIcon3, setShowPasswordIcon3] = useState(false);

  const showPassword2 = () => {
    setShowPasswordIcon2(!showPasswordIcon2)
  }
  const showPassword3 = () => {
    setShowPasswordIcon3(!showPasswordIcon3)
  }

  const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      dob: '',
      password: '',
      confirm_password: '',
      gender: '',
    },
    validationSchema: Yup.object({
      first_name:
        Yup.string()
          .max(15, t('registration.form.first_name.validation.max'))
          .matches(/^[aA-zZ\s]+$/, t('registration.form.first_name.validation.matches'))
          .required(t('registration.form.first_name.validation.required')),
      last_name:
        Yup.string()
          .max(20, t('registration.form.last_name.validation.max'))
          .matches(/^[aA-zZ\s]+$/, t('registration.form.last_name.validation.matches'))
          .required(t('registration.form.last_name.validation.required')),
      mobile:
        Yup.number()
          .typeError(t('registration.form.mobile.validation.typeError'))
          .positive(t('registration.form.mobile.validation.positive'))
          .integer(t('registration.form.mobile.validation.integer'))
          .min(10)
          .required(t('registration.form.mobile.validation.required')),
      email:
        Yup.string()
          .email(t('registration.form.email.validation.email'))
          .required(t('registration.form.email.validation.required')),
      dob:
        Yup.date()
          .required(t('registration.form.dob.validation.required')),
      password:
        Yup.string()
          .required(t('registration.form.password.validation.required')),
      confirm_password:
        Yup.string()
          .required(t('registration.form.confirm_password.validation.required'))
          .oneOf([Yup.ref('password'), null], t('registration.form.confirm_password.validation.oneOf')),
      gender:
        Yup.string()
          .oneOf(
            ['M', 'F'],
            t('registration.form.gender.validation.oneOf')
          )
          .required(t('registration.form.gender.validation.required')),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.company_token = company_token;
      if ((cart.length > 0) || location.pathname === '/checkout') {
        values.from = "checkout";
      }
      Auth.getRegsiter(values).then((res) => {
        if (res.success == 1) {
          resetForm();
          Toast(res.message, "success");
          setLoading(false);
          (cart.length > 0)
            ? navigate("/checkout")
            : (redirect)
              ? navigate("/home")
              : navigate(path ? path : location.pathname);
        }
        setLoading(false);
      })
    },
  });

  return (
    <div className="register-form">
      <div className="section-title">
        <h2>{t('registration.title')}</h2>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form">
              <input type="text"
                className="form-control" placeholder={t('registration.form.first_name.placeholder')} name="first_name" onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name} />
              {touched.first_name && errors.first_name ? (
                <div className='text-danger'>{errors.first_name}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="text"
                className="form-control" placeholder={t('registration.form.last_name.placeholder')} name="last_name" onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name} />
              {touched.last_name && errors.last_name ? (
                <div className='text-danger'>{errors.last_name}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="email" className="form-control" placeholder={t('registration.form.email.placeholder')} name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email} />
              {touched.email && errors.email ? (
                <div className='text-danger'>{errors.email}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="text" className="form-control" placeholder={t('registration.form.mobile.placeholder')} name="mobile"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile} />
              {touched.mobile && errors.mobile ? (
                <div className='text-danger'>{errors.mobile}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="date" className="form-control" name="dob"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dob} />
              {touched.dob && errors.dob ? (
                <div className='text-danger'>{errors.dob}</div>
              ) : null}
            </div>
            <div className="form">
              <select name="gender" className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.gender}
              >
                <option value="">{t('registration.form.gender.select.1')}</option>
                <option value="M">{t('registration.form.gender.select.2')}</option>
                <option value="F">{t('registration.form.gender.select.3')}</option>
              </select>
              {touched.gender && errors.gender ? (
                <div className='text-danger'>{errors.gender}</div>
              ) : null}
            </div>
            <div className="form">
              <div className="password-input">
                <input type={`${showPasswordIcon2 ? "text" : "password"}`} className="form-control" placeholder={t('registration.form.password.placeholder')} name="password" onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password} />
                <span className={`${showPasswordIcon2 ? "hide" : "show"}-password-input`} onClick={showPassword2} />
                {touched.password && errors.password ? (
                  <div className='text-danger'>{errors.password}</div>
                ) : null}
              </div>
            </div>
            <div className="form">
              <div className="password-input">
                <input type={`${showPasswordIcon3 ? "text" : "password"}`} className="form-control" placeholder={t('registration.form.confirm_password.placeholder')} name="confirm_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password}
                />
                <span className={`${showPasswordIcon3 ? "hide" : "show"}-password-input`} onClick={showPassword3} />
                {touched.confirm_password && errors.confirm_password ? (
                  <div className='text-danger'>{errors.confirm_password}</div>
                ) : null}
              </div>
            </div>
            <div className="form">
              <button type="submit" className="btn" disabled={loading ? true : false} >{loading ? t('registration.button_loading') : t('registration.button_register')}
                <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
