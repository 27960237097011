import React from 'react'
import { Link } from "react-router-dom";
import HeaderCartItem from './HeaderCartItem';
import { useSelector } from "react-redux";
import { CouponSuccess } from "../../../components"
import { GetPrice } from '../../Helpers';
import { useTranslation } from 'react-i18next';

export default function Cart(props) {
  const { setting } = useSelector((state) => state);
  const { total } = useSelector((state) => state.product[0]);
  const { t } = useTranslation();

  const GetPrice = (price = 0.00) => {
    if (setting != '') {
      if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
        var currency = setting.currency_setting.symbol;
        var display = setting.currency_setting.display;
        if (display == 'before') {
          return currency + ' ' + price;
        } else {
          return price + ' ' + currency;
        }
      }
    }
  }


  return (
    <React.Fragment>
      <div className="cart-bar">
        <div className="cart-header">
          <h3 className="cart-heading">{t('header.my_cart')} ({props.data.length} {t('header.items')})</h3>
          <div className="close-cart">
            <i className="fal fa-times" />
          </div>
        </div>
        <div className="product-area">
          {(props.data.length > 0) ?
            props.data.map((items, index) => {
              return (
                <HeaderCartItem
                  title={items.title}
                  image={items.image}
                  category={items.category}
                  brand={items.brand}
                  quantity={items.quantity}
                  price={items.price}
                  items={items}
                  variation={items.variation}
                  key={index}
                />
              )
            })
            :
            <></>
          }
        </div>
        <div className="cart-bottom-area">
          {/* <span className="spend-shipping">
            <i className="fal fa-truck" /> SPENT{" "}
            <span className="amount">$199.00</span> MORE FOR FREE SHIPPING
          </span> */}
          <CouponSuccess />
          <span className="total-price">
            {t('header.total')}: <span className="price">{GetPrice(total)}</span>
          </span>
          <Link to="/checkout" className="theme-custom-link checkout-btn cart-btn" disabled={true}>
            {t('header.proceed_to_checkout')}
          </Link>
          <Link to={"/cart"} className="theme-custom-link view-btn cart-btn">
            {t('header.view_cart')}
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}
