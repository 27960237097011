import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Blog } from '../services';

export default function Tags({ blog_tags = {} }) {
  const [blogTags, setBlogTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    const fetchBlog = async () => {
      setLoading(true);
      await Blog.getTags().then((res) => {
        if (res) {
          setBlogTags(res.arrData);
        }
        setLoading(false);
      })
    }
    if (!isCancelled) {
      fetchBlog();
    }
    return () => {
      isCancelled = true;
    }
  }, [])

  return (
    <div className="tag-list">
      <ul>
        {blog_tags.length > 0
          ?
          blog_tags.map(({ title, slug }, index) => {
            return (
              <li key={index}>
                <Link name={"tag"} data-tag={slug} to={'/blogs/tag/'+slug} >{title}</Link>
              </li>
            )
          })
          : blogTags.map(({ tag_name, tag_uid,tag_slug }, index) => {
            return (
              <li key={index}>
                <Link name={"tag"} data-tag={tag_slug} to={'/blogs/tag/'+tag_slug} >{tag_name}</Link>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
