import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
export default function CouponSuccess() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { discount } = useSelector((state) => state);

  return (
    discount.length > 0
      ?
      discount.map((discount, index) => (
        <div className="alert alert-success alert-dismissible fade show" role="alert" key={index}>
          <strong>{discount.coupon_code}!</strong> {t('coupon.coupon_applied')}
          <button type="button" className="btn-close" onClick={() => dispatch({ type: "DISCOUNT_REMOVE", payload: discount })}></button>
        </div>
      ))
      : ""
  )
}
