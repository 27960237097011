import React, { useEffect } from 'react'
import { BreadCrumb, Meta } from '../components';
import { Link } from 'react-router-dom'
import { ContactForm } from "../components/Forms";
import { useTranslation } from 'react-i18next';
export default function Contact() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Meta
        type="contact"
      />
      <BreadCrumb title={t('contact.title')} />
      <div className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="section-header section-header5 text-start">
                <div className="wrapper">
                  <div className="sub-content">
                    <img
                      className="line-1 lazyload"
                      data-src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"}
                      alt="icon"
                    />
                    <span className="sub-text">{t('contact.contact_us')}</span>
                  </div>
                  <h2 className="title">{t('contact.make_custom_request')}</h2>
                </div>
              </div>
              <ContactForm />
            </div>
            <div className="col-lg-4">
              <div className="right-side">
                <div className="get-in-touch">
                  <h3 className="section-title2">{t('contact.get_in_touch')}</h3>
                  <div className="contact">
                    <ul>
                      <li className="one">
                        {t('contact.address')}
                      </li>
                      <li className="two">
                        <Link to="tel:+0989057868978">+098 (905) 786 897 8</Link>
                        <Link to="tel:61463895748">6 - 146 - 389 - 5748</Link>
                      </li>
                      <li className="three">
                        {t('contact.store_hours')} <br />
                        {t('contact.store_hours_text')}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="section-button">
                  <div className="btn-1">
                    <Link to="#">
                      {t('contact.get_support_on_call')} <i className="fal fa-headphones-alt" />
                    </Link>
                  </div>
                  <div className="btn-2">
                    <Link to="#">
                      {t('contact.get_direction')} <i className="rt-location-dot" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="map">
          <p>
            <iframe
              className="lazyload"
              data-src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3651.0452483624595!2d90.424043!3d23.781403!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3cc42b4e4b430164!2sReacThemes!5e0!3m2!1sen!2sbd!4v1656420500360!5m2!1sen!2sbd"
              height={500}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </p>
        </div>
      </div>

    </React.Fragment>
  )
}
