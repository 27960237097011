import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CompanyConfig from '../../CompanyConfig'

//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";

import {
  TopHeader,
  TopHeaderMenu,
  SideBar,
  Cart,
  MainMenu,
  SearchBox
} from "../../../components";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function Index() {
  const { cart } = useSelector((state) => state);
  const { company_token } = CompanyConfig();
  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState([]);
  let location = useLocation();
  const [searchbrand, setSearchBrand] = useState('');
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        await api
          .post(Apis.Categories, {
            company_token: company_token,
            keyword: '',
          })
          .then((res) => {
            if (res.data.success == 1) {
              setCategories(res.data.arrData);
            }
          });
        await api
          .post(Apis.BrandList, {
            company_token: company_token,
            keyword: searchbrand,
          })
          .then((res) => {
            if (res.data.success == 1) {
              setBrand(res.data.arrData);
            }
          });
      } catch (error) {
        console.error(error.message);
      }
    };

    if (!isCancelled) {
      fetchData();
    }
    return () => {
      isCancelled = true;
    };
  }, [searchbrand]);

  const onChange = (e) => {
    setSearchBrand(e.target.value);
  }


  const TopHeaderOffers = [
    { title: t('header.top_text_slider.one') }, { title: t('header.top_text_slider.two') }
  ]

  return (
    <React.Fragment>
      <div className="anywere anywere-home" />
      {/*================= Header Section Start Here =================*/}
      <header id="rtsHeader">
        <TopHeader data={TopHeaderOffers} />
        <TopHeaderMenu />
        <MainMenu categories={categories} brand={brand} onChange={onChange} setToggle={setToggle} />
        <SearchBox />
        <Cart data={cart} />
        <SideBar categories={categories} brand={brand} onChange={onChange} toggle={toggle} setToggle={setToggle} />
      </header>
      {/*================= Header Section End Here =================*/}
    </React.Fragment>
  )
}