import React from 'react'
import { ChangeAddress, NewAddress } from ".."

export default function Address({ checkout, getShippingCharge }) {
  return (
    <div>
      <ChangeAddress checkout={checkout} getShippingCharge={getShippingCharge} />
      <NewAddress checkout={checkout} getShippingCharge={getShippingCharge} />
    </div>
  )
}
