import React from 'react'

import { Link } from 'react-router-dom';
import { GetPrice } from '../Helpers';
import { useTranslation } from 'react-i18next';

export default function Offer(props) {
  const { t } = useTranslation();
  const {coupon_code,discount_type,amount} = props.coupon;
  var final_amount = '';
  if(discount_type == 'percentage'){
      final_amount = amount+' %';
  }else{
    final_amount = GetPrice(amount);
  }
  return (
    <React.Fragment>
      <div className="rts-offer-section">
        <div className="container">
          <div className="rts-offer-inner">
            <p className="description">
              {t('homepage.coupon.left_text')} {final_amount} {t('homepage.coupon.right_text')} 
              <Link to="/products">{coupon_code}</Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
