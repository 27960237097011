import React from 'react'

import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';

export default function BreadCrumb(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="page-path">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="path-title">{props.title}</h1>
            <ul>
              <li><Link className="home-page-link" to="/">{t('breadcrumbs.home')} <i className="fal fa-angle-right"></i></Link></li>
              <li><Link className="current-page" to={`#`}>{props.title}</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
