import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BreadCrumb, Toast, Meta } from '../components'
import { Auth } from '../components/services';
import { UserOverview, PasswordChange, BillingAddress, UpdateProfile } from "../components"
import { useTranslation } from 'react-i18next';

export default function Account() {
  const [tabChange, setTabchange] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const logoutUser = () => {
    if (Auth.logout()) {
      navigate("/home")
      Toast(t('account.logout_successfully'), "success");
    } else {
      Toast(t('account.something_wrong'), "error");
    }
  }

  return (
    <React.Fragment>
      <Meta
        type="my_account"
      />
      <BreadCrumb title={t('account.title')} />
      <div className="rts-account-section section-gap">
        <div className="container">
          <div className="account-inner">
            <div className="account-side-navigation">
              <button className="filter-btn active" data-show=".dashboard" onClick={() => setTabchange(!tabChange)}><i className="fal fa-chart-bar" />
                {t('account.tabs.1.title')}</button>
              <button className="filter-btn" data-show=".updateprofile" onClick={() => setTabchange(!tabChange)}><i className="fal fa-user" />
                {t('account.tabs.2.title')}
              </button>
              <button className="filter-btn" data-show=".accountdtls" onClick={() => setTabchange(!tabChange)}><i className="fal fa-shield" />
                {t('account.tabs.3.title')}
              </button>
              <Link to="/account/orders" className="filter-btn" data-show=".dashboard"><i className="fal fa-shopping-basket" />
                {t('account.tabs.4.title')}</Link>
              <Link to="/wishlist" className="filter-btn"><i className="fal fa-shopping-basket" />
                {t('account.tabs.5.title')}</Link>
              <Link to="/account/address" className="filter-btn" data-show=".address"><i className="fal fa-map-marker-alt" />
                {t('account.tabs.6.title')}
              </Link>
              <button onClick={logoutUser} className="filter-btn"><i className="fal fa-long-arrow-left" />
                {t('account.tabs.7.title')}</button>
            </div>
            <div className="account-main-area">
              <UserOverview logout={logoutUser} tabChange={tabChange} />
              <PasswordChange />
              {/* <BillingAddress /> */}
              <UpdateProfile />
              <div className="offer-ad mt--40"><img src={process.env.REACT_APP_URL + "/assets/images/banner/45Offer.jpg"} alt="ads" /></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
