import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderSearchBox({ setSearchText }) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const searchHandler = () => {
    setSearchText(search)
  }
  return (
    <div className="d-flex search-widget my-2">
      <div className="input-div">
        <input
          name="search"
          placeholder={t('account.tabs.4.orders_history.search.search_placeholder')}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <button type="submit" className="search-btn" onClick={searchHandler}>
      {t('account.tabs.4.orders_history.search.button_search')} <i className="fal fa-search"></i>
      </button>
    </div>
  )
}
