import React from 'react';
import { useDispatch } from "react-redux";
import { GetPrice } from '../Helpers';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { Product } from '../services';
const CartProduct = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const removeProductCart = (data) => {
        swal({
            title: t('swal.cart_remove.title'),
            text: t('swal.cart_remove.text'),
            icon: "warning",
            buttons: [t('swal.cart_remove.cancelButtonText'), t('swal.cart_remove.confirmButtonText')],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch({ type: "REMOVE", payload: data });
                }

            });
    }

    const cartHandler = async (props) => {
        let ProductStock = await Product.getProductStockCheck(props.id, props.quantity);
        if (ProductStock) {
            return;
        }
    }

    return (
        <>
            <tr>
                <td className="d-flex justify-content-center justify-md-content-start"><div className="product-thumb" style={{ width: 100 }}><img className="lazyload" data-src={props.image} alt="product-thumb" /></div></td>
                <td>
                    <div className="product-title-area">
                        <span className="pretitle">{props.category} / {props.brand}</span>
                        <h4 className="product-title">{props.title}</h4>
                        {(props.variation && props.variation != "") ?
                            <span className="pretitle">{props.variation.variation_1.name} / {props.variation.variation_2.name}</span>
                            :
                            <></>
                        }
                    </div>
                </td>
                <td><span className="product-price">{GetPrice(props.price)}</span></td>
                <td>
                    <div className="cart-edit">
                        <div className="quantity-edit">
                            <button className="button" onClick={() => {
                                {
                                    cartHandler(props); if (props.quantity > 1) {
                                        dispatch({ type: "DECREASE", payload: props });
                                    } else {
                                        dispatch({ type: "REMOVE", payload: props });
                                    }
                                }
                            }}
                            ><i className="fal fa-minus minus"></i></button>
                            <input type="text" className="input" value={props.quantity ? props.quantity : ""} />
                            <button className="button plus" onClick={() => { cartHandler(props); dispatch({ type: "INCREASE", payload: props }) }}>+<i className="fal fa-plus plus"></i></button>
                        </div>
                    </div>
                </td>
                <td className="last-td">
                    <button
                        className="remove-btn"
                        onClick={() => { removeProductCart(props) }}>{t('cart.remove')}</button>
                </td>
            </tr>
        </>
    )
}

export default CartProduct;