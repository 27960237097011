import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SocialList from "../General/SocialList";
import { GetPrice } from "../Helpers";
import { useTranslation } from "react-i18next";
import { Product } from "../services";

const ProductDetailsPopup = (props) => {
  const variations = props.data.variation ?? {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    setQuantity(1);
  }, [props]);
  var fno = "";
  var sno = "";
  var extraClass = "";

  const [productPrice, setProductPrice] = useState(0);
  const [product_price_main, setproduct_price_main] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [selectVariation, setselectVariation] = useState({});
  const [variationDetails, setVariationDetails] = useState({});
  const [variation, setVariation] = useState({
    variation_1: {},
    variation_2: {},
  });

  useEffect(() => {
    if (Object.keys(variations).length) {
      setVariationDetails(Object.values(variations[6])[0]);
      setProductPrice(props.data.product_price);
      setproduct_price_main(props.data.product_price);
    }
  }, [props.data]);

  useEffect(() => {
    setDisabled(false);
    setselectVariation({});
    let variation_uid_check = "";
    if (variation.variation_1.option_uid) {
      variation_uid_check += variation.variation_1.option_uid;
    }
    if (variation.variation_2.option_uid) {
      variation_uid_check += "," + variation.variation_2.option_uid;
    }
    for (let i = 0; i < Object.keys(variationDetails).length; i++) {
      if (Object.keys(variationDetails)[i] == variation_uid_check) {
        setDisabled(true);
        setselectVariation(Object.values(variationDetails)[i]);
      }
    }
    if (variation_uid_check == "") {
      setDisabled(true);
    }
  }, [variation]);

  useEffect(() => {
    if (Object.keys(selectVariation).length > 0) {
      var varition_price =
        parseInt(product_price_main) *
        parseInt(selectVariation.variation_sku_code.slice(-1));
      setProductPrice(varition_price);
    }
  }, [selectVariation]);

  const cartHandler = async (item = {}, type = "") => {
    let ProductStock = await Product.getProductStockCheck(item.product_uid, item.quantity);
    if (type === "quantity") {
      return;
    }
    if (ProductStock) {
      dispatch({
        type: "ADD",
        payload: item,
      })
    }
  }

  return (
    <>
      <div className="product-details-popup-wrapper">
        <div className="rts-product-details-section rts-product-details-section2 product-details-popup-section">
          <div className="product-details-popup">
            <button className="product-details-close-btn">
              <i className="fal fa-times"></i>
            </button>
            <div className="details-product-area">
              <div className="product-thumb-area">
                <div className="cursor"></div>
                {Object.keys(selectVariation).length > 0 && (
                  <div
                    className={`thumb-wrapper ${fno} filterd-items ${extraClass}`}
                  >
                    <div
                      className="product-thumb zoom"
                      onmousemove="zoom(event)"
                      style={{
                        backgroundImage:
                          "url('" + selectVariation.product_image + "')",
                        height: "350px",
                      }}
                    >
                      <img
                        src={selectVariation.product_image}
                        alt="product-thumb"
                      />
                    </div>
                  </div>
                )}

                {Object.values(
                  Object.assign({}, props.data.product_images)
                ).map((img, index) => {
                  if (index == 0) {
                    fno = "one";
                    extraClass =
                      Object.keys(selectVariation).length > 0
                        ? "hide"
                        : "figure";
                  } else if (index == 1) {
                    fno = "two";
                    extraClass = "hide";
                  } else if (index == 2) {
                    fno = "three";
                    extraClass = "hide";
                  }
                  return (
                    <div
                      className={`thumb-wrapper ${fno} filterd-items ${extraClass}`}
                    >
                      <div
                        className="product-thumb zoom"
                        onmousemove="zoom(event)"
                        style={{
                          backgroundImage: "url('" + img + "')",
                          height: "350px",
                        }}
                      >
                        <img src={img} alt="product-thumb" />
                      </div>
                    </div>
                  );
                })}

                <div className="product-thumb-filter-group">
                  {Object.keys(selectVariation).length > 0 && (
                    <div
                      className="thumb-filter filter-btn active"
                      data-show={`.variation`}
                    >
                      <img
                        src={selectVariation.product_image}
                        alt="product-thumb-filter"
                      />
                    </div>
                  )}

                  {Object.values(
                    Object.assign({}, props.data.product_images)
                  ).map((img, index) => {
                    if (index == 0) {
                      sno = "one";
                    } else if (index == 1) {
                      sno = "two";
                    } else if (index == 2) {
                      sno = "three";
                    }
                    return (
                      <div
                        className="thumb-filter filter-btn active"
                        data-show={`.${sno}`}
                        key={index}
                      >
                        <img src={img} alt="product-thumb-filter" />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="contents">
                <div className="product-status">
                  <span className="product-catagory">
                    {props.data.category}
                  </span>
                  <div className="rating-stars-group">
                    <div className="rating-star">
                      <i className="fas fa-star"></i>
                    </div>
                    <div className="rating-star">
                      <i className="fas fa-star"></i>
                    </div>
                    <div className="rating-star">
                      <i className="fas fa-star-half-alt"></i>
                    </div>
                    <span>{t("product_popup.reviews")}</span>
                  </div>
                </div>
                <h2 className="product-title">
                  {Object.keys(selectVariation).length > 0
                    ? selectVariation.product_name
                    : props.data.product_name}
                  {parseInt(props.data.product_quantity) ==
                    parseInt(props.data.product_stock_out) ? (
                    <span className="stock">
                      {t("product_popup.out_of_stock")}
                    </span>
                  ) : (
                    <span className="stock">{t("product_popup.in_stock")}</span>
                  )}
                </h2>
                <span className="product-price">
                  <span className="old-price">
                    {GetPrice(props.data.product_ref_price)}
                  </span>{" "}
                  {Object.keys(selectVariation).length > 0
                    ? GetPrice(
                      parseInt(props.data.product_price) *
                      parseInt(selectVariation.variation_sku_code.slice(-1))
                    )
                    : GetPrice(props.data.product_price)}
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      Object.keys(selectVariation).length > 0
                        ? selectVariation.product_description
                        : props.data.product_description,
                  }}
                />

                {variations.variation_option1_name && (
                  <div className="action-item3">
                    <div className="action-top">
                      <span className="action-title">
                        {"Size"} :{" "}
                        <strong> {variation.variation_1.name}</strong>
                      </span>
                    </div>
                    {variations.variation_option1_name.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className="color-item2"
                          onClick={() => {
                            setVariation({
                              ...variation,
                              variation_1: {
                                uid: 1,
                                option_uid: option.uid,
                                name: option.name,
                              },
                            });
                          }}
                        >
                          <div
                            className={`size ${variation.variation_1.option_uid === option.uid &&
                              "active"
                              }`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title={option.name}
                          >
                            {option.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {variations.variation_option2_name && (
                  <div className="action-item3">
                    <div className="action-top">
                      <span className="action-title">
                        {"Color"} :{" "}
                        <strong> {variation.variation_2.name}</strong>
                      </span>
                    </div>
                    {variations.variation_option2_name.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className="color-item2"
                          onClick={() => {
                            setVariation({
                              ...variation,
                              variation_2: {
                                uid: 2,
                                option_uid: option.uid,
                                name: option.name,
                              },
                            });
                          }}
                        >
                          <div
                            className={`size ${variation.variation_2.option_uid === option.uid &&
                              "active"
                              }`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title={option.name}
                            aria-label={option.name}
                          >
                            {option.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="product-bottom-action">
                  <div className="cart-edit">
                    <div className="quantity-edit action-item">
                      <button
                        className="button"
                        onClick={() => {
                          cartHandler({}, "quantity"); quantity > 1
                            ? setQuantity(quantity - 1)
                            : setQuantity(1)
                        }
                        }
                      >
                        <i className="fal fa-minus minus"></i>
                      </button>
                      <input type="text" className="input" value={quantity} />
                      <button
                        className="button plus"
                        onClick={() => { cartHandler({}, "quantity"); setQuantity(quantity + 1) }}
                      >
                        +<i className="fal fa-plus plus"></i>
                      </button>
                    </div>
                  </div>

                  <div>
                    <Link
                      className="addto-cart-btn action-item c-model-add-to-cart"
                      disabled={
                        (parseInt(props.data.product_quantity) === parseInt(props.data.product_stock_out) || !disabled)
                          ? "disabled"
                          : ""
                      }
                      onClick={() => cartHandler(
                        {
                          id: props.data.product_uid,
                          slug: props.data.slug,
                          title: props.data.product_name,
                          image: props.data.product_image,
                          quantity:
                            Object.keys(selectVariation).length > 0
                              ? parseInt(quantity) *
                              parseInt(
                                selectVariation.variation_sku_code.slice(-1)
                              )
                              : parseInt(quantity),
                          price:
                            Object.keys(selectVariation).length > 0
                              ? parseInt(props.data.product_price) *
                              parseInt(
                                selectVariation.variation_sku_code.slice(-1)
                              )
                              : parseInt(props.data.product_price),
                          brand: props.data.brand,
                          category: props.data.category,
                          variation: variation,
                        }
                      )}
                    >
                      <i className="rt-basket-shopping"></i>
                      {t("add_to_cart")}
                    </Link>
                  </div>

                  <div>
                    {parseInt(props.data.product_quantity) !==
                      parseInt(props.data.product_stock_out) && (
                        <Link
                          className="wishlist-btn action-item c-model-add-to-cart"
                          onClick={() =>
                            dispatch({
                              type: "WISHLIST_ADD",
                              payload: {
                                id: props.data.product_uid,
                                slug: props.data.slug,
                                title: props.data.product_name,
                                image: props.data.product_image,
                                quantity:
                                  Object.keys(selectVariation).length > 0
                                    ? parseInt(quantity) *
                                    parseInt(
                                      selectVariation.variation_sku_code.slice(
                                        -1
                                      )
                                    )
                                    : parseInt(quantity),
                                price:
                                  Object.keys(selectVariation).length > 0
                                    ? parseInt(props.data.product_price) *
                                    parseInt(
                                      selectVariation.variation_sku_code.slice(
                                        -1
                                      )
                                    )
                                    : parseInt(props.data.product_price),
                                category: props.data.category,
                                brand: props.data.brand,
                                quantity: parseInt(props.data.product_quantity),
                                stock_out: props.data.product_stock_out,
                                variation: variation,
                              },
                            })
                          }
                        >
                          <i className="rt-heart"></i>
                        </Link>
                      )}
                  </div>
                </div>
                <div className="product-uniques">
                  <span className="sku product-unipue">
                    <span>{t("product_popup.sku")} </span>{" "}
                    {Object.keys(selectVariation).length > 0
                      ? selectVariation.variation_sku_code
                      : props.data.product_sku}
                  </span>
                  <span className="catagorys product-unipue">
                    <span>{t("product_popup.categories")} </span>{" "}
                    {props.data.category}
                  </span>
                  <span className="tags product-unipue">
                    <span>{t("product_popup.brand")} </span> {props.data.brand}
                  </span>
                </div>
                <div className="share-social d-flex align-items-center">
                  <span>{t("product_popup.share")}</span>
                  <SocialList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsPopup;
