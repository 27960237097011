import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Toast } from '../../components'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CompanyConfig from '../../components/CompanyConfig';
import { Customer } from '../CustomerConfig';

import { Product } from '../../components/services';


const ReviewForm = ({ rating }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { company_token } = CompanyConfig();
    const { CustomerData } = Customer();
    const navigate = useNavigate();
    const { state } = useLocation();


    const [image, setImage] = useState(`${process.env.REACT_APP_URL}/assets/images/placeholder/placeholder-image.png`);

    const handleChange = (e) => {
        const file = e.target.files[0];
        setImage(URL.createObjectURL(file));
    }

    const review = useFormik({
        enableReinitialize: true,
        initialValues: {
            rating: 0,
            message: '',
            review_image: null,
        },
        validationSchema: Yup.object({
            rating: Yup.number().min(1, t('product_details.tabs.3.reveiw_form.rating.validation.min')).max(5, t('product_details.tabs.3.reveiw_form.rating.validation.max')).required(t('product_details.tabs.3.reveiw_form.rating.validation.required')),
            message:
                Yup.string()
                    .required(t('product_details.tabs.3.reveiw_form.message.validation.required')),
            review_image: Yup.mixed()
                .required(t('product_details.tabs.3.reveiw_form.image.validation.required'))
        }),
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            values.access_token = CustomerData.access_token;
            values.company_token = company_token;
            values.order_uid = localStorage.getItem("rating_order_id");
            values.product_uid = state.product_uid;

            Product.getProductReview(values).then((res) => {
                if (res.success === 1) {
                    setTimeout(() => {
                        resetForm();
                        Toast(res.message, "success");
                        localStorage.removeItem("rating_order_id");
                        navigate("/home");
                    }, 1000)
                }
                setLoading(false);
            })
        },
    });

    return (
        <div className={`full-details dls-three filterd-items ${(rating) ? '' : 'hide'}`}>
            <form onSubmit={review.handleSubmit}>
                <div className="full-details-inner">
                    <p>{t('product_details.tabs.3.reveiw_form.there_are_no_reveiws_yet')}</p>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mr-10">
                            <div className="reveiw-form">
                                <h2 className="section-title">
                                    {t('product_details.tabs.3.reveiw_form.be_the_first_to_reveiw')}
                                    <strong>
                                        {" "}
                                        <Link to="/">
                                            {t('product_details.tabs.3.reveiw_form.wide_cotton_tunic_dress')}
                                        </Link>
                                    </strong>
                                </h2>
                                <h4 className="sect-title">
                                    {t('product_details.tabs.3.reveiw_form.your_email_address_will_not_be_published')}
                                </h4>
                                <div className="reveiw-form-main mb-10">
                                    <div className="contact-form">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="input-box text-input mb-20">
                                                    <textarea
                                                        name="message"
                                                        id="validationDefault01"
                                                        cols={30}
                                                        rows={10}
                                                        placeholder={t('product_details.tabs.3.reveiw_form.message.placeholder')}
                                                        onChange={review.handleChange}
                                                        onBlur={review.handleBlur}
                                                        value={review.values.message}
                                                    />
                                                    {review.touched.message && review.errors.message ? (
                                                        <div className='text-danger'>{review.errors.message}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <input
                                                            name='review_image'
                                                            className="form-control"
                                                            type="file"
                                                            onChange={(e) => { review.handleChange(e); review.setFieldValue('review_image', e.currentTarget.files[0]); handleChange(e); }}
                                                        />
                                                        {review.touched.review_image && review.errors.review_image ? (
                                                            <div className='text-danger'>{review.errors.review_image}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="profile-dp-area">
                                                        <div className="profile-dp">
                                                            {image && <img src={image} alt="profile-dp" />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="rating mb-2">
                                                        <p>{t('product_details.tabs.3.reveiw_form.your_rating')}</p>
                                                        <div className="rating-icon">
                                                            <span className="one">
                                                                {" "}
                                                                <label htmlFor='rating_index_1'
                                                                    className={`rating-icon-star ${(review.values.rating === 1 || review.values.rating >= 1) && 'active'}`}>
                                                                    {" "}
                                                                    <i className="fal fa-star" />
                                                                </label>
                                                                <input
                                                                    id='rating_index_1'
                                                                    type="radio"
                                                                    name="rating"
                                                                    value={1}
                                                                    onChange={review.handleChange}
                                                                    checked={review.values.rating === 1}
                                                                    className="c-rating-input"
                                                                />
                                                            </span>

                                                            <span className="two">
                                                                <label
                                                                    htmlFor='rating_index_2'
                                                                    className={`rating-icon-star ${(review.values.rating === 2 || review.values.rating >= 2) && 'active'}`}
                                                                >
                                                                    {" "}
                                                                    <i className="fal fa-star" />
                                                                </label>
                                                                <input
                                                                    id='rating_index_2'
                                                                    type="radio"
                                                                    name="rating"
                                                                    value={2}
                                                                    onChange={review.handleChange}
                                                                    checked={review.values.rating === 2}
                                                                    className="c-rating-input"
                                                                />
                                                            </span>
                                                            <span className="three">
                                                                <label htmlFor='rating_index_3'
                                                                    className={`rating-icon-star ${(review.values.rating === 3 || review.values.rating >= 3) && 'active'}`}
                                                                >
                                                                    {" "}
                                                                    <i className="fal fa-star" />
                                                                </label>
                                                                <input
                                                                    id='rating_index_3'
                                                                    type="radio"
                                                                    name="rating"
                                                                    value={3}
                                                                    onChange={review.handleChange}
                                                                    checked={review.values.rating === 3}
                                                                    className="c-rating-input"
                                                                />
                                                            </span>
                                                            <span className="four">
                                                                <label htmlFor='rating_index_4'
                                                                    className={`rating-icon-star ${(review.values.rating === 4 || review.values.rating >= 4) && 'active'}`}
                                                                >
                                                                    {" "}
                                                                    <i className="fal fa-star" />
                                                                </label>
                                                                <input
                                                                    id='rating_index_4'
                                                                    type="radio"
                                                                    name="rating"
                                                                    value={4}
                                                                    onChange={review.handleChange}
                                                                    checked={review.values.rating === 4}
                                                                    className="c-rating-input"
                                                                />
                                                            </span>
                                                            <span className="five">
                                                                <label htmlFor='rating_index_5'
                                                                    className={`rating-icon-star ${(review.values.rating === 5 || review.values.rating >= 5) && 'active'}`}
                                                                >
                                                                    {" "}
                                                                    <i className="fal fa-star" />
                                                                </label>
                                                                <input
                                                                    id='rating_index_5'
                                                                    type="radio"
                                                                    name="rating" value={5}
                                                                    onChange={review.handleChange}
                                                                    checked={review.values.rating === 5}
                                                                    className="c-rating-input"
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2 mb-3'>
                                                        {review.touched.rating && review.errors.rating ? (
                                                            <div className='text-danger'>{review.errors.rating}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-15">
                                                    <button className="form-btn form-btn4" disabled={loading ? true : false}>
                                                        {loading ? t('product_details.tabs.3.reveiw_form.button_loading') : t('product_details.tabs.3.reveiw_form.button_submit')}
                                                        <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ReviewForm