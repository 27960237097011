import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { GetPrice } from "../Helpers";
import { useTranslation } from "react-i18next";
import { Product } from "../services";

export default function ItemCard(props) {
  const variations = props.item.variation ?? {};
  const [productPrice, setProductPrice] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [product_price_main, setproduct_price_main] = useState(0);
  const [selectVariation, setselectVariation] = useState({});
  const [variationDetails, setVariationDetails] = useState({});
  const [variation, setVariation] = useState({
    variation_1: {},
    variation_2: {},
  });

  const { t } = useTranslation();
  const { item } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(variations).length) {
      setVariationDetails(Object.values(variations[6])[0]);
      setProductPrice(item.product_price);
      setproduct_price_main(item.product_price);
    }
  }, [props]);

  useEffect(() => {
    setDisabled(false);
    setselectVariation({});
    let variation_uid_check = "";
    if (variation.variation_1.option_uid) {
      variation_uid_check += variation.variation_1.option_uid;
    }
    if (variation.variation_2.option_uid) {
      variation_uid_check += "," + variation.variation_2.option_uid;
    }
    for (let i = 0; i < Object.keys(variationDetails).length; i++) {
      if (Object.keys(variationDetails)[i] == variation_uid_check) {
        setDisabled(true);
        setselectVariation({
          ...selectVariation,
          ...Object.values(variationDetails)[i],
        });
      }
    }
    if (variation_uid_check == "") {
      setDisabled(true);
    }
  }, [variation]);

  useEffect(() => {
    if (Object.keys(selectVariation).length > 0) {
      var varition_price =
        product_price_main * selectVariation.variation_sku_code.slice(-1);
      setProductPrice(varition_price);
    }
  }, [selectVariation]);

  const cartHandler = async (item) => {
    let ProductStock =
      await Product.getProductStockCheck(item.product_uid, item.quantity);
    if (ProductStock) {
      dispatch({
        type: "ADD",
        payload: item,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="product-item product-item2 element-item3 c-product-inner-img">
        <div>
          <div>
            {Object.keys(selectVariation).length > 0 ? (
              <img
                data-src={selectVariation.product_image}
                alt="product-img"
                className="product-images-slider lazyload"
              />
            ) : (
              <img
                data-src={item.product_image}
                alt="product-img"
                className="product-images-slider lazyload"
              />
            )}
          </div>
          {variations.variation_option1_name && (
            <div className="action-item3 py-0 my-2">
              {variations.variation_option1_name.map((option, index) => {
                return (
                  <div
                    className="color-item2"
                    key={index}
                    onClick={() =>
                      setVariation({
                        ...variation,
                        variation_1: {
                          uid: "1",
                          option_uid: option.uid,
                          name: option.name,
                        },
                      })
                    }
                  >
                    <div
                      className={`size ${variation.variation_1.option_uid === option.uid &&
                        "active"
                        }`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title={option.name}
                    >
                      {option.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {variations.variation_option2_name && (
            <div className="action-item3 py-0 my-2">
              {variations.variation_option2_name.map((option, index) => {
                return (
                  <div
                    className="color-item2"
                    key={index}
                    onClick={() =>
                      setVariation({
                        ...variation,
                        variation_2: {
                          uid: "2",
                          option_uid: option.uid,
                          name: option.name,
                        },
                      })
                    }
                  >
                    <div
                      className={`size ${variation.variation_2.option_uid === option.uid &&
                        "active"
                        }`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title={option.name}
                    >
                      {option.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* </Link> */}
        </div>
        <div className="bottom-content">
          <Link
            to={process.env.PUBLIC_URL + "/product/" + item.slug}
            className="product-name"
          >
            {item.product_name}
          </Link>
          <div className="action-wrap">
            <span className="product-price">
              {Object.keys(selectVariation).length > 0
                ? GetPrice(
                  item.product_price *
                  selectVariation.variation_sku_code.slice(-1)
                )
                : GetPrice(item.product_price)}
            </span>
            <Link
              className="addto-cart"
              disabled={
                parseInt(item.product_quantity) ===
                  parseInt(item.product_stock_out)
                  ? "disabled"
                  : ""
              }
              onClick={() => cartHandler({
                id: item.product_uid,
                slug: item.slug,
                title: item.product_name,
                image: item.product_image,
                quantity: 1,
                price: item.product_price,
                category: item.Category,
                brand: item.Brand,
                variation: variation,
              })}
            >
              <i className="fal fa-shopping-cart" /> {t("add_to_cart")}
            </Link>
          </div>
        </div>
        {item.product_quantity == item.product_stock_out ? (
          <div className="product-features">
            <div className="discount-tag product-tag c-out-of-stock-inner">
              {t("out_of_stock")}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="product-actions">
          {parseInt(item.product_quantity) !==
            parseInt(item.product_stock_out) && (
              <Link
                className="product-action"
                onClick={() =>
                  dispatch({
                    type: "WISHLIST_ADD",
                    payload: {
                      id: item.product_uid,
                      slug: item.slug,
                      title: item.product_name,
                      image: item.product_image,
                      quantity: 1,
                      price: item.product_price,
                      category: item.Category,
                      brand: item.Brand,
                      quantity: item.product_quantity,
                      stock_out: item.product_stock_out,
                      variation: variation,
                    },
                  })
                }
              >
                <i className="fal fa-heart" />
              </Link>
            )}

          <button
            className="product-action product-details-popup-btn"
            onClick={() =>
              props.setModel({
                ...item,
                brand: item.Brand,
                category: item.Category,
              })
            }
          >
            <i className="fal fa-eye" />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
