import React, { useEffect, useState } from "react";
import { BreadCrumb, BackToPage, Preload, Toast } from "../components";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Product } from "../components/services";

import { Customer } from "../components/CustomerConfig";

import { useFormik } from "formik";
import * as Yup from "yup";

import { GetCurrency } from "../components/Helpers";
import { useTranslation } from 'react-i18next';

export default function OrderView() {
  const { t } = useTranslation();
  const Currency = GetCurrency();
  const [loading, setLoading] = useState(false);
  const { CustomerData } = Customer();
  const navigate = useNavigate();
  const [order, setOrder] = useState([]);
  const [reasonList, setReasonLisrt] = useState({});

  const { orderId } = useParams();

  useEffect(() => {
    let isCancelled = false;
    const fetchOrder = async () => {
      setLoading(true);
      let parameter = {
        "user_uid": CustomerData.user_uid,
        "access_token": CustomerData.access_token,
        "order_uid": orderId
      }
      await Product.getOrder(parameter).then((res) => {
        if (res) {
          setOrder(res.arrData);
        }
      })
      await Product.getCancelReason().then((res) => {
        if (res) {
          setReasonLisrt(res.arrData);
        }
      })
      setLoading(false);
    }
    if (!isCancelled) {
      fetchOrder();
    }
    return () => {
      isCancelled = true;
    }
  }, [])


  const order_cancel = useFormik({
    initialValues: {
      reason: "",
      return_reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required(t('account.tabs.4.cancel_order.form.reason.validation.required')),
      return_reason: Yup.string().required(t('account.tabs.4.cancel_order.form.return_reason.validation.required')),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.order_uid = orderId;
      values.user_uid = CustomerData.user_uid;
      values.access_token = CustomerData.access_token;
      setTimeout(() => {
        Product.getCancelOrder(values).then((res) => {
          resetForm();
          Toast(t('account.tabs.4.cancel_order.order') + res.message);
          navigate(-1);
        });
        setLoading(false);
      }, 2000);
    },
  });


  return (
    <React.Fragment>
      <BreadCrumb title={t('account.tabs.4.cancel_order.main_title')} />
      <div className="rts-shop-section order_list">
        <div className="container w-1220">
          {
            loading
              ? <Preload type="feed" />
              : <div>
                <div className="row gutter-lg mb-4">
                  <div className="col-lg-12 col-md-12 c_desktop">
                    <h2>{t('account.tabs.4.cancel_order.order_details')}</h2>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <td colSpan={2} className="align_left">
                          {t('account.tabs.4.cancel_order.order_details')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align_left">
                            <b>{t('account.tabs.4.cancel_order.order_id')}</b>{t('account.tabs.4.cancel_order.pos_001')}{order.unique_order_number}<br />
                            <b>{t('account.tabs.4.cancel_order.date')}</b> {order.order_date ? moment(order.order_date).format('DD MMM YYYY') : ""}
                          </td>
                          <td className="align_left">
                            <b>{t('account.tabs.4.cancel_order.payment_method')}{order.payment_method} </b>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <th className="align_left">{t('account.tabs.4.cancel_order.payment_address')}</th>
                          <th className="align_left">{t('account.tabs.4.cancel_order.shipping_address')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="align_left">
                            <div
                              dangerouslySetInnerHTML={{ __html: order.billing_address }}
                            />
                          </td>
                          <td className="align_left">
                            <div
                              dangerouslySetInnerHTML={{ __html: order.shipping_address }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="shop-table cart-table mt-2 table-bordered">
                      <thead>
                        <tr>
                          <th width="25%" className="align_left">
                          {t('account.tabs.4.cancel_order.name')}
                          </th>
                          <th width="25%">
                          {t('account.tabs.4.cancel_order.qty')}
                          </th>
                          <th width="25%">
                          {t('account.tabs.4.cancel_order.price')}
                          </th>
                          <th width="25%">
                          {t('account.tabs.4.cancel_order.total')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (order.order_items)
                            ?
                            order.order_items.map(({
                              product_name,
                              oder_quantity,
                              product_price,
                              oder_price,
                            }, index) => {
                              return (
                                <tr key={index}>
                                  <td className="align_left">
                                    <b>{product_name}</b>
                                  </td>
                                  <td>{oder_quantity}</td>
                                  <td>{Currency + ' ' + oder_price}</td>
                                  <td>{Currency + ' ' + parseInt(oder_price) * parseInt(oder_quantity)}</td>
                                </tr>
                              )
                            })
                            : ""
                        }
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.cancel_order.coupon_amount')}</td>
                          <td className="align_center textbold">
                            - {Currency + ' ' + order.voucher_amount}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.cancel_order.shipping_amount')}</td>
                          <td className="align_center textbold">
                            + {Currency + ' ' + order.shipping_amount}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.cancel_order.sub_total')}</td>
                          <td className="align_center textbold">{Currency + ' ' + order.total_amount}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} />
                          <td className="align_center textbold">{t('account.tabs.4.cancel_order.main_total')}</td>
                          <td className="align_center textbold">{Currency + ' ' + order.total_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-md-12 c_mobile"></div>
                </div>
                <div className="card p-5 mb-5 shadow-sm">
                  <h2 className="mb-3">{t('account.tabs.4.cancel_order.cancel_order')}</h2>
                  {
                    order.dispute != 0
                      ?
                      <p>{t('account.tabs.4.cancel_order.already_requested_for_cancel')}</p>
                      :
                      <form onSubmit={order_cancel.handleSubmit}>
                        <div className="form mb-3">
                          <select
                            name="return_reason"
                            className="form-control"
                            onChange={order_cancel.handleChange}
                            onBlur={order_cancel.handleBlur}
                            value={order_cancel.values.return_reason}
                          >
                            {
                              reasonList.length > 0
                                ?
                                reasonList.map(({ uid, title }, index) => {
                                  return (
                                    <option value={uid} key={index}>{title}</option>
                                  )
                                })
                                : ""
                            }
                          </select>
                          {order_cancel.touched.return_reason && order_cancel.errors.return_reason ? (
                            <div className="text-danger">
                              {order_cancel.errors.return_reason}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            id="return_order"
                            cols="80"
                            rows="4"
                            name="reason"
                            placeholder={t('account.tabs.4.cancel_order.form.reason.placeholder')}
                            onChange={order_cancel.handleChange}
                            onBlur={order_cancel.handleBlur}
                            value={order_cancel.values.reason}
                          ></textarea>
                          {order_cancel.touched.reason && order_cancel.errors.reason ? (
                            <div className="text-danger">
                              {order_cancel.errors.reason}
                            </div>
                          ) : null}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-2"
                          disabled={loading ? true : false}
                        >
                          {!loading ? t('account.tabs.4.cancel_order.form.button_submit') : ""}
                          <i className={`fal ${loading ? "fa-spinner fa-spin" : ""}`} />
                        </button>
                      </form>
                  }
                </div>
              </div>
          }
          <BackToPage title={t('account.tabs.4.cancel_order.go_to_order_list')} />
        </div>
      </div>
    </React.Fragment>
  )
}
