import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Search() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ? searchParams.get("search") : "";
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    setSearchText(search);
  }, [searchParams])

  const searchHandler = () => {
    if (searchText.length > 2 || searchText.length < 1) {
      navigate({
        pathname: '/blogs',
        search: `?search=${searchText}`,
      });
    }
  }

  return (
    <div className="widget widget-search mb-40">
      <div className="widget-title-box pb-25 mb-30">
        <h4 className="widget-sub-title2 fs-20">{t('blogs.search.search_here')}</h4>
      </div>
      <div className="subscribe-form mb-10">
        <input type="text" placeholder={t('blogs.search.search_your_keyword')} onChange={(e) => setSearchText(e.target.value)} value={searchText ? searchText : ""} />
        <button className="widget-btn" onClick={searchHandler}>
          <i className="fal fa-search" />
        </button>
      </div>
    </div>
  )
}
