import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useTranslation } from 'react-i18next';
export default function CheckoutForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { setting } = useSelector((state) => state);

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t('stripe.form.succeeded'));
          break;
        case "processing":
          setMessage(t('stripe.form.processing'));
          break;
        case "requires_payment_method":
          setMessage(t('stripe.form.requires_payment_method'));
          break;
        default:
          setMessage(t('stripe.form.something_went_wrong'));
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_URL}/stripe-response`,
      },
    });



    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(t('stripe.form.an_unexpected_error_occurred'));
    }

    setIsLoading(false);
  };

  const GetPrice = (price = 0.00) => {
    if (setting != '') {
      if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
        var currency = setting.currency_setting.symbol;
        var display = setting.currency_setting.display;
        if (display == 'before') {
          return currency + ' ' + price;
        } else {
          return price + ' ' + currency;
        }
      }
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="order-detaiil">
        <div className="order-header">
          <Link className="btn-1" to="/cancel" state={{ cancelStatusMsg: t('stripe.form.you_order_is_cancelled') }} style={{ color: '#777777' }}>
            <i className="fal fa-long-arrow-left" /> {t('stripe.form.back')}
          </Link>
        </div>
      </div>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : t('stripe.form.pay_now') + GetPrice(props.amount)}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}