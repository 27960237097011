import React from 'react'
import { Link } from 'react-router-dom'

export default function UserIcon() {
  return (
    <Link to="/login" className="account theme-custom-link">
      <i className="rt-user-2" />
    </Link>
  )
}
