import React from 'react';
import { Link } from 'react-router-dom';

function HomeBrands(props) {
    return (
        <>
            <div className="rts-brands-section1 brand-bg3 lazyload" data-bg={process.env.REACT_APP_ASSETS_URL + "/assets/images/brands/sponsor-overley-1.webp"}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="slider-div">
                                <div className="swiper rts-brandSlide1">
                                    <div className="swiper-wrapper">
                                        {
                                            props.data.map(({ brand_image, brand_name, brand_slug }, index) => {
                                                return (
                                                    <div className="swiper-slide" key={index}>
                                                        <Link className="brand-front" to={"brands/" + brand_slug}>
                                                            <img
                                                                className="lazyload"
                                                                data-src={brand_image}
                                                                alt={brand_name}
                                                                width={'121px'}
                                                                height={'29px'}
                                                            />
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeBrands;