import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { GetPrice } from "../Helpers";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Product } from "../services";

export default function Item(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [productPrice, setProductPrice] = useState(0);
  const [product_price_main, setproduct_price_main] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [variations, setVariations] = useState({});
  const [selectVariation, setselectVariation] = useState({});
  const [variationDetails, setVariationDetails] = useState({});
  const [variation, setVariation] = useState({
    variation_1: {},
    variation_2: {},
  });

  useEffect(() => {
    setVariations(props.variation);
    if (Object.keys(variations).length) {
      setVariationDetails(Object.values(variations[6])[0]);
      setProductPrice(props.product_price);
      setproduct_price_main(props.product_price);
    }
  }, [props]);

  useEffect(() => {
    setDisabled(false);
    setselectVariation({});
    let variation_uid_check = "";
    if (variation.variation_1.option_uid) {
      variation_uid_check += variation.variation_1.option_uid;
    }
    if (variation.variation_2.option_uid) {
      variation_uid_check += "," + variation.variation_2.option_uid;
    }
    for (let i = 0; i < Object.keys(variationDetails).length; i++) {
      if (Object.keys(variationDetails)[i] == variation_uid_check) {
        setDisabled(true);
        setselectVariation(Object.values(variationDetails)[i]);
      }
    }
    if (variation_uid_check == "") {
      setDisabled(true);
    }
  }, [variation]);

  useEffect(() => {
    if (Object.keys(selectVariation).length > 0) {
      var varition_price =
        product_price_main * selectVariation.variation_sku_code.slice(-1);
      setProductPrice(varition_price);
    }
  }, [selectVariation]);

  const cartHandler = async (item) => {
    let ProductStock = await Product.getProductStockCheck(props.product_uid, props.quantity);
    if (ProductStock) {
      dispatch({
        type: "ADD",
        payload: item,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="product-item element-item1">
        <div>
          <div>
            {Object.keys(selectVariation).length > 0 ? (
              <img
                data-src={selectVariation.product_image}
                alt={props.product_name}
                className="product-images-slider lazyload"
                width={'300px'}
                height={'400px'}
              />
            ) : (
              <img
                data-src={props.product_image}
                alt={props.product_name}
                className="product-images-slider lazyload"
                width={'300px'}
                height={'400px'}
              />
            )}
          </div>

          {variations.variation_option1_name && (
            <div className="action-item3 py-0 my-2">
              {variations.variation_option1_name.map((option, index) => {
                return (
                  <div
                    className="color-item2"
                    key={index}
                    onClick={() =>
                      setVariation({
                        ...variation,
                        variation_1: {
                          uid: 1,
                          option_uid: option.uid,
                          name: option.name,
                        },
                      })
                    }
                  >
                    <div
                      className={`size ${variation.variation_1.option_uid === option.uid &&
                        "active"
                        }`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title={option.name}
                    >
                      {option.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {variations.variation_option2_name && (
            <div className="action-item3 py-0 my-2">
              {variations.variation_option2_name.map((option, index) => {
                return (
                  <div
                    className="color-item2"
                    key={index}
                    onClick={() =>
                      setVariation({
                        ...variation,
                        variation_2: {
                          uid: 2,
                          option_uid: option.uid,
                          name: option.name,
                        },
                      })
                    }
                  >
                    <div
                      className={`size ${variation.variation_2.option_uid === option.uid &&
                        "active"
                        }`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title={option.name}
                    >
                      {option.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="bottom-content pt-3">
          <Link to={`/product/${props.slug}`} className="product-name">
            {props.product_name}
          </Link>
          <div className="action-wrap">
            <span className="price">
              {Object.keys(selectVariation).length > 0
                ? GetPrice(
                  parseInt(props.product_price) *
                  parseInt(selectVariation.variation_sku_code.slice(-1))
                )
                : GetPrice(props.product_price)}
            </span>
          </div>
        </div>
        <div className="quick-action-button">
          <div className="cta-single cta-plus">
            <i className="rt-plus" />
          </div>
          <div className="cta-single cta-quickview">
            <button
              className="product-details-popup-btn"
              onClick={() => props.setModel(props)}
            >
              <i className="far fa-eye" />
            </button>
          </div>

          {parseInt(props.product_quantity) !==
            parseInt(props.product_stock_out) && (
              <>
                <div className="cta-single cta-wishlist">
                  <Link
                    onClick={() =>
                      dispatch({
                        type: "WISHLIST_ADD",
                        payload: {
                          id: props.product_uid,
                          slug: props.slug,
                          title: props.product_name,
                          image: props.product_image,
                          quantity:
                            Object.keys(selectVariation).length > 0
                              ? parseInt(1) *
                              parseInt(
                                selectVariation.variation_sku_code.slice(-1)
                              )
                              : 1,
                          price:
                            Object.keys(selectVariation).length > 0
                              ? parseInt(props.product_price) *
                              parseInt(
                                selectVariation.variation_sku_code.slice(-1)
                              )
                              : parseInt(props.product_price),
                          category: props.category,
                          brand: props.brand,
                          quantity: parseInt(props.product_quantity),
                          stock_out: props.product_stock_out,
                          variation: variation,
                        },
                      })
                    }
                  >
                    <i className="far fa-heart" />
                  </Link>
                </div>
                <div className="cta-single cta-addtocart">
                  <Link
                    onClick={() => cartHandler({
                      id: props.product_uid,
                      slug: props.slug,
                      title: props.product_name,
                      image: props.product_image,
                      quantity:
                        Object.keys(selectVariation).length > 0
                          ? parseInt(1) *
                          parseInt(
                            selectVariation.variation_sku_code.slice(-1)
                          )
                          : 1,
                      price:
                        Object.keys(selectVariation).length > 0
                          ? parseInt(props.product_price) *
                          parseInt(
                            selectVariation.variation_sku_code.slice(-1)
                          )
                          : parseInt(props.product_price),
                      brand: props.brand,
                      category: props.category,
                      variation: variation,
                    })}
                  >
                    <i className="rt-basket-shopping" />
                  </Link>
                </div>
              </>
            )}
        </div>

        {parseInt(props.product_quantity) ===
          parseInt(props.product_stock_out) && (
            <div className="product-features">
              <div className="discount-tag product-tag c-out-of-stock-inner">
                {t("homepage.product_slider.out_of_product")}
              </div>
            </div>
          )}
      </div>
    </React.Fragment>
  );
}
