import React, { useState, useEffect } from 'react';
import { ProductDetailsPopup, Offer, CategoryItemsList, FeaturedItemsList, Banner, OffersPoster, Deals, HomeBrands, Meta } from '../components'
import { useSelector } from "react-redux";

//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { useTranslation } from 'react-i18next';

import CompanyConfig from '../components/CompanyConfig'
function Home({ title }) {
  const { t } = useTranslation();
  const [model, setModel] = useState({});
  const { company_token } = CompanyConfig();
  const [category, setCategory] = useState([]);
  const [dealsproduct, setDealsProduct] = useState([]);
  const [featureproduct, setFeatureProduct] = useState([]);
  const [topsellproducts, setTopSellProducts] = useState([]);
  const [brand, setBrand] = useState([]);
  const [slider, setSlider] = useState([]);
  const [coupon, setCoupon] = useState({});
  const { ecommerce_setting = null } = useSelector((state) => state.setting);

  useEffect(() => {

    let isCancelled = false;

    const fetchData = async () => {
      try {
        await api
          .post(Apis.FeaturedCategories, {
            company_token: company_token,
            keyword: "",
          })
          .then((res) => {
            if (res.data.success == 1) {
              setCategory(res.data.arrData);
            }
          })
        await api
          .post(Apis.FeaturedBrand, {
            company_token: company_token
          })
          .then((res) => {
            if (res.data.success == 1) {
              setBrand(res.data.arrData);
            }
          })
        await api
          .post(Apis.Slider, {
            company_token: company_token,
          })
          .then((res) => {
            if (res.data.success == 1) {
              setSlider(res.data.arrData);
            }
          })
        await api
          .post(Apis.DealsProduct, {
            company_token: company_token
          })
          .then((res) => {
            if (res.data.success == 1) {
              setDealsProduct(res.data.arrData);
            }
          })
        await api
          .post(Apis.FeatureProduct, {
            company_token: company_token
          })
          .then((res) => {
            if (res.data.success == 1) {
              setFeatureProduct(res.data.arrData);
            }
          })
        await api
          .post(Apis.TopSellProduct, {
            company_token: company_token
          })
          .then((res) => {
            if (res.data.success == 1) {
              setTopSellProducts(res.data.arrData);
            }
          })
        await api
          .post(Apis.TopCouponShow, {
            company_token: company_token
          })
          .then((res) => {
            if (res.data.success == 1) {
              setCoupon(res.data.arrData);
            }
          })
      } catch (error) {
        console.error(error.message);
      }
    };
    if (!isCancelled) {
      fetchData();
    }
    return () => {
      isCancelled = true;
    }
  }, []);



  return (
    <React.Fragment>
      <Meta
        type="home"
        keyword={ecommerce_setting != null ? ecommerce_setting.meta_keyword : ""}
      />
      <Banner category={category} slider={slider} />
      {/*================= Offer Section Start Here =================*/}
      {(coupon.coupon_code != undefined) ?
        <Offer coupon={coupon} />
        :
        <></>
      }
      {/*================= Offer Section End Here =================*/}
      {/*================= New Collection Section Start Here =================*/}
      <div className="rts-new-collection-section section-gap">
        <div className="container">
          <div className="recent-products-header section-header"></div>
          <div className="swiper rts-cmmnSlider-over" data-swiper="pagination">
            <div className="swiper-wrapper">
              {category.map((item, index) => {
                return (
                  <div className="swiper-slide" key={index}>
                    <CategoryItemsList name={item.category_name} image={item.category_image} slug={item.category_slug} total={item.count_products} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/*================= New Collection Section End Here =================*/}
      {/*================= Hand Picked Section Start Here =================*/}
      <FeaturedItemsList icon={"wvbo-icon.png"} title={t('homepage.deals_product.title')} sub_title={t('homepage.deals_product.sub_title')} Objectdata={dealsproduct} setModel={setModel} />
      {/*================= Hand Picked Section End Here =================*/}
      {/*================= Deal Section Start Here =================*/}
      <Deals />
      {/*================= Deal Section End Here =================*/}
      {/*================= Featured Product Section Start Here =================*/}
      <FeaturedItemsList icon={"wvbo-icon.png"} title={t('homepage.featured_product.title')} sub_title={t('homepage.featured_product.sub_title')} Objectdata={featureproduct} setModel={setModel} />
      {/*================= Featured Product Section End Here =================*/}
      {/*================= Posters Section Start Here =================*/}
      <div className="rts-posters-section1">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6 col-12">
              <OffersPoster
                link={"products"}
                image={"pot.png"}
                sub_title={t('homepage.offers_poster.1.sub_title')}
                title={t('homepage.offers_poster.1.title')}
                btn_link={"/shop"}
                type={1}
                width={'80px'}
                height={'45px'}
              />
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12 col-12 last-child">
              <OffersPoster
                link={"products"}
                image={"dress.png"}
                sub_title={t('homepage.offers_poster.2.sub_title')}
                title={t('homepage.offers_poster.2.sub_title')}
                description={t('homepage.offers_poster.2.description')}
                type={2}
                width={'200px'}
                height={'196px'}
              />
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 col-12">
              <OffersPoster
                link={"products"}
                image={"3rd-image.png"}
                sub_title={t('homepage.offers_poster.3.sub_title')}
                title={t('homepage.offers_poster.3.sub_title')}
                btn_link={"/shop"}
                type={1}
                width={'80px'}
                height={'45px'}
              />
            </div>
          </div>
        </div>
      </div>
      {/*================= Posters Section End Here =================*/}
      {/*================= Brand Section Start Here =================*/}
      <HomeBrands data={brand} />
      {/*================= Brand Section End Here =================*/}
      {/*================= Featured Product Section Start Here =================*/}
      <FeaturedItemsList icon={"wvbo-icon.png"} title={t('homepage.top_sell_product.title')} sub_title={t('homepage.top_sell_product.sub_title')} Objectdata={topsellproducts} setModel={setModel} />
      {/*================= Featured Product Section End Here =================*/}
      {/*================= Newsletter Popup Start Here =================*/}
      {/* <div className="rts-newsletter-popup">
        <div className="newsletter-close-btn">
          <i className="fal fa-times" />
        </div>
        <div className="newsletter-inner">
          <h3 className="newsletter-heading">Get Weekly Newsletter</h3>
          <p>
            Priyoshop has brought to you the Hijab 3 Pieces Combo Pack PS23. It is a
            completely modern design
          </p>
          <form>
            <div className="input-area">
              <div className="input-div">
                <input type="text" placeholder="Your name" />
                <div className="input-icon">
                  <i className="far fa-user" />
                </div>
              </div>
              <div className="input-div">
                <input type="email" placeholder="Email address" required="" />
                <div className="input-icon">
                  <i className="far fa-envelope" />
                </div>
              </div>
            </div>
            <button type="submit" className="subscribe-btn">
              Subscribe Now <i className="fal fa-long-arrow-right ml--5" />
            </button>
          </form>
        </div>
      </div> */}
      <ProductDetailsPopup data={model} />
      {/*================= Newsletter Popup End Here =================*/}
    </React.Fragment>
  )
}

export default Home;