import React from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';

export default function Deals() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="rts-deal-section1">
        <div className="container">
          <div className="section-inner lazyload" data-bg={process.env.REACT_APP_ASSETS_URL + "/assets/images/hand-picked/counter-bg-1.webp"}>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" />
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="single-inner">
                  <div className="content-box">
                    <div className="sub-content">
                      <img
                        className="line-1 lazyload"
                        data-src={process.env.REACT_APP_ASSETS_URL + "/assets/images/banner/wvbo-icon.png"}
                        alt={t('homepage.deals.title')}
                        width={'22px'}
                        height={'6px'}
                      />
                      <span className="sub-text">{t('homepage.deals.title')}</span>
                    </div>
                    <h1 className="slider-title">
                      {t('homepage.deals.sub_text_1')} <br /> {t('homepage.deals.sub_text_2')}
                    </h1>
                    <div className="slider-description">
                      <p>
                        {t('homepage.deals.content')}
                      </p>
                    </div>
                    <div className="countdown" id="countdown">
                      <ul>
                        <li>
                          <span id="days" />D
                        </li>
                        <li>
                          <span id="hours" />H
                        </li>
                        <li>
                          <span id="minutes" />M
                        </li>
                        <li>
                          <span id="seconds" />S
                        </li>
                      </ul>
                    </div>
                    <div className="content-bottom">
                      <div className="img-box">
                        <img  className="lazyload" data-src={process.env.REACT_APP_ASSETS_URL + "/assets/images/hand-picked/deal-icon.png"} alt={t('homepage.deals.limited_time_offer')} width={'50px'} height={'50px'}/>
                      </div>
                      <p className="content">
                        {t('homepage.deals.limited_time_offer')} <br />
                        {t('homepage.deals.on')} {moment(new Date().getFullYear() + '-12-31').format("MMMM DD, YYYY")} {t('homepage.deals.hurry_up')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
