import React, { useEffect, useState } from 'react'
import { BreadCrumb, BlogCard, Preload, Search, PopularBlogs, Categories, Tags, NotFound, Pagination, Meta } from '../components';
import { Blog } from "../components/services"
import { useSearchParams } from "react-router-dom";
import CompanyConfig from "../components/CompanyConfig";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
function Blogs({ title }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { company_token } = CompanyConfig();
  const { category, tag } = useParams();
  const params = {};
  const perPage = 4;

  const [total, setTotal] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const search = searchParams.get("search") ? searchParams.get("search") : "";
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [values, setValues] = useState({
    category: "",
    tag: "",
  });

  useEffect(() => {

    if (search != "") {
      params.search = search;
    }
    if (page != 1) {
      params.page = page;
    }
    /* if (values.category.length) {
      params.category = JSON.stringify(values.category);
    }
    if (values.tag.length) {
      params.tag = JSON.stringify(values.tag);
    } */
    setSearchParams(params);
    fetchBlogs(page);
  }, [searchParams, page, category, tag]);

  /* useEffect(() => {
    if (searchParams.get("category")) {
      let category_params = JSON.parse(searchParams.get("category"));
      values.category = category_params;
    }
    if (searchParams.get("tag")) {
      let tag_params = JSON.parse(searchParams.get("tag"));
      values.tag = tag_params;
    }
    fetchBlogs(page);
  }, [searchParams]); */

  const setPageNumber = (pageNumber) => {
    fetchBlogs(pageNumber);
  };


  const fetchBlogs = async (page) => {
    setLoading(true);
    let parameter = {
      "company_token": company_token,
      "keyword": search,
      "category": (category != 'undefined') ? category : "",
      "tag": (tag != 'undefined') ? tag : "",
      "pagination": {
        "per_page": perPage,
        "page": page
      }
    }

    await Blog.getBlogs(parameter).then((res) => {
      if (res) {
        setPage(page);
        setBlogs(res.arrData);
        setTotal(res.total_count);
        setLoading(false);
      } else {
        setBlogs([]);
        setLoading(false);
      }
    })
  }


  /* const handleClick = (e) => {
    if (e.target.name === "category") {
      if (values.category != e.target.getAttribute("category_slug")) {
        setValues({ ...values, category: e.target.getAttribute("category_slug") });
      } else {
        setValues({ ...values, category: "" });
      }
    }
    if (e.target.name === "tag") {
      if (values.tag != e.target.getAttribute("data-tag")) {
        setValues({ ...values, tag: e.target.getAttribute("data-tag") });
      } else {
        setValues({ ...values, tag: "" });
      }
    }
  } */


  return (
    <React.Fragment>
      <Meta
        type="blog"
      />
      <BreadCrumb title={t('blogs.title')} />
      <div className="news-feed-area rts-featured-product-section3">
        <div className="container">
          <div className="rts-featured-product-section-inner">
            <div className="row mb-15">
              <div className="col-lg-8 pe-xl-0">
                <div className="row">
                  {loading ? <Preload /> :
                    blogs.length > 0
                      ?
                      blogs.map((blog, index) => (
                        <BlogCard
                          key={index}
                          blog_title={blog.blog_title}
                          blog_slug={blog.blog_slug}
                          blog_image={blog.blog_image}
                          blog_content={blog.blog_content}
                          blog_created_date={blog.blog_created_date}
                          blog_category={blog.blog_category}
                          blog_tag={blog.blog_tag}
                        />
                      ))
                      : <NotFound />}
                </div>

                {
                  loading
                    ? <></>
                    : blogs.length > 0
                      ? (
                        <Pagination
                          page={page}
                          perPage={perPage}
                          total={total}
                          pageNumber={setPageNumber}
                        />
                      ) : (
                        <></>
                      )}
              </div>
              <div className="col-lg-4 pl-30 pl-lg-15 pl-md-15 pl-xs-15">
                <div className="news-right-widget">
                  <Search />
                  <PopularBlogs />
                  <Categories />
                  <div className="widget widget-categories-tag mb-40">
                    <div className="widget-title-box pb-25 mb-25">
                      <h4 className="widget-sub-title2 fs-20">{t('blogs.tags')}</h4>
                    </div>
                    <Tags />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Blogs