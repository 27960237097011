import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BreadCrumb, Toast } from '../components';
import { Auth } from '../components/services';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CompanyConfig from '../components/CompanyConfig';
import { useTranslation } from 'react-i18next';
export default function ForgotPassword({ title }) {
  const { t } = useTranslation();
  const { company_token } = CompanyConfig();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // title
    document.title = title;
  }, [])

  const forgotPassword = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email:
        Yup.string()
          .email(t('forgot_password.form.email.validation.email'))
          .required(t('forgot_password.form.email.validation.required')),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.company_token = company_token;
      Auth.getForgotPassword(values).then((res) => {
        if (res) {
          resetForm();
          Toast(res.message, "success");
          navigate("/forgot-password-verify", { state: { verifyCode: true } });
        }
        setLoading(false);
      })
    },
  });

  return (
    <React.Fragment>
      <BreadCrumb title={t('forgot_password.title')} />
      <div className="contact-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form className="contact-form mb-10" onSubmit={forgotPassword.handleSubmit}>
                <div className="section-header section-header5 text-center align-items-center">
                  <div className="wrapper text-center">
                    <h2 className="title">{t('forgot_password.forgot_password')}</h2>
                    <div className="sub-content">
                      <img
                        className="line-1"
                        src={process.env.REACT_APP_URL + "/assets/images/banner/wvbo-icon.png"}
                        alt="icon"
                      />
                      <span className="sub-text">{t('forgot_password.required_fields_are_marked')}</span>
                    </div>
                  </div>
                </div>
                <div className="info-form">
                  <div className="row justify-content-center w-50 m-auto">
                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                      <div className="input-box mail-input mb-20">
                        <input
                          type="email"
                          placeholder={t('forgot_password.form.email.placeholder')}
                          name="email"
                          onChange={forgotPassword.handleChange}
                          onBlur={forgotPassword.handleBlur}
                          value={forgotPassword.values.email}
                        />
                        {forgotPassword.touched.email && forgotPassword.errors.email ? (
                          <div className='text-danger'>{forgotPassword.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                      <button type="submit" className="form-btn form-btn4 w-100 text-center" disabled={loading ? true : false} >
                        {
                          loading ?
                            <i className={`fal ${loading ? "fa-spinner fa-spin" : ""}`} />
                            : t('forgot_password.button_submit')
                        }
                      </button>
                    </div>
                    <div className='d-flex justify-content-center mb-5 mt-3'>
                      <span className='text-muted me-2'>{t('forgot_password.already_have_account')} </span>
                      <Link to="/login" className='text-decoration-underline'>
                        {t('forgot_password.login')}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
