import { Toast } from "../components";
import { Auth } from "./services";

const INITIAL_STATE = {
  cart:
    localStorage.getItem("cart") == null
      ? []
      : JSON.parse(localStorage.getItem("cart")),
  wishlist:
    localStorage.getItem("wishlist") == null
      ? []
      : JSON.parse(localStorage.getItem("wishlist")),
  tempitems: [],
  discount: [],
  product: [],
  setting: [],
};

const Reducer = (state = INITIAL_STATE, action) => {
  //** items total define */
  let cartData;
  let total = 0;
  let sub_total = 0;
  let discount_amount = 0;

  //** items */
  if (action.type === "ADD") {
    let tempcart = state.cart.filter((item) => item.id === action.payload.id);
    if (tempcart < 1) {
      Toast("Item added to Cart", "success");
      if (Auth.isAuthenticate()) {
        Auth.getCartCreate(action.payload);
      }
      state = {
        ...state,
        cart: [...state.cart, action.payload],
      };
    } else {
      Toast("Item is Already added to Cart", "info");
      let cart = state.cart.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, quantity: parseInt(item.quantity) + 1 };
        }
        return item;
      });
      state = {
        ...state,
        cart,
        discount: [],
      };
    }
  }
  if (action.type === "REMOVE") {
    Toast("Item Removed to Cart", "success");
    let remove = state.cart.filter((item) => item.id !== action.payload.id);
    if (remove.length == 0) {
      remove = [];
    }
    if (Auth.isAuthenticate()) {
      Auth.getCartDelete(action.payload);
    }
    state = {
      ...state,
      cart: remove,
      discount: [],
    };
  }
  if (action.type === "INCREASE") {
    let cart = state.cart.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, quantity: parseInt(item.quantity) + 1 };
      }
      return item;
    });

    state = {
      ...state,
      cart,
      discount: [],
    };
  }
  if (action.type === "DECREASE") {
    let cart = state.cart.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, quantity: parseInt(item.quantity) - 1 };
      }
      return item;
    });
    state = {
      ...state,
      cart,
      discount: [],
    };
  }
  if (action.type === "RESET") {
    Auth.getCart().then((res) => {
      if (res.arrData.length) {
        res.arrData.map((item) => {
          item.id = item.product_uid;
          action.payload.map((item2) => {
            if (parseInt(item2.product_uid) === parseInt(item.product_uid)) {
              Auth.getCartDelete(item);
            }
          });
        });
      }
    });
    state = {
      ...state,
      cart: [],
      discount: [],
    };
  }
  if (action.type === "RESET_ALL") {
    state = {
      ...state,
      cart: [],
      wishlist: [],
      discount: [],
    };
  }

  //** wishlist items */
  if (action.type === "WISHLIST_ADD") {
    let tempwishlist = state.wishlist.filter(
      (item) => item.id === action.payload.id
    );
    if (tempwishlist < 1) {
      if (Auth.isAuthenticate()) {
        Auth.getWishlistCreate(action.payload);
      }
      Toast("Product is added to Wishlist", "success");
      state = {
        ...state,
        wishlist: [...state.wishlist, action.payload],
      };
    } else {
      state = state;
    }
  }
  if (action.type === "WISHLIST_REMOVE") {
    Toast("Product is removed to Wishlist", "success");
    let remove = state.wishlist.filter((item) => item.id !== action.payload.id);
    if (remove.length == 0) {
      remove = [];
    }
    if (Auth.isAuthenticate()) {
      Auth.getWishlistDelete(action.payload);
    }
    state = {
      ...state,
      wishlist: remove,
    };
  }

  //** temp popup items */
  if (action.type === "TEMP_ITEMS_ADD") {
    let tempitem = state.tempitems.filter(
      (item) => item.id === action.payload.id
    );
    if (tempitem < 1) {
      state = {
        ...state,
        tempitems: [...state.tempitems, action.payload],
      };
    } else {
      state = state;
    }
  }
  if (action.type === "TEMP_ITEMS_REMOVE") {
    let remove = state.tempitems.filter(
      (item) => item.id !== action.payload.id
    );
    if (remove.length == 0) {
      remove = [];
    }
    state = {
      ...state,
      tempitems: remove,
    };
  }
  if (action.type === "TEMP_ITEMS_INCREASE") {
    let tempitems = state.tempitems.map((item) => {
      if (item.id == action.payload.id) {
        return { ...item, quantity: parseInt(item.quantity) + parseInt(1) };
      }
      return item;
    });
    state = {
      ...state,
      tempitems,
    };
  }
  if (action.type === "TEMP_ITEMS_DECREASE") {
    let tempitems = state.tempitems.map((item) => {
      if (item.id == action.payload.id) {
        return { ...item, quantity: parseInt(item.quantity) - 1 };
      }
      return item;
    });
    state = {
      ...state,
      tempitems,
    };
  }
  if (action.type === "TEMP_ITEMS_RESET") {
    state = {
      ...state,
      tempitems: [],
    };
  }

  //** Add setting */
  if (action.type === "ADD_SETTING") {
    state = {
      ...state,
      setting: {
        ...state.setting,
        currency_setting: action.payload.currency_setting,
        ecommerce_setting: action.payload.ecommerce_setting,
        general_setting: action.payload.general_setting,
        payment_gateway: action.payload.payment_gateway
      },
    };
  }

  //** items total */
  if (state.cart.length > 0) {
    state.cart.map((item) => {
      total += parseInt(item.price) * parseInt(item.quantity);
    });
  }

  //** coupon code */
  if (action.type === "DISCOUNT") {
    let discount = state.discount.filter(
      (coupon) => coupon.uid === action.payload.uid
    );
    if (discount < 1) {
      if (total > parseInt(action.payload.amount)) {
        discount_amount += parseInt(action.payload.amount);
        state = {
          ...state,
          discount: [...state.discount, action.payload],
        };
        Toast("Coupon Code applied!", "success");
      } else {
        Toast("Something Wrong!", "error");
        state = state;
      }
    } else {
      Toast("Coupon Code Already applied!", "info");
      state = state;
    }
  }

  if (action.type === "DISCOUNT_REMOVE") {
    Toast("Coupon Code is removed!", "success");
    let remove = state.discount.filter(
      (coupon) => coupon.uid !== action.payload.uid
    );
    if (remove.length == 0) {
      remove = [];
    }
    state = {
      ...state,
      discount: remove,
    };
  }

  if (action.type === "LOGIN") {
    let cartData = JSON.parse(localStorage.getItem("cart"));
    let wishlistData = JSON.parse(localStorage.getItem("wishlist"));

    if (cartData.length) {
      cartData.map((item) => {
        Auth.getCartCreate(item);
      });
    }
    if (wishlistData.length) {
      wishlistData.map((item) => {
        Auth.getWishlistCreate(item);
      });
    }
  }

  if (
    action.type === "ADD" ||
    action.type === "INCREASE" ||
    action.type === "DECREASE"
  ) {
    state.cart.map((item) => {
      if (Auth.isAuthenticate()) {
        Auth.getCartEdit(item);
      }
    });
  }

  state = {
    ...state,
    product: [
      {
        total: discount_amount != 0 ? total - discount_amount : total,
        sub_total: total,
      },
    ],
  };

  //** set items */

  if (Auth.isAuthenticate()) {
    Auth.getCart().then((res) => {
      if (res.arrData.length) {
        res.arrData.map((item) => {
          item.id = item.product_uid;
        });
      }
      state.cart = res.arrData;
    });
    Auth.getWishlist().then((res) => {
      if (res.arrData.length) {
        res.arrData.map((item) => {
          item.id = item.product_uid;
        });
      }
      state.wishlist = res.arrData;
    });
  }

  localStorage.setItem("cart", JSON.stringify(state.cart));
  localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
  return state;
};
export default Reducer;
