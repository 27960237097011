import React from 'react'

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function CategoryItemsList(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="collection-item">
        <Link to={`categories/${props.slug}`}>
          <img
            className="lazyload"
            data-src={props.image}
            alt={props.name}
            width={'327px'}
            height={'436px'}
          />
        </Link>
        <p className="item-quantity">
          {props.total} <span>{t('homepage.category_item_list.items')}</span>
        </p>
        <Link to={`categories/${props.slug}`} className="item-catagory-box">
          <h3 className="title">{props.name}</h3>
        </Link>
      </div>
    </React.Fragment>
  )
}
