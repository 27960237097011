import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className='text-center m-auto w-50 h-50 py-md-0 py-5'>
      <img src={process.env.REACT_APP_URL + "/assets/images/error/no-data-found.svg"} alt="not found" className="img-fluid" />
      <span className='text-center'>{t('not_found')}</span>
    </div>
  )
}
