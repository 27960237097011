import React from "react";
import i18next from "i18next";

const SelectLanguage = () => {
  function handlechange(event) {
    const value = event.target.value;
    i18next.changeLanguage(value);
    window.location.reload();
  }
  let selectLG = "";
  if (localStorage.getItem("i18nextLng")) {
    selectLG = localStorage.getItem("i18nextLng");
  }
  return (
    <div className="topbar-select-area">
      <select className="c-language-select" onChange={handlechange}>
        <option value="en" selected={selectLG === "en" ? true : false}>
          English
        </option>
        <option value="chi" selected={selectLG === "chi" ? true : false}>
          Chinese
        </option>
      </select>
    </div>
  );
};

export default SelectLanguage;
