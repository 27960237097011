//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { Toast } from "../../../components";
import CompanyConfig from "../../CompanyConfig";
import { Customer } from "../../CustomerConfig";
import { useDispatch } from "react-redux";


const getUser = async (data) => {
  try {
    let result = await api
      .post(Apis.GetUser, data)
    if (result.data.error == 1) {
      Toast("Something wrong!", "error");
      return null;
    }
    if (result.data.success !== 1) {
      logout();
      window.location.reload();
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getLogin = async (data) => {
  try {
    let result = await api
      .post(Apis.GetUserLogin, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    } else {
      let data = {
        access_token: result.data.access_token,
        email: result.data.email,
        user_uid: result.data.user_uid,
      }
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('isLoggin', true);
      return result.data;
    }
  } catch (error) {
    console.error(error.message);
  }
}

const getRegsiter = async (data) => {
  try {
    let result = await api
      .post(Apis.GetUserRegsiter, data)
    if (result.data.error == 1) {
      for (const key in result.data.arr_error) {
        Toast(result.data.arr_error[key], "error");
      }
      return result.data;
    }
    if (result.data.access_token !== "") {
      let data = {
        access_token: result.data.access_token,
        email: result.data.email,
        user_uid: result.data.user_uid,
      }
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('isLoggin', true);
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}


const getForgotPassword = async (data) => {
  try {
    let result = await api
      .post(Apis.GetForgotPassword, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getVerifyForgotPassword = async (data) => {
  try {
    let result = await api
      .post(Apis.GetVerifyForgotPassword, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const changePassword = async (data) => {
  try {
    let result = await api
      .post(Apis.GetChangePassword, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getUpdateProfile = async (data) => {
  try {
    let result = await api
      .post(Apis.GetUpdateProfile, data,{
        headers: { 'Content-Type': 'multipart/form-data' },
    })
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const isAuthenticate = () => {
  if (typeof window == 'undefined') {
    return false;
  }
  let user = JSON.parse(window.localStorage.getItem('user'));
  let isLoggin = window.localStorage.getItem('isLoggin');
  if (user && isLoggin) {
    return true;
  }
  return false;
};

const logout = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('isLoggin');
    window.localStorage.clear();
    return true;
  }
};


const getCart = async () => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let data = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
  }
  try {
    let result = await api
      .post(Apis.Cart, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCartCreate = async (data) => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let postdata = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
    "product_uid": data.id,
    "title": data.title,
    "image": data.image,
    "quantity": parseInt(data.quantity),
    "price": data.price,
    "category": data.category,
    "brand": data.brand,
    "variation": data.variation
  }
  try {
    let result = await api
      .post(Apis.CartCreate, postdata)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCartEdit = async (data) => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let postdata = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
    "product_uid": data.id,
    "quantity": parseInt(data.quantity),
  }
  try {
    let result = await api
      .post(Apis.CartEdit, postdata)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getCartDelete = async (data) => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let postdata = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
    "product_uid": data.id,
  }
  try {
    let result = await api
      .post(Apis.CartDelete, postdata)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getWishlist = async () => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let data = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
  }
  try {
    let result = await api
      .post(Apis.Wishlist, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getWishlistCreate = async (data) => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let postdata = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
    "product_uid": data.id,
    "title": data.title,
    "image": data.image,
    "price": data.price,
    "category": data.category,
    "brand": data.brand,
    "variation":data.variation
  }
  try {
    let result = await api
      .post(Apis.WishlistCreate, postdata)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getWishlistDelete = async (data) => {
  const { company_token } = CompanyConfig();
  const { CustomerData } = Customer();
  let postdata = {
    "company_token": company_token,
    "access_token": CustomerData.access_token,
    "product_uid": data.id,
  }
  try {
    let result = await api
      .post(Apis.WishlistDelete, postdata)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

export default {
  getUser,
  isAuthenticate,
  logout,
  getLogin,
  getRegsiter,
  changePassword,
  getUpdateProfile,
  getForgotPassword,
  getVerifyForgotPassword,
  getCart,
  getCartCreate,
  getCartEdit,
  getCartDelete,
  getWishlist,
  getWishlistCreate,
  getWishlistDelete
};