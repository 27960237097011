import React from 'react'

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function OffersPoster(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>

      {(props.type == 1) ?
        <Link
          to={"/" + props.link} className="product-box product-box-medium product-box-medium3">
          <div className="contents">
            <span className="pretitle">{props.sub_title}</span>
            <h3 className="product-title">
              {props.title}
            </h3>
            <div className="view-collections go-btn">{t('homepage.offers_poster.shop_now')} <i className="rt-arrow-right-long"></i></div>
          </div>
          <div className="product-thumb"><img data-src={process.env.REACT_APP_ASSETS_URL + "/assets/images/featured/" + props.image} alt={props.title} className="lazyload" width={props.width} height={props.height} /></div>
        </Link>

        :
        <Link
          to={"/" + props.link} className="product-box product-box-medium mid">
          <div className="contents">
            <span className="pretitle">{props.sub_title}</span>
            <h3 className="product-title">{props.title}</h3>
            <p>{props.description}</p>
          </div>
          <div className="product-thumb product-thumb1"><img data-src={process.env.REACT_APP_ASSETS_URL + "/assets/images/featured/" + props.image} alt={props.title} className="lazyload" width={props.width} height={props.height} /></div>
        </Link>
      }
    </React.Fragment >
  )
}
