import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Toast } from "../../components";
import { useNavigate } from "react-router-dom";
import { Customer } from "../../components/CustomerConfig";

import { Address } from "../../components/services";
import { useTranslation } from 'react-i18next';
export default function AddressBookForm({ addressData = null }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { CustomerData } = Customer();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);

  const AddNewAddress = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: addressData !== null ? "edit" : "add",
      address_uid: addressData !== null ? addressData.uid : "",
      address1: addressData !== null ? addressData.address1 : "",
      address2: addressData !== null ? addressData.address2 : "",
      city: addressData !== null ? addressData.city : "",
      state_uid: addressData !== null ? addressData.state_uid : "",
      zipcode: addressData !== null ? addressData.zipcode : "",
      country_uid: addressData !== null ? addressData.country_uid : "",
      is_default_billing: addressData !== null ? addressData.is_default_billing : "",
      is_default_shipping: addressData !== null ? addressData.is_default_shipping : "",
      address_type: addressData !== null ? addressData.address_type : "",
    },
    validationSchema: Yup.object({
      country_uid:
        Yup.string()
          .required(t('account.tabs.6.form.country_uid.validation.required')),
      state_uid:
        Yup.string()
          .required(t('account.tabs.6.form.state_uid.validation.required')),
      city:
        Yup.string()
          .required(t('account.tabs.6.form.city.validation.required')),
      zipcode:
        Yup
          .number()
          .typeError(t('account.tabs.6.form.zipcode.validation.typeError'))
          .positive(t('account.tabs.6.form.zipcode.validation.positive'))
          .integer(t('account.tabs.6.form.zipcode.validation.integer'))
          .required(t('account.tabs.6.form.zipcode.validation.required')),
      address1:
        Yup
          .string()
          .required(t('account.tabs.6.form.address1.validation.required')),
      is_default_billing:
        Yup.string()
          .required(t('account.tabs.6.form.is_default_billing.validation.required')),
      is_default_shipping:
        Yup.string()
          .required(t('account.tabs.6.form.is_default_shipping.validation.required')),
      address_type:
        Yup.string()
          .required(t('account.tabs.6.form.address_type.validation.required')),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.access_token = CustomerData.access_token;
      values.type === "add"
        ? Address.getCreateAddress(values).then((res) => {
          if (res) {
            resetForm();
            Toast(res.message, "success");
            navigate("/account/address");
          }
          setLoading(false);
        })
        : Address.getEditAddress(values).then((res) => {
          if (res) {
            resetForm();
            Toast(res.message, "success");
            navigate("/account/address");
          }
          setLoading(false);
        })
    },
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      fetchCountry();
    }
    if (addressData !== null) {
      fetchState(addressData.country_uid)
    }
    return () => {
      isCancelled = true;
    };
  }, [addressData])

  const fetchCountry = async () => {
    await Address.getCountryList().then((res) => {
      setCountry(res.arrData);
    });
  };

  const fetchState = async (countryId) => {
    await Address.getStateList(countryId).then((res) => {
      setState(res.arrData);
    });
  };

  const changeHandler = (e) => {
    if (e.target.name === "country_uid") {
      fetchState(e.target.value)
    }
  }

  return (
    <div className="login-form">
      <div className="section-title">
        <h2>{addressData === null ? t('account.tabs.6.add.add_address') : t('account.tabs.6.edit.edit_address')}</h2>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={AddNewAddress.handleSubmit}>
            <div className="form">
              <input type="text"
                className="form-control" placeholder={t('account.tabs.6.form.address1.placeholder')} name="address1" onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.address1} />
              {AddNewAddress.touched.address1 && AddNewAddress.errors.address1 ? (
                <div className='text-danger'>{AddNewAddress.errors.address1}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="text"
                className="form-control" placeholder={t('account.tabs.6.form.address2.placeholder')} name="address2" onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.address2} />
              {AddNewAddress.touched.address2 && AddNewAddress.errors.address2 ? (
                <div className='text-danger'>{AddNewAddress.errors.address2}</div>
              ) : null}
            </div>
            <div className="form">
              <select name="country_uid" className="form-control"
                onChange={(e) => { AddNewAddress.handleChange(e); changeHandler(e) }}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.country_uid}
              >
                <option value="">{t('account.tabs.6.form.country_uid.placeholder')}</option>
                {
                  country.length > 0
                    ? country.map((country, index) => (
                      <option key={index} value={country.uid}>{country.name}</option>
                    ))
                    : ""
                }
              </select>
              {AddNewAddress.touched.country_uid && AddNewAddress.errors.country_uid ? (
                <div className='text-danger'>{AddNewAddress.errors.country_uid}</div>
              ) : null}
            </div>
            <div className="form">
              <select name="state_uid" className="form-control"
                onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.state_uid}
              >
                <option value="">{t('account.tabs.6.form.state_uid.placeholder')}</option>
                {
                  state.length > 0
                    ? state.map((state, index) => (
                      <option key={index} value={state.uid}>{state.name}</option>
                    ))
                    : ""
                }
              </select>
              {AddNewAddress.touched.state_uid && AddNewAddress.errors.state_uid ? (
                <div className='text-danger'>{AddNewAddress.errors.state_uid}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="text" className="form-control" placeholder={t('account.tabs.6.form.city.placeholder')} name="city"
                onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.city} />
              {AddNewAddress.touched.city && AddNewAddress.errors.city ? (
                <div className='text-danger'>{AddNewAddress.errors.city}</div>
              ) : null}
            </div>
            <div className="form">
              <input type="text" className="form-control" placeholder={t('account.tabs.6.form.zipcode.placeholder')} name="zipcode"
                onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.zipcode} />
              {AddNewAddress.touched.zipcode && AddNewAddress.errors.zipcode ? (
                <div className='text-danger'>{AddNewAddress.errors.zipcode}</div>
              ) : null}
            </div>
            <div className="form">
              <select name="is_default_billing" className="form-control"
                onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.is_default_billing}
              >
                <option value="">{t('account.tabs.6.form.is_default_billing.select.1')}</option>
                <option value="1">{t('account.tabs.6.form.is_default_billing.select.2')}</option>
                <option value="0">{t('account.tabs.6.form.is_default_billing.select.3')}</option>
              </select>
              {AddNewAddress.touched.is_default_billing && AddNewAddress.errors.is_default_billing ? (
                <div className='text-danger'>{AddNewAddress.errors.is_default_billing}</div>
              ) : null}
            </div>
            <div className="form">
              <select name="is_default_shipping" className="form-control"
                onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.is_default_shipping}
              >
                <option value="">{t('account.tabs.6.form.is_default_shipping.select.1')}</option>
                <option value="1">{t('account.tabs.6.form.is_default_shipping.select.2')}</option>
                <option value="0">{t('account.tabs.6.form.is_default_shipping.select.3')}</option>
              </select>
              {AddNewAddress.touched.is_default_shipping && AddNewAddress.errors.is_default_shipping ? (
                <div className='text-danger'>{AddNewAddress.errors.is_default_shipping}</div>
              ) : null}
            </div>
            <div className="form">
              <select name="address_type" className="form-control"
                onChange={AddNewAddress.handleChange}
                onBlur={AddNewAddress.handleBlur}
                value={AddNewAddress.values.address_type}
              >
                <option value="">{t('account.tabs.6.form.address_type.select.1')}</option>
                <option value="home">{t('account.tabs.6.form.address_type.select.2')}</option>
                <option value="office">{t('account.tabs.6.form.address_type.select.3')}</option>
                <option value="other">{t('account.tabs.6.form.address_type.select.4')}</option>
              </select>
              {AddNewAddress.touched.address_type && AddNewAddress.errors.address_type ? (
                <div className='text-danger'>{AddNewAddress.errors.address_type}</div>
              ) : null}
            </div>
            <div className="form">
              <button type="submit" className="btn" disabled={loading ? true : false} >{loading ? t('account.tabs.6.form.button_loading') : addressData === null ? t('account.tabs.6.form.button_create') : t('account.tabs.6.form.button_update')}
                <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
