import React, { useState } from 'react'
import { Toast } from '../../components'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function NewsletterForm() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const newsletter = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema:
      Yup.object({
        email:
          Yup.string()
            .email(t('footer.get_newsletter.form.email_validation_email'))
            .required(t('footer.get_newsletter.form.email_validation_required')),
      }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setTimeout(() => {
        resetForm();
        Toast(t('footer.get_newsletter.form.response_success_msg'), "success");
        setLoading(false);
      }, 1000);
    },
  });

  return (
    <form onSubmit={newsletter.handleSubmit}>
      <div className="input-div">
        <input
          name='email'
          type="email"
          placeholder={t('footer.get_newsletter.form.email_placeholder')}
          onChange={newsletter.handleChange}
          onBlur={newsletter.handleBlur}
          value={newsletter.values.email}
        />
        {newsletter.touched.email && newsletter.errors.email ? (
          <div className='text-danger'>{newsletter.errors.email}</div>
        ) : null}
      </div>
      <button type="submit" className="subscribe-btn" disabled={loading ? true : false} >{loading ? t('footer.get_newsletter.form.btn_text_loading') : t('footer.get_newsletter.form.btn_text_subscribe_now') }
        <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
    </form>
  )
}
