import React, { useState, useEffect } from 'react'
import { PopularBlogsCard } from '../../components';
import { Blog } from '../services';
import { useTranslation } from 'react-i18next';

export default function PopularBlogs() {
  const { t } = useTranslation();
  const [popularblog, setPopularBlog] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    let isCancelled = false;
    const fetchPopularBlog = async () => {
      setLoading(true);
      await Blog.getPopularBlog().then((res) => {
        if (res) {
          setPopularBlog(res.arrData);
          setLoading(false);
        }
      })
    }
    if (!isCancelled) {
      fetchPopularBlog();
    }
    return () => {
      isCancelled = true;
    }
  }, [])
  return (
    <div className="widget widget-post mb-40">
      <div className="widget-title-box pb-25 mb-30">
        <h4 className="widget-sub-title2 fs-20">{t('blogs.popular_feeds')}</h4>
      </div>
      <ul className="post-list">
        {
          popularblog.length ?
            popularblog.map((item, index) => (
              
              <li>
                <PopularBlogsCard  
                title={item.blog_title}
                slug={item.blog_slug}
                image={item.blog_image}
                date={item.blog_created_date}
                />
              </li>
            ))
            :
            "Not Found"
        }
      </ul>
    </div>
  )
}
