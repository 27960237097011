import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Toast } from '../../components'
import { Auth } from '../../components/services';
import CompanyConfig from '../../components/CompanyConfig';
import { useSelector, useDispatch } from "react-redux";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
export default function LoginForm({ redirect = true, path = "" }) {
  const { cart } = useSelector((state) => state);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let location = useLocation();
  const navigate = useNavigate();
  const { company_token } = CompanyConfig();
  const [loading, setLoading] = useState(false);
  const [showPasswordIcon1, setShowPasswordIcon1] = useState(false);

  const showPassword1 = () => {
    setShowPasswordIcon1(!showPasswordIcon1)
  }

  const login = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema:
      Yup.object({
        email:
          Yup.string()
            .email(t('login.form.email.validation.email'))
            .required(t('login.form.email.validation.required')),
        password:
          Yup.string()
            .required(t('login.form.password.validation.required')),
      }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.company_token = company_token;
      Auth.getLogin(values).then((res) => {
        if (res) {
          resetForm();
          Toast(res.message, "success");
          setLoading(false);
          dispatch({ type: "LOGIN" });
          (cart.length > 0)
            ? navigate("/checkout")
            : (redirect)
              ? navigate("/home")
              : navigate(path ? path : location.pathname);
        }
        setLoading(false);
      })
    },
  });


  return (
    <div className="login-form">
      <div className="section-title">
        <h2>{t('login.title')}</h2>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={login.handleSubmit}>
            <div className="form">
              <input type="text" className="form-control" name="email" placeholder={t('login.form.email.placeholder')}
                onChange={login.handleChange}
                onBlur={login.handleBlur}
                value={login.values.email} />

              {login.touched.email && login.errors.email ? (
                <div className='text-danger'>{login.errors.email}</div>
              ) : null}
            </div>
            <div className="form">
              <div className="password-input">
                <input type={`${showPasswordIcon1 ? "text" : "password"}`} className="form-control" name="password" placeholder={t('login.form.password.placeholder')}
                  onChange={login.handleChange}
                  onBlur={login.handleBlur}
                  value={login.values.password}
                />
                {login.touched.password && login.errors.password ? (
                  <div className='text-danger'>{login.errors.password}</div>
                ) : null}
                <span className={`${showPasswordIcon1 ? "hide" : "show"}-password-input`} onClick={showPassword1} />
              </div>
            </div>
            <div className="form">
              <button type="submit" className="btn" disabled={loading ? true : false} >{loading ? t('login.button_loading') : t('login.button_login')}
                <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
            </div>
            <Link className="forgot-password" to="/forgot-password">{t('login.lost_your_password')}</Link>
          </form>
        </div>
      </div>
    </div>
  )
}
