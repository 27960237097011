import React, { useEffect, useState } from 'react'
import { BreadCrumb, Meta } from '../components';
import { Pages } from "../components/services";
import { useTranslation } from 'react-i18next';
export default function TermsOfUse() {
  const { t } = useTranslation();
  const [termsOfUseContent, setTermsOfUseContent] = useState();

  useEffect(() => {

    let isCancelled = false;
    const fetchTermsOfUse = async () => {
      await Pages.getTermsOfUse().then((res) => {
        setTermsOfUseContent(res);
      });
    };
    if (!isCancelled) {
      fetchTermsOfUse();
    }
    return () => {
      isCancelled = true;
    };
  }, [])

  return (
    <React.Fragment>
      <Meta
        type="terms_of_use"
      />
      <BreadCrumb title={t('terms_of_use.title')} />
      <section className="faq-area pt-120 pb-90 pt-md-55 pb-md-30 pt-xs-55 pb-xs-30 c-terms-of-use">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{ __html: termsOfUseContent ? termsOfUseContent : t('not_found') }}
          />
        </div>
      </section>
    </React.Fragment>
  )
}

