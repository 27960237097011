export function CustomerConfig() {
  const { user_uid } = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : "";
  return {
    user_uid
  }
}
export function Customer() {
  const CustomerData = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : "";
  return {
    CustomerData
  }
}