import React, { useEffect, useState } from 'react'
import { BreadCrumb, Meta } from '../components';
import { Pages } from "../components/services";
import { useTranslation } from 'react-i18next';
export default function PrivacyPolicy() {
  const { t } = useTranslation();
  const [privacyContent, setPrivacyContent] = useState();

  useEffect(() => {
    let isCancelled = false;
    const fetchPrivacyPolicy = async () => {
      await Pages.getPrivacyPolicy().then((res) => {
        setPrivacyContent(res);
      });
    };
    if (!isCancelled) {
      fetchPrivacyPolicy();
    }
    return () => {
      isCancelled = true;
    };
  }, [])

  return (
    <React.Fragment>
      <Meta
        type="privacy_policy"
      />
      <BreadCrumb title={t('privacy_policy.title')} />
      <section className="faq-area pt-120 pb-90 pt-md-55 pb-md-30 pt-xs-55 pb-xs-30 c-privacy-policy">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{ __html: privacyContent ? privacyContent : t('not_found') }}
          />
        </div>
      </section>
    </React.Fragment>
  )
}
