import Paginate from "react-js-pagination";
import React from 'react'

export default function Pagination({ page, perPage, total, pageNumber }) {
  return (
    <Paginate
      activePage={parseInt(page)}
      itemsCountPerPage={parseInt(perPage)}
      totalItemsCount={parseInt(total)}
      onChange={pageNumber}
      itemClass="number"
      linkClass="page-link"
      itemClassFirst="d-none"
      itemClassLast="d-none"
      firstPageText="First"
      lastPageText="Last"
      innerClass="product-pagination-area mt--20 c-pagination"
    />
  )
}
