import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import '../stripe/Stripe.css';

//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { GetStripePublicKey } from "../components/Helpers";
/* var PK_Key = GetStripePublicKey(); */
/* const stripePromise = loadStripe("pk_test_b7npP6mrpMyGAVTPtR2TsuVg"); */
const AcceptPayment = () => {
  var PK_Key = GetStripePublicKey();
  const stripePromise = loadStripe(PK_Key);
  const Navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('redirect_status');
  if (!localStorage.getItem('order_id')) {
    Navigate('/checkout');
  }
  let isCancelled = false;
  useEffect(() => {
    if (myParam != '' && myParam != null && myParam == 'succeeded') {
      localStorage.removeItem('order_id');
      localStorage.removeItem('cart');
      Navigate('/thank-you');
    } else {
      const fetchData = async () => {
        try {
          await api
            .post(Apis.StripePayment, {
              order_uid: localStorage.getItem('order_id')
            })
            .then((res) => {
              if (res.data.success == 1) {
                setClientSecret(res.data.paymentIntent.client_secret);
                setAmount(res.data.paymentIntent.amount);
              }
            })
        } catch (error) {
          console.error(error.message);
        }
      };
      if (!isCancelled) {
        fetchData();
      }
      return () => {
        isCancelled = true;
      }

      // Create PaymentIntent as soon as the page loads
      /* fetch("/create.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.paymentIntent.client_secret)); */
    }

  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      <div className="payment-stripe">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm amount={amount} />
          </Elements>
        )}
      </div>
    </>
  )
}

export default AcceptPayment