//** Api
import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { Toast } from "../../../components";
import CompanyConfig from "../../CompanyConfig";
import { Customer } from "../../CustomerConfig";

const getCreateAddress = async (data) => {
  try {
    let result = await api
      .post(Apis.CreateAddress, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getEditAddress = async (data) => {
  try {
    let result = await api
      .post(Apis.EditAddress, data)
    if (result.data.error == 1) {
      for (const key in result.data.message) {
        Toast(result.data.message[key], "error");
      }
      return null;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getAddress = async (data) => {
  try {
    let result = await api
      .post(Apis.GetAddress, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getAddressList = async (data) => {
  try {
    let result = await api
      .post(Apis.GetAllAddress, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const deleteAddress = async (data) => {
  try {
    let result = await api
      .post(Apis.deleteAddress, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getCountryList = async () => {
  const { company_token } = CompanyConfig();
  let data = {
    company_token: company_token
  }
  try {
    let result = await api
      .post(Apis.GetCountry, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}
const getCustomerAddress = async () => {
  const { CustomerData } = Customer();
  let data = {
    access_token: CustomerData.access_token
  }
  try {
    let result = await api
      .post(Apis.CustomerAddress, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}



const getStateList = async (countryId) => {
  let data = {
    "country_uid": countryId
  }
  try {
    let result = await api
      .post(Apis.GetState, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

const getShippingSetting = async (data) => {
  try {
    let result = await api
      .post(Apis.ShippingSetting, data)
    if (result.data.error == 1) {
      return result.data;
    }
    return result.data;
  } catch (error) {
    console.error(error.message);
  }
}

export default {
  getAddress,
  getCreateAddress,
  getEditAddress,
  getAddressList,
  deleteAddress,
  getCountryList,
  getStateList,
  getShippingSetting,
  getCustomerAddress
};


