import React, { useEffect, useState } from "react";
import CompanyConfig from "../components/CompanyConfig";
import { Auth, Address } from "../components/services";

import { Link, useNavigate } from "react-router-dom";
import {
  BreadCrumb,
  Coupon,
  CouponSuccess,
  AddressSection,
  Toast
} from "../components";
import { Product } from "../components/services";
import { LoginForm, SignupForm } from "../components/Forms";

import { useFormik } from "formik";
import * as Yup from "yup";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Customer } from "../components/CustomerConfig";

import { useDispatch, useSelector } from "react-redux";

import { GetPrice } from "../components/Helpers";
import { useTranslation } from 'react-i18next';
const Checkout = ({ title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { CustomerData } = Customer();
  const { company_token } = CompanyConfig();
  const { cart, setting } = useSelector((state) => state);
  const { total, sub_total } = useSelector((state) => state.product[0]);
  const discount = useSelector((state) => state.discount[0]);
  const [user, setUser] = useState();
  const [key, setKey] = useState("login");
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateId, setStateId] = useState();
  const [shippingData, setShippingData] = useState({});
  const [shipping, setShipping] = useState(0);
  const [allTotal, setAllTotal] = useState(0);
  const [freeGift, setFreeGift] = useState({});

  const navigate = useNavigate();

  let cartData = [];
  let variations = [];

  useEffect(() => {
    // title
    document.title = title;

    let isCancelled = false;
    const fetchUser = () => {
      let parameter = {
        user_uid: CustomerData.user_uid,
        access_token: CustomerData.access_token,
      };
      Auth.getUser(parameter).then((res) => {
        if (res) {
          setUser(res.arrData);
        }
      });
    };

    const fetchFreeGift = () => {
      let parameter = { "prodcuts": [] };
      let getcart = cart;
      for (const cart of getcart) {
        let item = {
          product_uid: cart.id,
          product_qty: cart.quantity
        };
        parameter = {
          ...parameter,
          prodcuts: [...parameter.prodcuts, item],
        };
      }
      Product.getFreeGift(parameter).then((res) => {
        if (res) {
          setFreeGift(res.arrData);
        }
      });
    };

    if (!isCancelled) {
      fetchUser();
      fetchFreeGift();
      setAllTotal(total);
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const fetchShippingCharge = async () => {
      let parameter = {
        amount: total,
        state_uid: stateId
      }
      await Address.getShippingSetting(parameter).then((res) => {
        if (res.success === 1) {
          setShippingData(res.arrData);
          setShipping(parseInt(res.arrData.shipping_amount));
          setAllTotal(total + parseInt(shipping))
        } else {
          setShippingData({});
          setShipping(0);
          setAllTotal(parseInt(total))
        }
      });
    };

    if (!isCancelled) {
      fetchShippingCharge();
    }

    return () => {
      isCancelled = true;
    };
  }, [stateId, shipping, discount, cart])

  const getShippingCharge = (stateUid) => {
    stateUid.target
      ? setStateId(stateUid.target.value)
      : setStateId(stateUid)
  }

  const GetShippingPrice = (price = 0.00) => {
    if (setting != '') {
      if (setting.currency_setting.symbol != '' && setting.currency_setting.display != '') {
        var currency = setting.currency_setting.symbol;
        var display = setting.currency_setting.display;
        if (display == 'before') {
          return currency + ' ' + price;
        } else {
          return price + ' ' + currency;
        }
      }
    }
  }

  let freeGiftUid = [];
  let getgift = Object.values(freeGift);
  for (const freeGift of getgift) {
    freeGiftUid.push(freeGift.free_gift_uid);
  }

  const checkout = useFormik({
    initialValues: {
      choose_address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      note: "",
      payment_method: "",
    },

    validationSchema: Yup.object().shape({
      //** choose address //
      choose_address: Yup.string().when(['address1', 'country', 'state', 'city', 'zipcode'], {
        is: (address1, country, state, city, zipcode) => {
          return ((address1 && country && state && city && zipcode) === undefined);
        },
        then: Yup.string().required(t('checkout.form.choose_address.validation.required')),
      }),

      //** new address //
      country: Yup.string().when(['choose_address'], {
        is: (choose_address) => {
          return (choose_address === undefined);
        },
        then: Yup.string().required(t('checkout.form.country.validation.required')),
      }),
      state: Yup.string().when(['choose_address'], {
        is: (choose_address) => {
          return (choose_address === undefined);
        },
        then: Yup.string().required(t('checkout.form.state.validation.required'))
      }),
      city: Yup.string().when(['choose_address'], {
        is: (choose_address) => {
          return (choose_address === undefined);
        },
        then: Yup.string().required(t('checkout.form.city.validation.required'))
      }),
      zipcode: Yup.number().when(['choose_address'], {
        is: (choose_address) => {
          return (choose_address === undefined);
        },
        then: Yup.number().typeError("That doesn't look like a zipcode number")
          .positive(t('checkout.form.zipcode.validation.positive'))
          .integer(t('checkout.form.zipcode.validation.integer'))
          .required(t('checkout.form.zipcode.validation.required'))
      }),
      address1: Yup.string().when(['choose_address'], {
        is: (choose_address) => {
          return (choose_address === undefined);
        },
        then: Yup.string().required(t('checkout.form.address1.validation.required')),
      }),
      payment_method: Yup.string()
        .required(t('checkout.form.payment_method.validation.required'))
        .oneOf(["online", "cod", "paypal"]),
    }, [
      ['choose_address', 'address1'],
      ['choose_address', 'country'],
      ['choose_address', 'state'],
      ['choose_address', 'city'],
      ['choose_address', 'zipcode'],
    ]),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      let getcart = cart;

      for (const cart of getcart) {
        Object.values(Object.assign({}, cart.variation)).map((variation, ibdex) => {
          variations.push(
            {
              variation_uid: variation.uid,
              variation_options_uid: variation.option_uid,
            }
          )
        })

        let item = {
          product_uid: cart.id,
          quantity: cart.quantity,
          price: cart.price,
          variation: variations,
        };
        cartData.push(item);
      }

      let parameter = {
        company_token: company_token,
        access_token: user.access_token,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        mobile: user.mobile,
        customer_address_uid: values.choose_address ? values.choose_address : "0",
        address1: !values.choose_address ? values.address1 : "",
        address2: !values.choose_address ? values.address2 : "",
        country_uid: !values.choose_address ? values.country : "",
        state_uid: !values.choose_address ? values.state : "",
        city: !values.choose_address ? values.city : "",
        zipcode: !values.choose_address ? values.zipcode : "",
        payment_method: values.payment_method,
        voucher_code: discount ? discount.coupon_code : "",
        voucher_amount: discount ? discount.amount : "",
        total_amount: allTotal,
        total_unit: cartData.length,
        shipping_uid: Object.values(shippingData).length ? shippingData.uid : "",
        shipping_amount: Object.values(shippingData).length ? parseInt(shippingData.shipping_amount) : 0,
        note: values.note,
        free_gift_uid: freeGiftUid,
        order_details: cartData,
      };

      setTimeout(() => {
        Product.getCheckout(parameter).then((res) => {
          if (res.success == 1) {
            localStorage.setItem("order_id", res.order_uid);
            localStorage.removeItem('cart');
            dispatch({ type: "RESET", payload: getcart });
            resetForm();
            navigate("/pay");
            setLoading(false);
          }
        });
      }, 1000);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Auth.isAuthenticate()) {
      checkout.handleSubmit(e);
    } else {
      Toast(t('error_msg.auth_error'), "error");
    }
  }

  console.log(freeGift);
  return (
    <React.Fragment>
      <BreadCrumb title={t('checkout.title')} />
      <div className="rts-checkout-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-8">
              <div className="coupon-area">
                <div className="coupon-ask">
                  <span className="me-3">{t('checkout.have_a_coupon')}</span>
                  <button
                    className="coupon-click"
                    onClick={() => setToggle((prevToggle) => !prevToggle)}
                  >
                    {t('checkout.click_here_to')}
                  </button>
                </div>
                {toggle ? <Coupon cart={cart} /> : ""}
              </div>
              {Auth.isAuthenticate() ? (
                <AddressSection checkout={checkout} getShippingCharge={getShippingCharge} />
              ) : (
                <>
                  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>Please!</strong> login first
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="login" title={t('checkout.tabs.login')}>
                      <div className="py-3">
                        <LoginForm redirect={false} path={"/checkout"} />
                      </div>
                    </Tab>
                    <Tab eventKey="signup" title={t('checkout.tabs.signup')}>
                      <div className="py-3">
                        <SignupForm redirect={false} path={"/checkout"} />
                      </div>
                    </Tab>
                  </Tabs>
                </>
              )}
            </div>

            <div className="col-xl-4">
              <div className="action-item mt-md-0 mt-3">
                <div className="action-top">
                  <span className="action-title">{t('checkout.product')}</span>
                  <span className="subtotal">{t('checkout.subtotal')}</span>
                </div>
                {cart.map((item, index) => {
                  return (
                    <div className="category-item" key={index}>
                      <div className="category-item-inner">
                        <div className="category-title-area">
                          <span className="category-title">
                            {item.title} × {item.quantity}
                          </span>
                        </div>
                        <div className="price">
                          {GetShippingPrice(item.price * item.quantity)}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="action-middle">
                  <span className="subtotal">{t('checkout.subtotal')}</span>
                  <span className="total-price">
                    {GetShippingPrice(sub_total)}
                  </span>
                </div>
                {(freeGift.length > 0) ?
                  <>

                    <div className="shipping-options checkout-options">
                      <span className="shipping">{t('checkout.free_gift')}</span>
                      <div className="action-top" style={{ marginBottom: "unset" }}>
                        <span className="action-title">{t('checkout.products')}</span>
                        <span className="action-title">{t('checkout.quantity')}</span>
                      </div>
                      {Object.values(freeGift).map((item, index) => {
                        return (
                          <div className="category-item" key={index}>
                            <div className="category-item-inner">
                              <div className="category-title-area">
                                <span className="category-title">
                                  {item.name}
                                </span>
                              </div>
                              <div className="price">
                                {item.gift_quantity}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                  :
                  <></>
                }
                <div className="shipping-options checkout-options">
                  <span className="shipping">{t('checkout.shipping')}</span>
                  <form>
                    <div className="form-group">
                      <input type="checkbox" checked id="frsh2" />
                      <label className="check-title" for="frsh2">
                        {
                          shipping > 0
                            ? t('checkout.your_shipping_charge') + ` ${GetShippingPrice(shipping)}`
                            : t('checkout.free_shipping')
                        }
                      </label>
                    </div>
                    <CouponSuccess />
                  </form>
                </div>
                <div className="action-bottom">
                  <span className="total">{t('checkout.total')}</span>
                  <span className="total-price">{GetShippingPrice(allTotal)}</span>

                </div>
              </div>

              <div className="action-item m-0">
                <div className="payment-options checkout-options">
                  <form>
                    <div className="form-group">
                      <input
                        type="radio"
                        id="online"
                        name="payment_method"
                        value="online"
                        onChange={checkout.handleChange}
                      />
                      <label className="check-title" for="online">
                        {t('checkout.online')}
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="radio"
                        id="cod"
                        name="payment_method"
                        value="cod"
                        onChange={checkout.handleChange}
                      />
                      <label className="check-title" for="cod">
                        {t('checkout.cash_on_delivery')}
                      </label>
                    </div>
                    <div className="form-group last-child">
                      <input
                        type="radio"
                        id="paypal"
                        name="payment_method"
                        onChange={checkout.handleChange}
                        value="paypal"
                      />
                      <label className="check-title" for="paypal">
                        {t('checkout.paypal')}
                      </label>
                    </div>
                    {checkout.touched.payment_method &&
                      checkout.errors.payment_method ? (
                      <div className="text-danger">
                        {checkout.errors.payment_method}
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>


              <form
                className="checkout-form mb-5 mb-md-0"
                onSubmit={handleSubmit}
              >
                <button
                  type="submit"
                  className="place-order-btn"
                  disabled={loading || !cart.length ? true : false}
                >
                  {!loading ? t('checkout.place_order') : ""}
                  <i className={`fal ${loading ? "fa-spinner fa-spin" : ""}`} />
                </button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Checkout;
