import React, { useEffect, useState } from "react";
import {
  BreadCrumb,
  FilterCount,
  Pagination,
  ProductDetailsPopup,
  Filter,
  ItemContent,
  Meta
} from "../components";
import { useSearchParams, useNavigate } from "react-router-dom";
import CompanyConfig from "../components/CompanyConfig";

//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { useTranslation } from 'react-i18next';

import queryString from 'query-string';

export default function Products({ title }) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { company_token } = CompanyConfig();
  const params = {};

  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({});
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [brand, setBrand] = useState([]);
  const [total, setTotal] = useState(0);
  const search = searchParams.get("search") ? searchParams.get("search") : "";
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );

  const [values, setValues] = useState({
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
    category: [],
    brands: [],
    attributes: [],
    pricemin: 0,
    pricemax: 0,
  });

  const setPageNumber = (pageNumber) => {
    fetchProducts(pageNumber);
  };

  useEffect(() => {
    if (search != "") {
      params.search = search;
    }
    if (page != 1) {
      params.page = page;
    }
    if (values.order_by !== "") {
      params.order_by = values.order_by;
    }
    if (values.category.length) {
      params.category = JSON.parse(JSON.stringify(values.category));
    }
    if (values.brands.length) {
      params.brands = JSON.parse(JSON.stringify(values.brands));
    }
    if (values.attributes.length) {
      params.attributes = JSON.parse(JSON.stringify(values.attributes));
    }
    if (values.pricemax > 0) {
      params.price = values.pricemin + "-" + values.pricemax;
    }

    navigate({
      pathname: '/products',
      search: `?${queryString.stringify(params, { arrayFormat: 'comma', sort: false })}`,
    });

    fetchProducts(page);
  }, [values, page, search]);

  useEffect(() => {

    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      if (param == 'category') {
        let catArr = value.split(",");
        catArr.filter((category) => {
          if (!values.category.includes(category)) {
            setValues(prevObj => ({
              ...prevObj,
              category: [...prevObj.category, category]
            }));
          }
        })
      }
      if (param == 'brands') {
        let brandArr = value.split(",");
        brandArr.filter((brand) => {
          if (!values.brands.includes(brand)) {
            setValues(prevObj => ({
              ...prevObj,
              brands: [...prevObj.brands, brand]
            }));
          }
        })
      }
      if (param == 'attributes') {
        let AttributesArr = value.split(",");
        AttributesArr.filter((attribute) => {
          if (!values.attributes.includes(attribute)) {
            setValues(prevObj => ({
              ...prevObj,
              attributes: [...prevObj.attributes, attribute]
            }));
          }
        })
      }
      if (param == 'price') {
        let str = value.replace(/-/g, ",");
        let arr = str.split(",");
        setValues(prevObj => ({
          ...prevObj,
          pricemin: parseInt(arr[0]),
          pricemax: parseInt(arr[1]),
        }));
      }
    }
    fetchProducts(page);
  }, [searchParams]);


  const fetchProducts = async (page) => {
    setLoading(true);
    let Parameter = {
      company_token: company_token,
      keyword: search,
      categories: values.category,
      brands: values.brands,
      attributes: values.attributes,
      price: {
        min: values.pricemin,
        max: values.pricemax,
      },
      sorting: {
        by: "price",
        order: values.order_by,
      },
      pagination: {
        per_page: 3,
        page: page,
      },
    };
    try {
      await api.post(Apis.ProductList, Parameter).then((res) => {
        if (res.data.success === 1) {
          setPage(page);
          setProduct(res.data.arrData);
          setAttributes(res.data.attributes);
          setBrand(res.data.brands);
          setTotal(res.data.total_count);
          setLoading(false);
        } else {
          setProduct([]);
          setBrand([]);
          setLoading(false);
        }
      });
    } catch (error) {
      if (error.request.status !== 200) {
        return navigate("/404");
      }
      console.error(error.message);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "category") {
      const { value, checked } = e.target;
      let cat_slug_array = value.split(',');
      if (checked) {
        setValues({ ...values, category: cat_slug_array });
      } else {
        setValues({ ...values, category: values.category });
      }
    } else if (e.target.name === "brands") {
      const { value, checked } = e.target;
      if (checked) {
        values.brands.push(e.target.value);
        setValues({ ...values, [e.target.name]: values.brands });
      } else {
        setValues({
          ...values,
          [e.target.name]: values.brands.filter((e) => e !== value),
        });
      }
    } else if (e.target.name === "attributes") {
      const { value, checked } = e.target;
      if (checked) {
        values.attributes.push(e.target.value);
        setValues({ ...values, [e.target.name]: values.attributes });
      } else {
        setValues({
          ...values,
          [e.target.name]: values.attributes.filter((e) => e !== value),
        });
      }
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const handleClick = (value) => {
    if (value.type === "price_slider") {
      if (values.pricemin !== value.pricemin) {
        setValues({ ...values, pricemin: value.pricemin });
      } else if (values.pricemax !== value.pricemax) {
        setValues({ ...values, pricemax: value.pricemax });
      } else {
        setValues({ ...values, pricemin: 0 });
        setValues({ ...values, pricemax: 0 });
      }
    }
    if (value.type === "category") {
      let cat_slug_array = value.slug.split(',');
      setValues({ ...values, category: cat_slug_array });
    }
    if (value.type === "attributes") {
      let attribute_slug_array = value.slug.split(',');
      setValues({ ...values, attributes: attribute_slug_array });
    }
  }

  return (
    <React.Fragment>
      <Meta
        type="prodcuts"
      />
      <BreadCrumb title={t('products.title')} />
      <div className="rts-shop-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-9">
              <FilterCount
                total={total}
                onChange={onChange}
                defaultValue={values.order_by}
              />
              <ItemContent
                loading={loading}
                product={product}
                setModel={setModel}
              />
              {product.length > 0 ? (
                <Pagination
                  page={page}
                  perPage={4}
                  total={total}
                  pageNumber={setPageNumber}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="col-xl-3">
              <Filter handleClick={handleClick} values={values} onChange={onChange} brands={brand} attributes={attributes} />
            </div>
          </div>
        </div>
      </div>
      <ProductDetailsPopup data={model} />
    </React.Fragment>
  );
}
