import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom';
import { Auth } from "../components/services";

export default function Protected(props) {
  const navigate = useNavigate();
  const { Component, title } = props

  useEffect(() => {
    if (!Auth.isAuthenticate()) {
      navigate("/login")
    }
  }, [])

  return (
    <Component title={title} />
  )
}
