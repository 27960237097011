import React from 'react'
import { Link } from 'react-router-dom';
import { GetLogo } from "../../Helpers";

import {
  Menu,
  CartIcon,
  UserIcon,
  WishlistIcon,
  SearchBoxIcon,
} from "../../../components";

export default function MainMenu({ categories, brand, onChange, setToggle }) {
  return (
    <div className="navbar-sticky">
      <div className="container">
        <div className="navbar-part navbar-part1">
          <div className="navbar-inner">
            <div className="left-side">
              <div className="hamburger-1" onClick={() => setToggle((toggle) => !toggle)}>
                <Link to="#" className="nav-menu-link">
                  <span className="dot1" />
                  <span className="dot2" />
                  <span className="dot3" />
                  <span className="dot4" />
                  <span className="dot5" />
                  <span className="dot6" />
                  <span className="dot7" />
                  <span className="dot8" />
                  <span className="dot9" />
                </Link>
              </div>
              <Link to="/" className="logo">
                <img src={GetLogo()} alt="weiboo-logo" width={'212px'} height={'22px'} />
              </Link>
            </div>
            <div className="rts-menu">
              <nav className="menus menu-toggle">
                <ul className="nav__menu">
                  <Menu categories={categories} brand={brand} onChange={onChange} />
                </ul>
              </nav>
            </div>
            <div className="responsive-hamburger">
              <div className="hamburger-1" onClick={() => setToggle((toggle) => !toggle)}>
                <Link to="#" className="nav-menu-link">
                  <span className="dot1" />
                  <span className="dot2" />
                  <span className="dot3" />
                  <span className="dot4" />
                  <span className="dot5" />
                  <span className="dot6" />
                  <span className="dot7" />
                  <span className="dot8" />
                  <span className="dot9" />
                </Link>
              </div>
            </div>
            <div className="header-action-items header-action-items1">
              <SearchBoxIcon />
              <UserIcon />
              <WishlistIcon />
              <CartIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
