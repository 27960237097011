import React from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Product } from '../services';
const CartProduct = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cartHandler = async (props) => {
        let ProductStock = await Product.getProductStockCheck(props.id, props.quantity);
        if (ProductStock) {
            return;
        }
    }
    return (
        <>
            <tr>
                <td><div className="product-thumb" style={{ width: 100 }}><img src={props.image} alt="product-thumb" /></div></td>
                <td>
                    <div className="product-title-area mx-3">
                        <span className="pretitle">{props.category} / {props.brand}</span>
                        <h4 className="product-title">{props.title}</h4>
                    </div>
                </td>
                <td><span className="product-price mx-3">{props.price}</span></td>
                <td>
                    <div className="cart-edit mx-3">
                        <div className="quantity-edit">
                            <button className="button" onClick={() => {
                                {
                                    cartHandler(props);
                                    if (props.quantity > 1) {
                                        dispatch({ type: "TEMP_ITEMS_DECREASE", payload: props });
                                    } else {
                                        dispatch({ type: "TEMP_ITEMS_REMOVE", payload: props });
                                    }
                                }
                            }}
                            ><i className="fal fa-minus minus"></i></button>
                            <input type="text" className="input" value={props.quantity} />
                            <button className="button plus"
                                onClick={() => { cartHandler(props); dispatch({ type: "TEMP_ITEMS_INCREASE", payload: props }) }}>+<i className="fal fa-plus plus"></i></button>
                        </div>
                    </div>
                </td>
                <td className="last-td"><button className="remove-btn" onClick={() => { dispatch({ type: "TEMP_ITEMS_REMOVE", payload: props }); props.cartClearHandler(); }}>{t('cart.remove')}</button></td>
            </tr>
        </>
    )
}

export default CartProduct;