import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Preload({ type = "spinner", count = 1 }) {
  const COUNT = count;
  const TableFeedSkeleton = () => (
    <div style={{ width: "100%" }}>
      <div className='mb-5'>
        <span className="d-block first-table-feed" style={{ width: "100%" }}>
          <Skeleton
            height={40}
            width={500}
            style={{ width: "100%" }}
          />
        </span>
        <span className="d-block second-table-feed">
          <Skeleton
            height={30}
            width={400}
          />
        </span>
        <span className="d-block third-table-feed">
          <Skeleton
            height={20}
            width={300}
          />
        </span>
      </div>
    </div>
  )
  const SpinnerLoader = () => (
    <div className='d-flex justify-content-center align-item-center p-5'>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
  const ItemSkeleton = () => (
    <div style={{ marginRight: "10px" }}>
      <span className='d-block'>
        <Skeleton width={315} height={440} />
      </span>
      <span className='d-block'>

        <Skeleton width={200} height={15} />
      </span>
      <span className='d-block'>

        <Skeleton width={60} height={15} />
      </span>
    </div>
  )
  const AddressSkeleton = () => (
    <div style={{ width: "100%", margin: "10px 0" }}>
      <span className='d-block'>
        <Skeleton width={200} height={15} />
      </span>
      <span className='d-block'>
        <Skeleton width={150} height={15} />
      </span>
      <span className='d-block'>
        <Skeleton width={100} height={15} />
      </span>
    </div>
  )
  const UserDashboardIcon = () => (
    <div style={{ width: "auto", margin: "10px 0" }}>
      <span className='d-block text-center mb-2'>
        <Skeleton width={50} height={50} />
      </span>
      <span className='d-block text-center'>
        <Skeleton width={100} height={15} />
      </span>
      <span className='d-block text-center'>
        <Skeleton width={100} height={15} />
      </span>
    </div>
  )
  const UserProfile = () => (
    <div style={{ width: "auto", margin: "10px 0" }}>
      <div className='d-flex align-items-center mb-3'>
        <span className='d-block me-2'>
          <Skeleton circle={true} width={50} height={50} />
        </span>
        <div>
          <span className='d-block'>
            <Skeleton width={150} height={15} />
          </span>
          <span className='d-block'>
            <Skeleton width={100} height={15} />
          </span>
          <span className='d-block'>
            <Skeleton width={50} height={15} />
          </span>
        </div>
      </div>
      <span className='d-block custom-feed' style={{ width: "auto" }}>
        <Skeleton width={500} height={15} />
      </span>
    </div>
  )
  const BlogProfile = () => (
    <div style={{ width: "auto", margin: "10px 0" }}>
      <div className='d-flex align-items-center mb-3'>
        <span className='d-block me-2'>
          <Skeleton circle={true} width={48} height={48} />
        </span>
        <div>
          <span className='d-block'>
            <Skeleton width={150} height={15} />
          </span>
          <span className='d-block'>
            <Skeleton width={100} height={15} />
          </span>
        </div>
      </div>
    </div>
  )
  const FeedLine = () => (
    <div style={{ marginRight: "10px", marginTop: "0", width: "100%" }}>
      <span className='d-block'>
        <Skeleton width={100} height={15} />
      </span>
      <span className='d-block'>
        <Skeleton width={60} height={15} />
      </span>
    </div>
  )
  if (type === "feed") return <TableFeedSkeleton />
  if (type === "item") return <ItemSkeleton />
  if (type === "spinner") return <SpinnerLoader />
  if (type === "address") return <AddressSkeleton />
  if (type === "dashboardIcon") return <UserDashboardIcon />
  if (type === "userProfile") return <UserProfile />
  if (type === "feedLine") return <FeedLine />
  if (type === "blogProfile") return <BlogProfile />
}
