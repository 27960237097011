import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Blog } from '../services';
import { useTranslation } from 'react-i18next';

export default function Categories() {
  const { t } = useTranslation();
  const [blogCategory, setBlogCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    let isCancelled = false;
    const fetchBlog = async () => {
      setLoading(true);
      await Blog.getCategory().then((res) => {
        if (res) {
          setBlogCategory(res.arrData);
          setLoading(false);
        }
      })
    }
    if (!isCancelled) {
      fetchBlog();
    }
    return () => {
      isCancelled = true;
    }
  }, [])

  return (
    <div className="widget widget-categories-list mb-40">
      <div className="widget-title-box pb-25 mb-30">
        <h4 className="widget-sub-title2 fs-20">{t('blogs.categories')}</h4>
      </div>
      {
        blogCategory.length ?
          <ul className="list-none">
            {
              blogCategory.map(({ category_name, category_slug,count_blogs }, index) => (
                <li key={index}>
                  <Link name={"category"} category_slug={category_slug} to={'/blogs/category/'+category_slug}>
                    {category_name} <span className="f-right"> {count_blogs} </span>
                  </Link>
                </li>
              ))
            }
          </ul>
          : "Not Found"
      }
    </div>
  )
}
