import React, { useState, useEffect } from "react";
const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="top-to-btm">
      {" "}{showTopBtn && (
        <div className="scroll-top-btn scroll-top-btn1 jumpTop" onClick={goToTop}>
          <i className="fas fa-angle-up arrow-up"></i>
          <i className="fas fa-circle-notch"></i>
        </div>
      )}{" "}
    </div>
  );
};
export default ScrollToTop;