import React, { useState } from 'react'
import { Toast } from '../../components'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CompanyConfig from '../../components/CompanyConfig';
import { Pages } from '../../components/services';
import { useTranslation } from 'react-i18next';
export default function ContactForm() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { company_token } = CompanyConfig();
  const contact = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      name:
        Yup.string()
          .max(15, t('contact.form.name.validation.max'))
          .matches(/^[aA-zZ\s]+$/, t('contact.form.name.validation.matches'))
          .required(t('contact.form.name.validation.required')),
      email:
        Yup.string()
          .email(t('contact.form.email.validation.email'))
          .required(t('contact.form.email.validation.required')),
      mobile:
        Yup.number()
          .typeError(t('contact.form.mobile.validation.typeError'))
          .positive(t('contact.form.mobile.validation.positive'))
          .integer(t('contact.form.mobile.validation.integer'))
          .min(10)
          .required(t('contact.form.mobile.validation.required')),
      subject:
        Yup.string()
          .required(t('contact.form.subject.validation.required')),
      message:
        Yup.string()
          .required(t('contact.form.message.validation.required')),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.company_token = company_token;
      Pages.CreateContactUs(values).then((res) => {
        if (res) {
          setTimeout(() => {
            resetForm();
            Toast(t('contact.form.response_success_msg'), "success");
            setLoading(false);
          }, 1000)
        }
        setLoading(false);
      })
    },
  });

  return (
    <form className="contact-form mb-10" onSubmit={contact.handleSubmit}>
      <div className="info-form">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="input-box mb-20">
              <input
                type="text"
                placeholder={t('contact.form.name.placeholder')}
                name="name"
                onChange={contact.handleChange}
                onBlur={contact.handleBlur}
                value={contact.values.name}
              />
              {contact.touched.name && contact.errors.name ? (
                <div className='text-danger'>{contact.errors.name}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="input-box mail-input mb-20">
              <input
                type="email"
                name="email"
                placeholder={t('contact.form.email.placeholder')}
                onChange={contact.handleChange}
                onBlur={contact.handleBlur}
                value={contact.values.email}
              />
              {contact.touched.email && contact.errors.email ? (
                <div className='text-danger'>{contact.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="input-box number-input mb-30">
              <input
                type="text"
                name="mobile"
                placeholder={t('contact.form.mobile.placeholder')}
                onChange={contact.handleChange}
                onBlur={contact.handleBlur}
                value={contact.values.mobile}
              />
              {contact.touched.mobile && contact.errors.mobile ? (
                <div className='text-danger'>{contact.errors.mobile}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="input-box sub-input mb-30">
              <input
                type="text"
                name="subject"
                placeholder={t('contact.form.subject.placeholder')}
                onChange={contact.handleChange}
                onBlur={contact.handleBlur}
                value={contact.values.subject}
              />
              {contact.touched.subject && contact.errors.subject ? (
                <div className='text-danger'>{contact.errors.subject}</div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-12 col-sm-12">
            <div className="input-box text-input mb-20">
              <textarea
                name="message"
                cols={30}
                rows={10}
                placeholder={t('contact.form.message.placeholder')}
                defaultValue={""}
                onChange={contact.handleChange}
                onBlur={contact.handleBlur}
                value={contact.values.message}
              />
              {contact.touched.message && contact.errors.message ? (
                <div className='text-danger'>{contact.errors.message}</div>
              ) : null}
            </div>
          </div>
          <div className="col-12 mb-15">
            <button type="submit" className="form-btn form-btn4" disabled={loading ? true : false} >{loading ? t('contact.form.button_loading') : t('contact.form.button_get_a_quote')}
              <i className={`fal ${loading ? "fa-spinner fa-spin" : "fa-long-arrow-right"}`} /></button>
          </div>
        </div>
      </div>
    </form>
  )
}
