import React from 'react'
import Item from './Item';
import { ItemSlider } from './Slider';

export default function FeaturedItemsList(props) {
  return (
    <React.Fragment>
      <div className="rts-hand-picked-products-section section-gap">
        <div className="container">
          <div className="section-header section-header3 text-center">
            <div className="wrapper">
              <div className="sub-content">
                <img
                  className="line-1 lazyload"
                  data-src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/banner/` + props.icon}
                  alt={props.title}
                  width={'22px'}
                  height={'6px'}
                />
                <span className="sub-text">{props.sub_title}</span>
                <img
                  className="line-2 lazyload"
                  data-src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/banner/` + props.icon}
                  alt={props.title}
                  width={'22px'}
                  height={'6px'}
                />
              </div>
              <h2 className="title">{props.title}</h2>
            </div>
          </div>

          <div className="slider-div">
            <div className="swiper rts-sixSlide-over">
              <ItemSlider>
                {props.Objectdata.map((item, index) => {
                  return (
                    <div key={++index}>
                      <Item
                        brand={item.Brand}
                        category={item.Category}
                        product_description={item.product_description}
                        product_images={item.product_images}
                        product_image={item.product_image}
                        product_name={item.product_name}
                        product_price={item.product_price}
                        product_quantity={item.product_quantity}
                        product_ref_price={item.product_ref_price}
                        product_sku={item.product_sku}
                        product_stock_out={item.product_stock_out}
                        product_uid={item.product_uid}
                        slug={item.slug}
                        setModel={props.setModel}
                        variation={item.variation}
                      />
                    </div>
                  );
                })}
              </ItemSlider>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
