import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TempCartProduct from "./Orders/TempCartProduct";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Product } from "./services";
export default function CustomPopUp({ cartClearHandler, modelLoading, Currency }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tempitems } = useSelector((state) => state);
  const navigate = useNavigate();

  const cartHandler = async () => {
    tempitems.map((item) => {
      Product.getProductStockCheck(item.id, item.quantity).then((res) => {
        if (res) {
          dispatch({ type: "ADD", payload: item });
        }
      })
    })
    navigate("/checkout");
  };

  return (
    <React.Fragment>
      <div className="custom-popup order_list product-details-popup-wrapper w-100">
        <div className="rts-product-details-sections rts-product-details-section2 product-details-popup-section">
          <div className="product-popup-wrapper">
            <div className="product-details-popup">
              <div className="details-product-area">
                <button className="product-details-close-btn">
                  <i className="fal fa-times" />
                </button>
                <div className="rts-cart-section">
                  <div className="container">
                    <div className="cart-table-area">
                      <table className="table table-bordered table-hover">
                        <thead className="thead-dark" />
                        <tbody>
                          {(tempitems.length > 0) ?
                            tempitems.map((item, index) => {
                              return (
                                <TempCartProduct
                                  cartClearHandler={cartClearHandler}
                                  image={item.image}
                                  category={item.category}
                                  brand={item.brand}
                                  title={item.title}
                                  price={Currency + item.price}
                                  quantity={item.quantity}
                                  id={item.id}
                                  dispatch={dispatch}
                                  key={index}
                                />
                              );
                            })
                            :
                            <>
                            </>
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end align-items-center btn-sticky">
                      <button type="submit" className="theme-custom-link btn btn-2" onClick={cartHandler}>{t('add_to_cart')}<i className="fal"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
