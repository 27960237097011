import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Preload } from "../../components"
import { Customer } from "../../components/CustomerConfig";
import { Address } from "../../components/services";
import { useTranslation } from 'react-i18next';

export default function ChangeAddress({ checkout, getShippingCharge }) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);
  const { CustomerData } = Customer();
  const [addressList, setAddressList] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      fetchAddressList();
    }
    return () => {
      isCancelled = true;
    };
  }, []);


  const fetchAddressList = () => {
    let parameter = {
      access_token: CustomerData.access_token,
    };
    setLoading(true);
    Address.getAddressList(parameter).then((res) => {
      if (res) {
        setAddressList(res.arrData);
        res.arrData.filter((address) => {
          if (parseInt(address.is_default_billing) === 1) {
            setBillingAddress(address);
          }
          if (parseInt(address.is_default_shipping) === 1) {
            setShippingAddress(address);
          }
        });
        setLoading(false);
      }
    });
  };

  return (
    <div className="ps-md-3 ps-0">
      <button className="toggle_btn ps-md-3 ps-0" onClick={() => { setToggle(!toggle); checkout.resetForm(); getShippingCharge(0) }}>
        <i className="fa fa-plus me-1" aria-hidden="true"></i>
        {t('checkout.change_address.change_address')}
      </button>
      {toggle ? (
        <div
          className="change-address-wrapper p-3"
        >
          <h5 className="mb-3">{t('checkout.change_address.shipping_address')}</h5>
          {loading ? <Preload type="address" /> : Object.values(shippingAddress).length ? (
            <div className="d-flex align-items-center mb-3">
              <input
                type="radio"
                name="choose_address"
                className="choose_address"
                value={shippingAddress.uid}
                onChange={(e) => { checkout.handleChange(e); getShippingCharge(shippingAddress.state_uid) }}
              />
              <div className="ms-3">
                <p>{shippingAddress.address1} {shippingAddress.address2},</p>
                <p>
                  {shippingAddress.city} {shippingAddress.state},
                </p>
                <p>
                  {shippingAddress.country} {shippingAddress.zipcode}.
                </p>
              </div>
            </div>
          ) : <div className="address-not-found mb-3">
            <p className="bg-light p-3">
            {t('checkout.change_address.not_found_address')}
              <Link to={"/account/address/create"} className="address-not-found-link ms-3">{t('checkout.change_address.click_hare_to_add_address')}</Link>
            </p>
          </div>}
          <h5 className="mb-3">{t('checkout.change_address.billing_address')}</h5>
          {loading ? <Preload type="address" /> : Object.values(billingAddress).length ? (
            <div className="d-flex align-items-center mb-3">
              <input
                type="radio"
                name="choose_address"
                className="choose_address"
                value={billingAddress.uid}
                onChange={(e) => { checkout.handleChange(e); getShippingCharge(billingAddress.state_uid) }}
              />
              <div className="ms-3">
                <p>{billingAddress.address1} {billingAddress.address2},</p>
                <p>
                  {billingAddress.city} {billingAddress.state},
                </p>
                <p>
                  {billingAddress.country} {billingAddress.zipcode}.
                </p>
              </div>
            </div>
          ) : <div className="address-not-found">
            <p className="bg-light p-3">
            {t('checkout.change_address.not_found_address')}
              <Link to={"/account/address/create"} className="address-not-found-link ms-3">{t('checkout.change_address.click_hare_to_add_address')}</Link>
            </p>
          </div>}
          {checkout.touched.choose_address && checkout.errors.choose_address ? (
            <div className="text-danger">{checkout.errors.choose_address}</div>
          ) : null}
        </div>
      ) : (
        <div className="ps-md-3 ps-0">
          {loading ? <Preload type="address" /> : addressList.length ? addressList.map(
            (
              { uid, address1, address2, country, state, city, zipcode, state_uid },
              index
            ) => (
              <div className="d-flex align-items-center mb-3" key={index}>
                <input
                  type="radio"
                  name="choose_address"
                  className="choose_address"
                  value={uid}
                  onChange={(e) => { checkout.handleChange(e); getShippingCharge(state_uid) }}
                />
                <div className="ms-3">
                  <p>{address1} {address2},</p>
                  <p>
                    {city} {state},
                  </p>
                  <p>
                    {country} {zipcode}.
                  </p>
                </div>
              </div>
            )
          ) : <div className="address-not-found">
            <p className="bg-light p-3">
            {t('checkout.change_address.not_found_address')}
              <Link to={"/account/address/create"} className="address-not-found-link ms-3">{t('checkout.change_address.click_hare_to_add_address')}</Link>
            </p>
          </div>
          }
          {checkout.touched.choose_address && checkout.errors.choose_address ? (
            <div className="text-danger">{checkout.errors.choose_address}</div>
          ) : null}
        </div>
      )}
    </div>
  );
}
