import React from 'react';
import moment from "moment";
import { Link } from "react-router-dom";

export default function PopularBlogsCard(props) {
  return (
    <div className="blog-post mb-30">
      <Link to={'/blogs/'+props.slug}>
        <img className="lazyload" data-src={props.image} width={'130px'} alt="Post Img" />
      </Link>
      <div className="post-content">
        <h6 className="mb-10">
          <Link to={'/blogs/'+props.slug} >
           {props.title}
          </Link>
        </h6>
        <span className="fs-14">
          <i className="fal fa-calendar-alt" /> {moment(props.date).format("DD MMMM YYYY")}
        </span>
      </div>
    </div>
  )
}
