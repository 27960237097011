import React from 'react'
import { Link } from "react-router-dom"
import { CartIcon, UserIcon, WishlistIcon, SearchBoxIcon, MobileMenu } from "../../../components";
import SocialList from '../../General/SocialList';
import { GetLogo } from "../../Helpers";
import { useTranslation } from 'react-i18next';

export default function SideBar(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <aside className={`slide-bar ${props.toggle ? "show" : ""}`}>
        <div className="offset-sidebar">
          <Link className="hamburger-1 mobile-hamburger-1 ml--30" to="#" onClick={() => props.setToggle((toggle) => !toggle)}>
            <span>
              <i className="rt-xmark" />
            </span>
          </Link>
        </div>
        {/* offset-sidebar start */}
        <div className="offset-sidebar-main">
          <div className="offset-widget mb-40">
            <div className="info-widget">
              <img data-src={GetLogo()} alt="Ecommerce" className="lazyload"/>
              <p className="mb-30">
                {t('header.sidebar.top_text')}
              </p>
            </div>
            <div className="info-widget info-widget2">
              <h4 className="offset-title mb-20">{t('header.sidebar.get_in_touch')} </h4>
              <ul>
                <li className="info phone">
                  <a href="tel:78090790890">
                    780 907 908 90, 208 806 803
                  </a>
                </li>
                <li className="info email">
                  <a href="mailto:info@webmail.com">info@webmail.com</a>
                </li>
                <li className="info web">
                  <Link to={t('header.sidebar.web_link')}>{t('header.sidebar.web_text')}</Link>
                </li>
                <li className="info location">{t('header.sidebar.address')}</li>
              </ul>
              <div className="offset-social-link">
                <h4 className="offset-title mb-20">{t('header.sidebar.follow_us')} </h4>
                <SocialList type="square" />
              </div>
            </div>
          </div>
        </div>
        {/* offset-sidebar end */}
        {/* side-mobile-menu start */}
        <nav className="side-mobile-menu side-mobile-menu1">
          <ul id="mobile-menu-active">
            <MobileMenu categories={props.categories} brand={props.brand} onChange={props.onChange} />
          </ul>
        </nav>
        <div className="header-action-items header-action-items1 header-action-items-side">
          <SearchBoxIcon />
          <WishlistIcon />
          <CartIcon />
          <UserIcon />
        </div>
        {/* side-mobile-menu end */}
      </aside>
    </React.Fragment>
  )
}
