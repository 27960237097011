import React, { useEffect, useState } from "react";

import { AppendScript } from "./components/AppendScript";
import { RemoveScript } from "./components/RemoveScript";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AcceptPayment from "./stripe/AcceptPayment";
import StripeResponse from "./stripe/StripeResponse";

import { Setting } from "./components/services";

import { useDispatch } from "react-redux";
import 'lazysizes';
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

// ** Router dom
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";

//** common
import {
  Header,
  Footer,
  Preloader,
  NotFoundPage,
  BlogDetails,
} from "./components";
//** pages
import {
  Home,
  Contact,
  ProductDetails,
  Products,
  Cart,
  CategoryProducts,
  BrandProducts,
  About,
  Login,
  Account,
  Checkout,
  Wishlist,
  PaymentCancel,
  PaymentSuccess,
  OrderView,
  OrderItemReturn,
  OrderHistory,
  OrderCancel,
  ForgotPassword,
  ForgotPasswordVerify,
  Blogs,
  Faq,
  PrivacyPolicy,
  TermsOfUse,
  ListAddress,
  AddAddress,
} from "./pages";

import Protected from "./components/Protected";
import ScrollToTop from "./components/General/ScrollTop";
import { useTranslation } from "react-i18next";
function BasicLayout() {
  const dispatch = useDispatch();
  let { pathname } = useLocation();

  useEffect(() => {
    let isCancelled = false;
    const loadData = () => {
      RemoveScript(`${process.env.REACT_APP_URL}/assets/dist/js/bundle.min.js`);
      AppendScript(`${process.env.REACT_APP_URL}/assets/dist/js/bundle.min.js`);
    };
    const fetchSetting = async () => {
      await Setting.getSetting().then((res) => {
        dispatch({ type: "ADD_SETTING", payload: res.arrData });
      });
    };
    if (!isCancelled) {
      loadData();
      fetchSetting();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [pathname]);

  return (
    <React.Fragment>
      <Header />
      <Outlet />
      <Footer />
      <ToastContainer position="top-center" autoClose={3000} />
      <ScrollToTop />
    </React.Fragment>
  );
}

function App() {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    // preloader
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    // preloader
    const timer = setTimeout(() => {
      if (isLoaded) {
        setIsPageLoaded(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [isLoaded]);
  return (
    <React.Fragment>
      <BrowserRouter>
        {!isPageLoaded ? <Preloader /> : ""}
        <Routes>
          {/*//*** ===== layout ===== ***/}
          <Route path="/" element={<BasicLayout />}>
            <Route
              path="/"
              element={
                <Home
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.home")
                  }
                />
              }
            />
            <Route
              path="home"
              element={
                <Home
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.home")
                  }
                />
              }
            />
            <Route
              path="cart"
              element={
                <Cart
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.cart")
                  }
                />
              }
            />
            <Route
              path="products"
              element={
                <Products
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.products")
                  }
                />
              }
            >
              <Route path="*" element={<Products />} />
            </Route>
            <Route
              path="categories/:slug"
              element={
                <CategoryProducts
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.categories")
                  }
                />
              }
            />
            <Route
              path="brands/:slug"
              element={
                <BrandProducts
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.brands")
                  }
                />
              }
            />
            <Route
              path="product/:slug"
              element={
                <ProductDetails title={`${process.env.REACT_APP_TITLE}`} />
              }
            />
            <Route
              path="checkout"
              element={
                <Checkout
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.checkout")
                  }
                />
              }
            />
            <Route
              path="login"
              element={
                <Login
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.login")
                  }
                />
              }
            />
            <Route
              path="contact"
              element={
                <Contact
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.contact")
                  }
                />
              }
            />
            <Route
              path="about"
              element={
                <About
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.about")
                  }
                />
              }
            />
            <Route
              path="account"
              element={
                <Protected
                  Component={Account}
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.account")
                  }
                />
              }
            />
            <Route path="account/orders" element={<OrderHistory />} />
            <Route
              path="account/orders/view/:orderId"
              element={<OrderView />}
            />
            <Route
              path="account/orders/item/return/:orderId"
              element={<OrderItemReturn />}
            />
            <Route
              path="account/orders/cancel/:orderId"
              element={<OrderCancel />}
            />
            <Route
              path="wishlist"
              element={
                <Wishlist
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.wishlist")
                  }
                />
              }
            />
            <Route
              path="thank-you"
              element={
                <Protected
                  Component={PaymentSuccess}
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.order_success")
                  }
                />
              }
            />
            <Route
              path="cancel"
              element={
                <Protected
                  Component={PaymentCancel}
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.payment_fail")
                  }
                />
              }
            />
            <Route
              path="forgot-password"
              element={
                <ForgotPassword
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.forgot_password")
                  }
                />
              }
            />
            <Route
              path="forgot-password-verify"
              element={
                <ForgotPasswordVerify
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.forgot_password_verify")
                  }
                />
              }
            />
            <Route
              path="blogs"
              element={
                <Blogs
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.blog")
                  }
                />
              }
            />
            <Route
              path="blogs/category/:category"
              element={
                <Blogs
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.blog")
                  }
                />
              }
            />
            <Route
              path="blogs/tag/:tag"
              element={
                <Blogs
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.blog")
                  }
                />
              }
            />
            <Route
              path="blogs/:slug"
              element={
                <BlogDetails
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.blog_details")
                  }
                />
              }
            />
            <Route
              path="faq"
              element={
                <Faq
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.faq")
                  }
                />
              }
            />
            <Route path="account/address" element={<ListAddress />} />
            <Route path="account/address/create" element={<AddAddress />} />
            <Route
              path="account/address/edit/:addressId"
              element={<AddAddress />}
            />
            <Route
              path="privacy-policy"
              element={
                <PrivacyPolicy
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.privacy_policy")
                  }
                />
              }
            />
            <Route
              path="terms-of-use"
              element={
                <TermsOfUse
                  title={
                    `${process.env.REACT_APP_TITLE} - ` +
                    t("default_page_title.terms_of_use")
                  }
                />
              }
            />
          </Route>
          {/*//*** ===== end:layout ===== */}
          {/*//*** ===== 404 ===== */}
          <Route path="pay" element={<AcceptPayment />} />
          <Route path="stripe-response" element={<StripeResponse />} />
          <Route
            path="*"
            element={
              <NotFoundPage
                title={
                  `${process.env.REACT_APP_TITLE} - ` +
                  t("default_page_title.404")
                }
              />
            }
          />
          {/*//*** ===== end:404 ===== */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
