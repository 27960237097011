import React, { useState, useEffect } from 'react';
import { BreadCrumb, FilterCount, Pagination, Meta, ProductDetailsPopup, ItemContent } from '../components';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import CompanyConfig from '../components/CompanyConfig'

//** Api
import api from "../components/ApiConfig";
import { Apis } from "../config";
import { useTranslation } from 'react-i18next';

const CategoryProducts = ({ title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = {};

  const { slug } = useParams();
  const [loading, setLoading] = useState(true);

  const { company_token } = CompanyConfig();
  const [model, setModel] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState((searchParams.get('page')) ? searchParams.get('page') : 1);
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState({});

  const [values, setValues] = useState({
    order_by: (searchParams.get('order_by')) ? searchParams.get('order_by') : '',
    pricemin: 0,
    pricemax: 0,
    brands: [],
  });

  const setPageNumber = (pageNumber) => {
    fetchData(pageNumber);
  }

  useEffect(() => {
    if (page != 1) {
      params.page = page;
    }
    if (values.order_by !== "") {
      params.order_by = values.order_by;
    }
    if (values.pricemax > 0) {
      params.price = values.pricemin + "-" + values.pricemax;
    }
    setSearchParams(params);
    fetchData(page);
  }, [values, page]);

  useEffect(() => {
    fetchData(page);
  }, [slug]);

  useEffect(() => {
    if (searchParams.get("price")) {
      let str = searchParams.get("price").replace(/-/g, ",");
      let arr = str.split(",");
      values.pricemin = parseInt(arr[0]);
      values.pricemax = parseInt(arr[1]);
    }
    fetchData(page);
  }, [searchParams]);

  const fetchData = async (page = 1) => {
    setLoading(true);

    let Parameter = {
      "company_token": company_token,
      "keyword": "",
      "categories": [slug],
      "brands": values.brands,
      "price": {
        "min": values.pricemin,
        "max": values.pricemax,
      },
      "sorting": {
        "by": "price",
        "order": values.order_by
      },
      "pagination": {
        "per_page": 4,
        "page": page
      }
    }

    try {
      await api
        .post(Apis.ProductList, Parameter)
        .then((res) => {
          if (res.data.success == 1) {
            setPage(page)
            setProducts(res.data.arrData);
            setTotal(res.data.total_count);
            setMeta(res.data.meta);
            setLoading(false);
          } else {
            setProducts([]);
            setLoading(false);
            setTotal(0);
          }
        })
    } catch (error) {
      if (error.request.status !== 200) {
        return navigate("/404");
      }
      console.error(error.message);
    }
  };

  const onChange = (e) => {
    if (e.target.name == 'brands') {
      const { value, checked } = e.target;
      if (checked) {
        values.brands.push(e.target.value);
        setValues({ ...values, [e.target.name]: values.brands });
      } else {
        setValues({ ...values, [e.target.name]: values.brands.filter((e) => e !== value) });
      }
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  }

  const handleClick = (value) => {
    if (value.type === "price_slider") {
      if (values.pricemin !== value.pricemin) {
        setValues({ ...values, pricemin: value.pricemin });
      } else if (values.pricemax !== value.pricemax) {
        setValues({ ...values, pricemax: value.pricemax });
      } else {
        setValues({ ...values, pricemin: 0 });
        setValues({ ...values, pricemax: 0 });
      }
    }
  }

  return (
    <React.Fragment>
      <Meta title={title + meta.meta_title} keyword={title + meta.meta_keyword} description={title + meta.meta_description} />
      <BreadCrumb title={t('category_products.title')} />
      <div className="rts-shop-section section-gap">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <FilterCount
                total={total}
                onChange={onChange}
                handleClick={handleClick}
                defaultValue={values.order_by}
                price_filter_show={true}
              />
              <ItemContent
                loading={loading}
                product={products}
                setModel={setModel}
                Width={true}
              />
              {(products.length > 0) ?
                <Pagination page={page} perPage={4} total={total} pageNumber={setPageNumber} />
                :
                <>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <ProductDetailsPopup data={model} />
    </React.Fragment>
  )
}

export default CategoryProducts;