import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Link, useSearchParams } from "react-router-dom";
import { Customer } from "../components/CustomerConfig";
import { Product } from "../components/services";
import moment from "moment";
import {
  BreadCrumb,
  CustomPopUp,
  Pagination,
  BackToPage,
  Preload,
  NotFound,
  OrderSearchBox,
} from "../components";

import { useSelector, useDispatch } from "react-redux";
import { GetCurrency } from "../components/Helpers";
import { useTranslation } from 'react-i18next';

export default function OrderHistory() {
  const { t } = useTranslation();

  const Currency = GetCurrency();
  const [searchText, setSearchText] = useState("");
  const [modelLoading, setModelLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { CustomerData } = Customer();
  const [orderList, setOrderList] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const { tempitems } = useSelector((state) => state);

  const reOrderHandler = async (orderId) => {
    setModelLoading(true);
    let parameter = {
      user_uid: CustomerData.user_uid,
      access_token: CustomerData.access_token,
      order_uid: orderId,
    };
    await Product.getOrder(parameter).then((res) => {
      if (res) {
        let itemsData = res.arrData.order_items;
        dispatch({ type: "TEMP_ITEMS_RESET" });
        for (const key in itemsData) {
          const Obj = {
            id: itemsData[key].product_uid,
            title: itemsData[key].product_name,
            image: itemsData[key].product_image,
            quantity: itemsData[key].oder_quantity,
            price: itemsData[key].product_price,
            category: itemsData[key].Category,
            brand: itemsData[key].Brand,
          };
          dispatch({ type: "TEMP_ITEMS_ADD", payload: Obj });
        }
        setModelLoading(false);
      }
    });
  };

  const setPageNumber = (pageNumber) => {
    fetchOrderList(pageNumber);
  };

  useEffect(() => {
    let isCancelled = false;
    if (page != 1) {
      setSearchParams({
        page: page,
      });
    } else {
      setSearchParams({});
    }
    if (!isCancelled) {
      fetchOrderList(page);
    }
    return () => {
      isCancelled = true;
    }
  }, [page, searchText]);

  const fetchOrderList = (page = 1) => {
    setLoading(true);
    let parameter = {
      user_uid: CustomerData.user_uid,
      access_token: CustomerData.access_token,
      keyword: searchText,
      price: {
        min: 0,
        max: 0,
      },
      pagination: {
        per_page: 10,
        page: page
      }
    };
    Product.getOrderList(parameter).then((res) => {
      if (res) {
        setOrderList(res.arrData);
        setPage(page);
        setTotal(res.count);
        setLoading(false);
      }
    });
  };

  const cartClearHandler = () => {
    if (tempitems.length <= 1) {
      document.querySelector(".product-details-popup-wrapper").classList.remove("popup");
      document.querySelector(".anywere").classList.remove("bgshow");
    }
  }


  return (
    <React.Fragment>
      <BreadCrumb title={t('account.tabs.4.orders_history.title')} />
      <CustomPopUp cartClearHandler={cartClearHandler} modelLoading={modelLoading} Currency={Currency} />

      <div className="rts-shop-section order_list">
        <div className="container w-1220">
          <div className="row gutter-lg mb-4">
            <div className="col-lg-12 col-md-12 c_desktop">
              <h2 className="mb-3">{t('account.tabs.4.orders_history.my_orders')}</h2>
              <OrderSearchBox setSearchText={setSearchText} />
              <table className="shop-table cart-table mt-2 table-bordered">
                <thead>
                  <tr>
                    <th width="15%" className="align_left">
                      {t('account.tabs.4.orders_history.order')}
                    </th>
                    <th width="15%">{t('account.tabs.4.orders_history.order_total')}</th>
                    <th width="15%">{t('account.tabs.4.orders_history.order_status')}</th>
                    <th width="15%">{t('account.tabs.4.orders_history.payment_status')}</th>
                    <th width="15%">{t('account.tabs.4.orders_history.order_date')}</th>
                    <th width="10%">{t('account.tabs.4.orders_history.is_cancelled')}</th>
                    <th width="15%">{t('account.tabs.4.orders_history.action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !loading ?
                      orderList.length > 0
                        ? orderList.map(
                          (
                            {
                              unique_order_number,
                              uid,
                              status,
                              payment_status,
                              total_amount,
                              total_unit,
                              order_date,
                              dispute
                            },
                            index
                          ) => {
                            return (
                              <tr key={index}>
                                <td>{loading ? <Skeleton height={15} width={100} /> : unique_order_number}</td>
                                <td>{loading ? <Skeleton height={15} width={100} /> : Currency + ' ' + total_amount}</td>
                                <td>{loading ? <Skeleton height={15} width={100} /> : status ? status.charAt(0).toUpperCase() + status.slice(1) : t('account.tabs.4.orders_history.pending')}</td>
                                <td>{loading ? <Skeleton height={15} width={100} /> : payment_status.charAt(0).toUpperCase() + payment_status.slice(1)}</td>
                                <td>
                                  {loading ? <Skeleton height={15} width={100} /> : moment(order_date).format("DD MMM YYYY")}
                                </td>
                                <td>
                                  {loading ? <Skeleton height={15} width={100} /> : dispute == 0 ? t('account.tabs.4.orders_history.no') : t('account.tabs.4.orders_history.yes')}
                                </td>
                                <td>
                                  {
                                    loading
                                      ? <Skeleton height={15} width={100} />
                                      : <div className="btn-icon-block">
                                        <button
                                          title={t('account.tabs.4.orders_history.reorder')}
                                          className="btn btn-icon product-details-popup-btn"
                                          onClick={() => reOrderHandler(uid)}
                                        >
                                          <i className="fa fa-undo"></i>
                                        </button>
                                        <Link
                                          to={`/account/orders/view/${uid}`}
                                          title={t('account.tabs.4.orders_history.view_order')}
                                          className="btn btn-icon"
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                        <Link
                                          to={`/account/orders/cancel/${uid}`}
                                          title={t('account.tabs.4.orders_history.order_cancel')}
                                          className="btn btn-icon"
                                        >
                                          <i className="fa fa-times"></i>
                                        </Link>
                                      </div>
                                  }
                                </td>
                              </tr>
                            );
                          }
                        )
                        : <tr>
                          <td colSpan={7} style={{ border: 0 }}>
                            <NotFound />
                          </td>
                        </tr>
                      : <Preload type="feed" />
                  }
                </tbody>
                <div className="d-flex justify-content-start">
                  {orderList.length > 0 ? (
                    <Pagination
                      page={page}
                      perPage={10}
                      total={total}
                      pageNumber={setPageNumber}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </table>
            </div>
            <div className="col-lg-12 col-md-12 c_mobile"></div>
          </div>
          <BackToPage navigateTo={"/account"} />
        </div>
      </div>
    </React.Fragment >
  );
}
