const API_URL = `/ecommerce`;
const Apis = {
  //? Auth api
  GetUser: `/customer`,
  GetUserLogin: `/customer/login`,
  GetUserRegsiter: `/customer/register`,
  GetChangePassword: `/customer/change_password`,
  GetUpdateProfile: `/customer/update_profile`,
  GetForgotPassword: `/customer/forgot_password`,
  GetVerifyForgotPassword: `/customer/forgot_password_verify`,

  //? Categories
  /* Categories: `/categories`, */
  Categories: `/categories/list_categories`,
  FeaturedCategories: `/categories/featured_categories`,

  //? Products */
  ProductList: `/inv_product/product_list_new`,
  DealsProduct: `/inv_product/deals_product`,
  FeatureProduct: `/inv_product/feature_product`,
  TopSellProduct: `/inv_product/top_sell_products`,
  ProductStockCheck: `/inv_product/check_product_stock`,
  ProductReview: `/inv_product/give_review`,

  //? Free Gift */
  GetFreeGift: `/inv_product/get_free_gift`,

  //? Orders */
  // Checkout: `/order/create`,
  Checkout: `/order/place_order`,
  GetOrderList: '/order/get_order_list',
  GetOrder: '/order/get_single_order',
  GetReorderItems: '/order/get_reorder_item',
  CancelOrder: '/order/cancel_order',
  GetCancelOrders: '/order/get_cancel_orders',
  ReturnOrder: '/order/return_order',
  GetOrderForReturn: '/order/get_order_for_return',
  GetCancelReason: `cancle_order_reason`,

  //? Get Single Products */
  GetProduct: `/inv_product/get_product`,

  //? slider */ 
  Slider: `/slider`,

  //? Brands */
  BrandList: `/brand`,
  FeaturedBrand: `/brand/featured_brand`,

  //? Blogs */
  Blogs: `/blog`,
  GetBlog: `/blog/get_blog`,
  BlogTags: `/blog_tag`,
  BlogCategory: `/blog_category`,
  PopularBlog: `/blog/popular_blog`,

  //? Coupon */
  GetCoupon: `/coupon_setting`,
  TopCouponShow: `/coupon_setting/get`,

  //? Setting */
  GetSetting: `/ecommerce_setting`,
  GetSeoSetting: `/seo_page_setting`,

  //? Setting */
  PrivacyAndTermsOfUse: `/privacy_terms_of_use`,

  //? Company uid */
  GetCompanyUid: `/company`,
  /* Stripe Api */
  StripePayment: `/stripe_payment/create`,
  StripePaymentResponse: `/stripe_payment/stripe_response`,
  /* faq */
  GetFaq: `/faq`,

  /* address Api */
  GetAddress: `/customer_address/get_address`,
  CreateAddress: `/customer_address/create`,
  EditAddress: `/customer_address/edit`,
  GetAllAddress: `/customer_address`,
  deleteAddress: `/customer_address/delete`,
  CustomerAddress: `/customer_address/address_book_for_checkout`,

  /* data */
  Cart: `/cart`,
  CartCreate: `/cart/create`,
  CartEdit: `/cart/edit`,
  CartDelete: `/cart/delete`,
  Wishlist: `/wishlist`,
  WishlistCreate: `/wishlist/create`,
  WishlistDelete: `/wishlist/delete`,

  /* country Api */
  GetCountry: `/country`,
  GetState: `/country/get_state`,

  /* shipping setting Api */
  ShippingSetting: `/shipping_setting/get`,

  /* Contact Us Api */
  CreateContactUs: `/contact_us/create`,
}

export { API_URL, Apis };