import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CompanyConfig from "../CompanyConfig";
import { PriceSlider, Preload } from "../../components"
//** Api
import api from "../ApiConfig";
import { Apis } from "../../config";
import { RemoveScript } from "../RemoveScript";
import { AppendScript } from "../AppendScript";
import { useTranslation } from 'react-i18next';

export default function Filter({ onChange, values, handleClick, brands, attributes }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const { company_token } = CompanyConfig();
  const [brandshowMore, setBrandShowMore] = useState({ itemsToShow: 2, expanded: false })
  const [categoryshowMore, setCategoryShowMore] = useState({ itemsToShow: 3, expanded: false })
  const [attributesshowMore, setAttributesShowMore] = useState({ itemsToShow: 3, expanded: false })
  const [count, setCount] = useState(0);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      fetchBrands();
      fetchCategory();
    }
    return () => {
      isCancelled = true;
    }
  }, []);

  useEffect(() => {
    fetchBrands();
  }, [count])

  const fetchBrands = async () => {
    setLoading(true);
    try {
      await api
        .post(Apis.BrandList, {
          company_token: company_token,
          keyword: "",
        })
        .then((res) => {
          if (res.data.success == 1 && !brands.length) {
            setBrand(res.data.arrData);
            setLoading(false);
            RemoveScript(`${process.env.REACT_APP_URL}/assets/js/custom.js`)
            AppendScript(`${process.env.REACT_APP_URL}/assets/js/custom.js`);
          } else {
            setBrand(brands);
            setLoading(false);
            RemoveScript(`${process.env.REACT_APP_URL}/assets/js/custom.js`)
            AppendScript(`${process.env.REACT_APP_URL}/assets/js/custom.js`);
          }
        });
    } catch (error) {
      console.error(error.message);
    }
  }
  const fetchCategory = async () => {
    try {
      await api
        .post(Apis.Categories, {
          company_token: company_token,
          keyword: "",
        })
        .then((res) => {
          if (res.data.success == 1) {
            setCategory(res.data.arrData);
            RemoveScript(`${process.env.REACT_APP_URL}/assets/js/custom.js`)
            AppendScript(`${process.env.REACT_APP_URL}/assets/js/custom.js`);
          }
        });

    } catch (error) {
      console.error(error.message);
    }
  };

  const clickHandler = (type) => {
    type === "brand" ?
      brandshowMore.itemsToShow === 3
        ? (
          setBrandShowMore({ ...brandshowMore, itemsToShow: brand.length, expanded: true })
        )
        : (
          setBrandShowMore({ ...brandshowMore, itemsToShow: 3, expanded: false })
        )
      :
      (type === 'category') ?
        categoryshowMore.itemsToShow === 3
          ? (
            setCategoryShowMore({ ...categoryshowMore, itemsToShow: category[0].length, expanded: true })
          )
          : (
            setCategoryShowMore({ ...categoryshowMore, itemsToShow: 3, expanded: false })
          )
        :
        attributesshowMore.itemsToShow === 3
          ? (
            setAttributesShowMore({ ...attributesshowMore, itemsToShow: Object.keys(Object.assign({}, attributes)).length, expanded: true })
          )
          : (
            setAttributesShowMore({ ...attributesshowMore, itemsToShow: 3, expanded: false })
          )
  }
  return (
    <div className="side-sticky">
      <div className="shop-side-action">
        <div className="action-item">
          <div className="action-top">
            <span className="action-title">{t('products.filter.product_category')}</span>
          </div>
          <ul id="myUL">
            {
              Object.values(Object.assign({}, category[0])).slice(0, categoryshowMore.itemsToShow).map((item, index) => {
                return (
                  <li key={index}>
                    <span
                      className="caret category-title"
                      onClick={() => { handleClick({ type: "category", slug: item.category_slug }); setCount(count + 1) }}
                    >
                      {item.category_name}
                      {
                        Object.values(Object.assign({}, category[item.uid])).length > 0 ?
                          <i className="rt-angle-right" />
                          : ""
                      }
                    </span>
                    {
                      Object.values(Object.assign({}, category[item.uid])).length > 0
                        ?
                        <ul className="nested">
                          {
                            Object.values(Object.assign({}, category[item.uid])).map((sitem, sindex) => {
                              return (
                                <li key={sindex}>
                                  <span
                                    className="caret category-title"
                                    onClick={() => { handleClick({ type: "category", slug: sitem.category_slug }); setCount(count + 1) }}
                                  >
                                    {sitem.category_name}
                                    {
                                      Object.values(Object.assign({}, category[sitem.uid])).length > 0 ?
                                        <i className="rt-angle-right" />
                                        : ""
                                    }
                                  </span>
                                  {
                                    Object.values(Object.assign({}, category[sitem.uid])).length > 0
                                      ?
                                      <ul className="nested">
                                        {Object.values(Object.assign({}, category[sitem.uid])).map((titem, sindex) => {
                                          return (
                                            <li key={sindex}>
                                              <div className="category-item">
                                                <div className="category-item-inner">
                                                  <div className="category-title-area">
                                                    <input
                                                      type="checkbox"
                                                      name="category"
                                                      value={titem.category_slug}
                                                      className="c-right"
                                                      onChange={(e) => {
                                                        onChange(e);
                                                      }}
                                                    />
                                                    <span className="category-title">{titem.category_name}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        })
                                        }
                                      </ul>
                                      : ""
                                  }
                                </li>
                              )
                            })
                          }
                        </ul>
                        : ""
                    }
                  </li>
                )
              })
            }
          </ul>
          <span className="showmore-link" onClick={() => clickHandler("category")}>
            {categoryshowMore.expanded
              ? (<>
                <span>{t('products.filter.show_less')}</span>
                <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
              </>)
              : (<>
                <span>{t('products.filter.show_more')}</span>
                <i className="ms-1 fa fa-angle-down" aria-hidden="true"></i>
              </>)
            }
          </span>
        </div>

        <div className="action-item">
          <div className="action-top">
            <span className="action-title">{t('products.filter.filter_by_price')}</span>
          </div>
          <PriceSlider handleClick={handleClick} />
        </div>

        <div className="action-item">
          <div className="action-top mb-3">
            <span className="action-title">{t('products.filter.filter_by_brand')}</span>
          </div>
          {loading ? <Preload type="feedLine" /> : brand.length
            ? brand.slice(0, brandshowMore.itemsToShow).map(({ brand_name, brand_slug }, index) => {
              return (
                <div className="category-item" key={index}>
                  <div className="category-item-inner">
                    <div className="category-title-area">
                      <input
                        type="checkbox"
                        name="brands"
                        value={brand_slug}
                        className="c-right"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        checked={
                          values.brands.includes(brand_slug) ? true : false
                        }
                      />
                      <span className="category-title">{brand_name}</span>
                    </div>
                  </div>
                </div>
              );
            })
            : "Not Found :("
          }
          {brand.length > brandshowMore.itemsToShow
            ?
            <span className="showmore-link" onClick={() => clickHandler("brand")}>
              {brandshowMore.expanded
                ? (<>
                  <span>{t('products.filter.show_less')}</span>
                  <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
                </>)
                : (<>
                  <span>{t('products.filter.show_more')}</span>
                  <i className="ms-1 fa fa-angle-down" aria-hidden="true"></i>
                </>)
              }
            </span> : ""
          }
        </div>


        <div className="action-item">
          <div className="action-top">
            <span className="action-title">{t('products.filter.attributes')}</span>
          </div>
          <ul id="myUL">
            {
              Object.keys(Object.assign({}, attributes)).slice(0, attributesshowMore.itemsToShow).map((attribute, index) => {
                return (
                  <li key={index}>
                    <span
                      className="caret category-title"
                    /* onClick={() => { handleClick({ type: "attributes", slug: attribute }); setCount(count + 1) }} */
                    >
                      {attribute}
                      {Object.values(Object.assign({}, attributes))[index].length > 0 ?
                        <i className="rt-angle-right" />
                        : ""
                      }
                    </span>

                    {
                      Object.values(Object.assign({}, attributes))[index].length > 0
                        ?
                        <ul className="nested">
                          {Object.values(Object.assign({}, attributes))[index].map((option, i) => {
                            return (
                              <li key={i}>
                                <div className="category-item">
                                  <div className="category-item-inner">
                                    <div className="category-title-area">
                                      <input
                                        type="checkbox"
                                        name="attributes"
                                        value={option.slug}
                                        className="c-right"
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                      />
                                      <span className="category-title">{option.name}</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          })
                          }
                        </ul>
                        : ""
                    }

                  </li>
                )
              })
            }
          </ul>
          {Object.keys(Object.assign({}, attributes)).length > attributesshowMore.itemsToShow
            ?
            <span className="showmore-link" onClick={() => clickHandler("attributes")}>
              {attributesshowMore.expanded
                ? (<>
                  <span>{t('products.filter.show_less')}</span>
                  <i className="ms-1 fa fa-angle-up" aria-hidden="true"></i>
                </>)
                : (<>
                  <span>{t('products.filter.show_more')}</span>
                  <i className="ms-1 fa fa-angle-down" aria-hidden="true"></i>
                </>)
              }
            </span>
            : ""
          }
        </div>



        {/* <ul>
            {
              Object.keys(Object.assign({}, attributes)).map((item, index) => {
                return (
                  <li>{item}
                    <ul>
                      {Object.values(Object.assign({}, attributes))[index].map((value, value_index) => {
                        return (
                          <li>{value.name}</li>
                        )
                      })}
                      
                    </ul>
                  </li>
                )
              })
            }
          </ul> */}

        <div>
          <Link to="/products" className="banner-item">
            <div className="banner-inner">
              <span className="pretitle">{t('products.filter.winter_fashion')}</span>
              <h3 className="title">{t('products.filter.behind_the_deseart')}</h3>
            </div>
          </Link>
        </div>
      </div>
    </div >
  );
}
